import React from 'react';
import { Empty, Table, Tooltip } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Card from 'components/Card';
import { userFinanceDetail } from 'public/api';
import Header from './component/Header';
import memoize from "memoize-one";
import NoData from 'components/NoData';
import './index.css';
import { formatCurrency } from 'public/utils/com';

const getUserFinanceDetail = memoize((pagination, callback) => {
  userFinanceDetail({
    page: pagination.current || 1,
    rows: pagination.pageSize,
  }).then(result => {
    if (result.code == 0) {
      callback(result.data);
    }
  })
});
let totalTo = 0;
function PlatformCustomerPersonal(props) {

  const [columns, setColumns] = React.useState([
    {
      title: '时间',
      key: 'trade_time',
      dataIndex: 'trade_time',
      width: 200,
    },
    {
      title: '交易类型',
      key: 'trade_type',
      dataIndex: 'trade_type',
      width: 200,
      align: 'center'
    },
    {
      title: '金额',
      key: 'trade_figure',
      dataIndex: 'trade_figure',
      width: 200,
      render: (data) => {
        if (data && data > 0) {
          data = `+${data}`
        }
        return data;
      },
    },
    {
      title: '余额',
      key: 'currency',
      dataIndex: 'currency',
      width: 200,
      render: (data) => {
        let num = data && formatCurrency(data).split('.')[0];
        return num;
      },
    },
    {
      title: '详情',
      key: 'info',
      dataIndex: 'info',
      //width: 220,
      ellipsis: true,
      render: (data) => data ? (
        <Tooltip placement="topLeft" title={data}>
          <div
            style={{
              cursor: 'default',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              'overflowWrap':'break-word',
            }}
          >
            {data}
          </div>
        </Tooltip>
      ) : '--'
    },
  ]);

  const [dataSource, setDataSource] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: totalTo,
    show: false,
    total: 0,
    onChange: (data) => {
      setPagination({
        ...pagination,
        total: totalTo,
        current: data,
      });
    },
    /* showSizeChanger: true,
    onShowSizeChange: (current, pageSize) => {
      setPagination({
        current: current,
        pageSize: pageSize
      });
    }, */
  });

  React.useEffect(() => {
    if (pagination.current) {
      getUserFinanceDetail(pagination, (data) => {
        const { datalist, total } = data;
        setDataSource(datalist);

        totalTo = total;
        setPagination({
          ...pagination,
          total: totalTo,
          hideOnSinglePage: totalTo < pagination.pageSize
        });
      });
    }
  }, [pagination.current]);

  return (
    <Card className="customerPersonal rightArrow" style={{ margin: '20px', padding: '36px 50px', minHeight: '100%' }}>
      <Header dataSource={dataSource} />
      <div className="receipts">
        <span>收支明细</span>
      </div>
      <article className="personalTableParent">
        <Table
          rowKey="trade_time"
          className="personalTable"
          columns={columns}
          dataSource={dataSource}
          rowKey={record => record.id}
          locale={{
            emptyText: () => {
              return ('')
            }
          }}
          //scroll={{ y: 460 }}
          pagination={pagination}
        >
        </Table>
        {
          (dataSource && dataSource.length <= 0) && (<NoData />)
        }
      </article>
    </Card>
  )
}

PlatformCustomerPersonal.propTypes = {
  dataSource: PropTypes.arrayOf({}),
};

PlatformCustomerPersonal.defaultProps = {
  dataSource: null,
};
export default PlatformCustomerPersonal;

