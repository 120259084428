import React from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Card from 'components/Card';
import { login } from 'public/api';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom';
import md5 from 'js-md5';
import './index.css';

function Login(props) {
  const { getFieldDecorator } = props.form;

  const [errorDes, setErrorDes] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const [emailLen, setEmailLen] = React.useState(null);
  const [passwordLen, setPasswordLen] = React.useState(null);



  React.useEffect(() => {
    let code = null;
    let { pathname } = window.location;

    let pathNameCheck = '';
    pathNameCheck = pathname && pathname.split('/');
    pathNameCheck = (pathNameCheck && pathNameCheck[1]) || pathname;
    if (pathNameCheck && pathNameCheck.indexOf("customer") >= 0) {
      code = 1;
    } else if (pathNameCheck && pathNameCheck.indexOf("platform") >= 0) {
      code = 2;
    }
    code = code === 1 ? 'customer' : 'platform';
    let state = { title: document.title, url: window.location.href };
    window.history.pushState(state, '', `/${code}/login`);
  }, []);

  return (
    <LoginTo className="login icon-back" style={{ minHeight: `${document.body.offsetHeight}px` }}>
      <Header>
        <div>RealSafe</div>
      </Header>
      <article className="loginPanel">
        <section className="loginLeft">
          <img src="/img/login/back.png" />
        </section>
        <section className="loginRight">
          <h2>用户登录</h2>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              let code = null;
              let { pathname } = window.location;

              let pathNameCheck = '';
              pathNameCheck = pathname && pathname.split('/');
              pathNameCheck = (pathNameCheck && pathNameCheck[1]) || pathname;
              if (pathNameCheck && pathNameCheck.indexOf("customer") >= 0) {
                code = 1;
              } else if (pathNameCheck && pathNameCheck.indexOf("platform") >= 0) {
                code = 2;
              }

              props.form.validateFields((err, values) => {
                if (!err) {
                  let { username, password } = values;
                  username = username && username.trim() || '';
                  password = password && password.trim() || '';

                  let reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/; //正则表达式
                  if (!username) {
                    setErrorDes(true);
                    setMessage("邮箱不能为空");
                    return false;
                  } else if (!reg.test(username)) {
                    setErrorDes(true);
                    setMessage("输入的电子邮件无效");
                    return false;
                  }

                  let re = new RegExp(/^[A-Za-z0-9_]{6,16}$/);
                  if (!password) {
                    setErrorDes(true);
                    setMessage("密码不能为空");
                    return false;
                  } else if (!re.test(password)) {
                    setMessage('');
                    return setErrorDes(true);
                  }
                  const user = md5(password);
                  login({
                    username,
                    password: user,
                    syscode: Number(code),
                  }).then(result => {
                    const { data } = result;
                    if (result.code == 0) {
                      const { user_name, info } = data;
                      let urlPath = null;
                      let userName = user_name && encodeURI(user_name) || '';
                      if (code === 1) {
                        urlPath = "/customer";
                      } else {
                        urlPath = "/platform/room";
                      }

                      window.location.href = urlPath;
                    } else {
                      setErrorDes(true);
                      setMessage('');
                      //setMessage(result.msg);
                    }
                  })

                }
              });
            }}
            className="login-form"
          >
            <div className="errorText">
              {
                errorDes && (
                  message || '邮箱或密码错误'
                )
              }
            </div>
            <Form.Item>
              {getFieldDecorator('username', {
                /* rules: [
                  {
                    type: 'email',
                    message: '输入的电子邮件无效',
                  },
                  { required: true, message: '邮箱不能为空' }
                ],*/
              })(
                <Input
                  placeholder="邮箱"
                  prefix={
                    <i className="iconfontEmail" style={{ backgroundColor: emailLen > 0 ? '#298AEC' : 'rgba(33, 42, 58, 0.2)' }} />
                  }
                  onChange={
                    (data) => {
                      setEmailLen((event.target.value.trim()).length)
                      setErrorDes(false);
                    }
                  }
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                /*rules: [
                   { required: false, message: '密码不能为空' },
                  //^[0-9a-zA-Z_]{1,}$
                  /* {
                    required: false,
                    pattern: /^[A-Za-z0-9_]{6,16}$/,//new RegExp(/^[1-9]\d*$/, "g"),
                    message: '密码只支持输入数字及英文字母，长度必须介于6-16位之间'
                  }
                ],*/
              })(
                <Input
                  type="password"
                  placeholder="请输入密码"
                  prefix={
                    <i className="iconfontPassword" style={{ backgroundColor: passwordLen > 0 ? '#298AEC' : 'rgba(33, 42, 58, 0.2)' }} />
                  }
                  onChange={
                    (data) => {
                      setPasswordLen((event.target.value.trim()).length)
                      setErrorDes(false);
                    }
                  }
                />,
              )}
            </Form.Item>
            <Form.Item>
              <SubmitButton size="large" type="primary" htmlType="submit">
                登录
              </SubmitButton>
            </Form.Item>
            <div className="loginTip">请使用 Chrome 浏览器访问 RealSafe 平台</div>
          </Form>
        </section>
      </article>
      <article className="loginFooter">
        <div>
          <section>
            <a target="_blank" href="http://www.beian.miit.gov.cn">
              京ICP备18060039号-1
            </a>
          </section>
          <section>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030432">
              <i className="Security" />
              京公网安备 11010802030432号
            </a>
          </section>
          <section>&copy;2018-{new Date().getFullYear()} RealAI. All Rights Reserved. 北京瑞莱智慧科技有限公司</section>
        </div>
      </article>
    </LoginTo>
  )
}

Login.propTypes = {
  dataSource: PropTypes.arrayOf({}),
};

Login.defaultProps = {
  dataSource: null,
};

Login = Form.create({})(Login);
export default Login;


const LoginTo = styled.article`
  .ant-form{
    width:100%;
    margin:0 auto;
    margin-top:40px;
    position:relative;
  }
  .ant-form-item{
    width:100%;
    margin-bottom:12px;
  }
  .errorText{
    color: red;
    position: absolute;
    top: -27px;
    z-index: 1;
    font-family: PingFang-SC-Medium;
    font-size: 12px;
    color: #F45343;
  }
  .has-error .ant-form-explain, .has-error .ant-form-split{
    font-size: 12px;
    padding-top: 4px;
  }
  .iconfontEmail{
    display: block;
    width: 16px;
    height: 16px;
    background-color:rgba(33, 42, 58, 0.2);
    -webkit-mask: url('/img/login/email.svg') no-repeat center;
    mask: url('/img/login/email.svg') no-repeat center;
  }
  .iconfontPassword{
    display: block;
    width: 16px;
    height: 16px;
    background-color: rgba(33, 42, 58, 0.2);
    -webkit-mask: url('/img/login/password.svg') no-repeat center;
    mask: url('/img/login/password.svg') no-repeat center;
  }
`;
const Header = styled.div`
  font-size:26px;
  color:rgba(0, 0, 0, 0.4);
  background: #298AEC;
  height:52px;
  line-height:52px;
  div{
    width:75%;
    text-align:left;
    margin:0 auto;
    color:#ffffff;
  }
`
const SubmitButton = styled(Button)`
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-top: 12px; 
  font-size: 18px;
`;
