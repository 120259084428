import React from 'react';
import styles from './style.module.css'
/**
 *
 * @param status 展示的状态      type:[object String]   {error||success||info||warn}  默认 info
 * @returns {*}
 * @constructor
 */
const StatusText = (props) => {
  const colorHash={
    'error':'#F45343',
    'todo':'#25B5FF',
    'success':'#0ECB8A',
    'doing':'#C9D4E6',
    'warn':'#faad14'
  }
  return (
    <span className={styles.status} style={{background:colorHash[props.type],...props.style}}>
      {props.children}
    </span>
  );
};
export default StatusText;
