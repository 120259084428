import React from 'react';
import { Empty, Table, Modal, Button, Form, Input, message } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getUserCurrency } from 'public/api';
import { formatCurrency } from 'public/utils/com';


function Header(props) {
  const { dataSource } = props;
  const { getFieldDecorator, resetFields } = props.form;

  const [currency, setCurrency] = React.useState();

  React.useEffect(() => {
    getUserCurrency().then(result => {
      if (result.code == 0) {
        setCurrency(result.data.currency);
      }
    });
  }, []);


  return (
    <HeaderTo>
      <h2>
        财务管理
      </h2>
      <div className="rb">R币余额</div>
      <div className="money">
        <span>{(formatCurrency((currency || dataSource.currency) || 0)).split('.')[0]}</span>
        <a onClick={() => {
          Modal.info({
            title: '充值R币',
            okText:'知道了',
            content: (
              <div>
                R 币充值暂只支持线下对公转账，请通过和您对接的 RealAI 工作人员联系充值
              </div>
            ),
            okButtonProps: {
              style:{
                'margin-right': '8px',
              }
            },
          });
        }}>充值</a>
      </div>
    </HeaderTo>
  )
}

Header.propTypes = {
  dataSource: PropTypes.shape({}),
};

Header.defaultProps = {
  dataSource: null,
};

Header = Form.create({})(Header);
export default Header;

const HeaderTo = styled.article`
  h2{
    position:relative;
    opacity: 0.8;
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #212A3A;
    margin-bottom: 16px;
    span{
      position: absolute;
      top: 12px;
      color: #ffffff;
      background: #0ECB8A;
      border-radius: 9px;
      font-size: 12px;
      padding: 1px 6px;
      margin-left: 10px;
    }
  }
  .rb{
    opacity: 0.9;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #212A3A;
    line-height: 16px;
    margin-bottom: 16px;
  }
  .money{
    span{
      font-family: DINCondensed-Bold;
      font-size: 24px;
      color: #298AEC;
      line-height: 24px;
      position:relative;
      top:1.6px;
    }
    a{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #298AEC;
      line-height: 14px;
      text-decoration: underline;
      margin-left:12px;
    }
  }
  .flex{
    display:flex;
    display:-webkit-flex;
    margin:12px 0;
    div{
      margin-right:30px;
      span{
        &:first-child{
          opacity: 0.8;
          font-family: PingFangSC-Semibold;
          font-size: 14px;
          color: #212A3A;
          line-height: 14px;
          margin-right:12px;
          display:inile-block;
        }
      }
    }
  }
  .a{
    margin-right: 7px;
  }
`;

const ModalContainer = styled(Modal)`
  .modalContent{
    width: 100%;
    margin: 26px 0;
    height:160px;
    .login-form{
      margin-top:20px;
    }
    .ant-form-item{
      width:100%;
      margin-right:12px;
    }
    .ant-form-item{
      margin-bottom:12px;
    }
    .ant-form-item-label{
      text-align:right;
    }
  }
  .details{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(33,42,58,0.50);
    letter-spacing: 0;
    line-height: 18px;
  }
 
  .ant-modal-body{
    padding-bottom:0;
  }
  .ant-select-selection{
    border:1px solid rgb(230, 235, 243);
  }
  }`;
