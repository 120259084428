import React, {Component} from 'react';
import CustomerTaskAddWrap from 'components/CustomerTask/CustomerTaskAddWrap'
import {Breadcrumb} from "antd";
import queryString from "query-string";

class CustomerTaskAdd extends Component {
  render() {
    const query=queryString.parse(location.search)
    const  {id}=query
    const {userInfo={}}=this.props

    return (
      <div className="ui-container-padding">
        <div className="ui-container">
          <div className="ui-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item><a href="/customer/task/list">测评任务管理</a></Breadcrumb.Item>
              <Breadcrumb.Item>新建测评任务</Breadcrumb.Item>
            </Breadcrumb>
          </div>
            <div className="ui-bd-bread">
              <div className="ui-bd-ct">
                <CustomerTaskAddWrap isGuest={userInfo.userType==3} history={this.props.history} taskid={query.id}></CustomerTaskAddWrap>

              </div>
            </div>
        </div>
      </div>

    );
  }
}

export default CustomerTaskAdd;
