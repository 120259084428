import React from 'react'
import MyIcon from 'components/MyIcon/MyIcon'
import styles from './style.module.css'
import'./style.css'
import {Icon} from 'antd'
import queryString from 'query-string'

//http://127.0.0.1:3000/customer/demo/222?name=xiaoming
export default class Demo extends React.Component{
  render(){
    console.log('demo',this.props)
    const {match:{params}}=this.props
    const query=queryString.parse(location.search)
    return(<div>
      <MyIcon style={{ fontSize: '33px', color: 'pink' }} type="icon-log-pure"></MyIcon>
      <MyIcon style={{ fontSize: '33px', color: 'red' }} type="icon-log1"></MyIcon>
      <MyIcon style={{ fontSize: '33px', color: 'pink' }} type="icon-pdf"></MyIcon>{/*上传图标是若带颜色，则修改颜色无效*/}
      <Icon type="left"></Icon>
      <Icon type="right" style={{ fontSize: '33px', color: 'black' }}></Icon>
      <Icon type="right" style={{ fontSize: '33px', color: 'black' }}></Icon>
      <div className={styles.yellow}>yellow</div>
      <div className="red">red</div>
      <div>name:{query.name}</div>
      <div>id:{params.id}</div>
    </div>)
  }
}
