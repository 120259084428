import React from 'react';
import ReactDOM from 'react-dom';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom'
import routes from './routes'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN';


ReactDOM.render(
  <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        {renderRoutes(routes)}
      </BrowserRouter>
  </ConfigProvider>
  , document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
