export  function toPageNum({offset,num}) {
  const pageNum=(offset/num)+1
  const pageSize=num
  return {
    pageNum,
    pageSize
  }
}
export  function toOffset({pageNum,pageSize}) {
  const num=pageSize

  const offset=(pageNum-1)*num
  return {offset,num}
}
