import React, {Component} from 'react';
import {
  Steps,
  Alert,
  Button,
  Form,
  Input,
  message,
  Radio,
  Checkbox,
  Icon,
  Tooltip,
  Slider,
  InputNumber,
  Row,
  Col,
  Upload,
  Select,
  Modal,
} from 'antd'
import schema from 'async-validator';//12k的parsed size

import MyIcon from 'components/MyIcon/MyIcon'
import StatusText from 'components/common/StatusText/StatusText'
import styles from './style.module.css'
import './style.css'
import {
  getDatasetsCustomer,
  getDatasetsSystem,
  postNameRepat,
  getCoin,
  postTask,
  getTask,
  postFinalTask,
  getTasks,
  getEvaluatePrice
} from './api'
import magnitude2 from './magnitude/2.png'
import magnitude4 from './magnitude/4.png'
import magnitude8 from './magnitude/8.png'
import magnitude16 from './magnitude/16.png'
import magnitude32 from './magnitude/32.png'

const magnitudeImg = {
  0: magnitude2,
  1: magnitude4,
  2: magnitude8,
  3: magnitude16,
  4: magnitude32,

}
const evaluateHash = {
  0: '查询攻击',
  1: '迁移攻击',
  2: '高级迁移攻击'
}
const attackHash = {
  0: '将不相似人脸攻击为相似',
  1: '将相似人脸攻击为不相似',
}
const sdkTypes = [".rar", ".zip"]
const apiTypes = [".pdf"]

const getRecommendBySourceAndMulticall = ({multiCall, returnScore}) => {
  if (multiCall) {
    return [2]
  }
  return [0, 2]

}
//按钮逻辑，输入非法：上一步可点，不存当前数据；未输入：上一步可点，不存当前数据；输入合法：下一步可点，上一步可点，都存数据
const Step = Steps.Step
const Item = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
const isError = (fieldsError) => Object.keys(fieldsError).some(fieledName => fieldsError[fieledName])
const MagnitudeLabel = () => (<span>扰动大小<Tooltip
  title={() => <span>扰动越大，生成的对抗样本越容易被人眼辨别。您可以根据不同扰动大小的对抗样本在您业务场景中的威胁程度，选择合适的区间（当前取值范围为0-255）。<br/>选择后，测评时只按照您选择的区间生成不同扰动大小的对抗样本</span>}><Icon
  className={`t-blue ${styles.hoverIcon}`} type="question-circle"/></Tooltip></span>)
const IterationLabel = () => (
  <span>最大迭代次数<Tooltip title={() => <span>您可以根据业务限制频繁调用的能力进行灵活选择。选择后，测评时每组样本利用查询攻击算法生成对抗样本时的最大迭代次数为所选值</span>}><Icon
    className={`t-blue ${styles.hoverIcon}`}
    type="question-circle"/></Tooltip></span>)
const StepLabel = () => (
  <span>步长<Tooltip title={() => <span>默认使用L_inf作为距离度量，支持输入0-32之间的数字</span>}><Icon
    className={`t-blue ${styles.hoverIcon}`}
    type="question-circle"/></Tooltip></span>)
const Threshold = () => (
  <span>阈值(%)<Tooltip title={() => <span>在实际业务中相似度高于设定的阈值时，即会被认为同一个人。测评时将使用阈值和返回的分数信息作为判断依据</span>}><Icon
    className={`t-blue ${styles.hoverIcon}`}
    type="question-circle"/></Tooltip></span>)
const SDK = () => (
  <span>SDK 文件<Tooltip title={() => <span>请将 SDK 包及使用文档压缩为一个文件上传</span>}><Icon className={`t-blue ${styles.hoverIcon}`}
                                                                               type="question-circle"/></Tooltip></span>)

@Form.create()
class CustomerTaskAddWrap extends Component {
  state = {
    step: 0,
    coins: 0,
    task: {},
    searchEvaluateType: null,//0 null
    migrationEvaluateType: null,//1 2 null
    datasetsCustomer: [],
    datasetsSystem: [],
    advanceStatus: false,
    evaluatePrice: {},
    saveModalVisible: false,
    apiLoading: false,
    sdkLoading: false
  }
  //不通过antd的校验，以解决点击上一步有错不触发表单错误提示
  customerValid = (fields, values) => {
    console.log('fields', fields, values)
    const descriptor = fields.reduce((descriptor, item) => {
      descriptor[item[0]] = item[1].rules
      return descriptor
    }, {})
    const validator = new schema(descriptor);
    validator.validate(values, (errors, fields) => {
      console.log('custoemr', errors, fields)
    });
  }
  data = {}

  componentDidMount() {

    this.fieldNameForStep = {
      0: ['name', 'description'],
      1: ['applyScene', 'attackDirection', 'returnScore', 'multiCall'],
      2: ['evaluatingMethod', 'magnitude', 'iteration', 'alpha'],
      3: ['request', 'threshold', 'thresholdType', 'qps', 'apiDoc', 'sdk'],
      4: ['datasetId', 'datasetType']
    }
    this.fieldNameForStep[5] = Object.keys(this.fieldNameForStep).reduce((arr, key) => arr.concat(this.fieldNameForStep[key]), [])
    getCoin().then(result => {
      if (result.code == 0) {
        this.setState({coins: result.data.currency});
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('getCoin 出错了')
    })
    if (this.props.taskid) {
      getTask({id: this.props.taskid}).then(result => {
        if (result.code == 0) {
          const {step} = this.state
          this.data = {...result.data,attackDirection:result.data.attackDirection.length==0?undefined:result.data.attackDirection}
          if (this.data.magnitude) {
            this.data.magnitude = Math.log2(this.data.magnitude) - 1
          }
          console.log('result.data', result.data)
          const keys = this.getCurFieldNames() || []
          const keysValue = keys.reduce((obj, key) => {
            obj[key] = this.data[key]
            return obj
          }, {})
          //初始化数据
          const evaluatingMethod = this.data.evaluatingMethod
          if (evaluatingMethod) {
            if (evaluatingMethod.includes(0)) {
              this.setState({searchEvaluateType: 0});
            }
            if (evaluatingMethod.includes(1)) {
              this.setState({migrationEvaluateType: 1});

            } else if (evaluatingMethod.includes(2)) {
              this.setState({migrationEvaluateType: 2});

            }
          }
          console.log('keysValue', keysValue)
          this.props.form.setFieldsValue(keysValue)

        } else {
          message.error(result.msg)
        }
        console.log('data', result.data)
      }).catch(err => {
        console.log('err', err)
        message.error('getTask 出错了')
      })
    }
    getEvaluatePrice().then(result => {
      if (result.code == 0) {
        this.setState({evaluatePrice: result.data});
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('getEvaluatePrice 出错了')
    })


    //this.props.form.validateFields();
  }

  handleNextClick = (e, isPre) => {
    const {getFieldValue, getFieldsValue, validateFields} = this.props.form
    const fieldNameForStep = this.fieldNameForStep
    const {step, datasetsSystem, datasetsCustomer, sdkLoading, apiLoading} = this.state
    const fieldNames = this.getCurFieldNames()
    const history = this.props.history
    const request = getFieldValue('request')

    //当前radio在上传，则抛出提示
    if (request == 1 && sdkLoading || request == 0 && apiLoading) {
      message.error('请等待文件上传完成')
      return
    }


    //this.customerValid(fieldNames,getFieldsValue(fieldNames))

    this.props.form.validateFields(fieldNames, (err, values) => {
      console.log('err', err, values)
      if (!err) {
        values.taskId = this.props.taskid


        //处理数据集
        if (values.datasetId) {
          const datasetType = getFieldValue('datasetType')
          const datasets = datasetType == 1 ? datasetsSystem : datasetsCustomer
          const dataset = datasets.find(item => item.id == values.datasetId)
          values.datasetName = dataset.name
          values.datasetDirPath = dataset.dirPath
          values.datasetCsv = dataset.csv
        }

        //处理sdk文件
        if (values.sdk && typeof values.sdk === 'object') {
          values.sdk = values.sdk[0].name
        }
        //处理api
        if (values.apiDoc && typeof values.apiDoc == 'object') {
          console.log('处理api----->',)
          values.apiDoc = values.apiDoc[0].name
        }
        console.log('value', values)
        const newValues = {...this.data, ...values}


        //处理扰动
        if (newValues.magnitude) {
          newValues.magnitude = 2 ** (newValues.magnitude + 1)

        }
        //在上传过api的情况下，又在第三步选择了查询攻击，清空api和类型
        if (newValues.request == 0 && newValues.evaluatingMethod.includes(0)) {
          newValues.request = 1
          newValues.apiDoc = null

        }

        //根据是否返回分数，处理扰动。否则置空阈值。
        if (!newValues.returnScore) {
          newValues.threshold = null
        }

        if (newValues.request == 1) {
          newValues.qps = null
          newValues.apiDoc = null
        } else if (newValues.request === 0) {
          newValues.sdk = null
        }

        //已设置最大迭代次数，取消了查询攻击,置空迭代次数
        if (newValues.iteration && !newValues.evaluatingMethod.includes(0)) {
          newValues.iteration = null
        }

        postTask(newValues).then(result => {
          console.log('reslt', result)
          if (result.code == 0) {
            if (!this.props.taskId) {
              history.replace(history.location.pathname + '?id=' + result.data.taskId)
            }

            this.setState({step: isPre ? step - 1 : step + 1}, () => {
              const {step} = this.state
              console.log('newValues', newValues, this.data, this.fieldNameForStep)

              this.data = {...this.data, ...newValues}
              if (this.data.magnitude) {
                this.data.magnitude = Math.log2(this.data.magnitude) - 1
              }

              const keys = this.getCurFieldNames()
              console.log('step---->', isPre, step, keys, this.fieldNameForStep)
              const keysValue = keys.reduce((obj, key) => {
                if (this.data[key] !== undefined && this.data[key] !== null) {
                  obj[key] = this.data[key]
                  if (key == 'sdk') {
                    obj[key] = [{
                      uid: '1',
                      name: obj[key],
                      status: 'done',
                    }]
                  } else if (key == 'apiDoc') {
                    obj[key] = [{
                      uid: '1',
                      name: obj[key],
                      status: 'done',
                    }]
                  }

                }
                return obj
              }, {})
              console.log('keysValue', keysValue)

              this.props.form.setFieldsValue(keysValue)
            });
          } else {
            message.error(result.msg)
          }
        }).catch(err => {
          message.error('postTask 出错了')
        })
        if (step == 3) {
          getDatasetsCustomer().then(result => {
            if (result.code == 0) {
              if(this.data.datasetType===undefined||this.data.datasetType===null){
                this.props.form.setFieldsValue({datasetType: result.data.length ? 0 : 1})
              }
              this.setState({datasetsCustomer: result.data});
            } else {
              message.error(result.msg)
            }
          }).catch(err => {
            message.error('出错了')
          })

          getDatasetsSystem().then(result => {
            if (result.code == 0) {
              this.setState({datasetsSystem: result.data});
            } else {
              message.error(result.msg)
            }
          }).catch(err => {
            message.error('出错了')
          })
        }
      }
    })


  }
  //保存
  handleSaveClick = () => {
    console.log('save', this.data)

    getCoin().then(result => {
      if (result.code == 0) {
        this.setState({coins: result.data.currency, saveModalVisible: true});
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('getCoin 出错了')
    })
  }
  handleSubmitOkClick = () => {
    //处理扰动
    if (this.data.magnitude) {
      this.data.magnitude = 2 ** (this.data.magnitude + 1)

    }
    postFinalTask(this.data).then(result => {
      if (result.code == 0) {
        message.success(result.msg)
        this.props.history.push('/customer/task/list')

      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('save 出错了')
    })
  }
  handleSaveCancelClick = () => {
    this.setState({saveModalVisible: false});
  }
  handleSaveOkClick=()=>{
    if (this.data.magnitude) {
      this.data.magnitude = 2 ** (this.data.magnitude + 1)

    }
    postTask(this.data).then(result => {
      if (result.code == 0) {
        message.success(result.msg)
        this.props.history.push('/customer/task/list')

      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('save 出错了')
    })
  }
  handlePreClick = (e) => {
    const {validateFields, getFieldsError, setFields} = this.props.form
    const {step} = this.state
    const fieldNames = this.getCurFieldNames()
    validateFields(fieldNames, (err) => {
      //有错不保存，直接回到上一步
      if (err) {
        setFields(fieldNames.reduce((obj, item) => {
          obj[item] = {error: null}
          return obj
        }, {}))
        this.setState({step: step - 1});
        return
      }
      //无错则和下一步逻辑类似，继续保存
      this.handleNextClick(e, true)

    })


  }
  getCurFieldNames = () => {
    const step = this.state.step
    if (step == 3) {
      const request = this.props.form.getFieldValue('request')
      let arr = ['request', 'threshold', 'thresholdType',]
      if (request == 1) {
        arr.push('sdk')
      } else {
        arr.push('qps')
        arr.push('apiDoc')
      }
      return arr
    }
    return this.fieldNameForStep[step]
  }
  haveNoInput = () => {
    const {step} = this.state
  }
  handleMethodClick = (type) => {
    const {searchEvaluateType, migrationEvaluateType} = this.state
    const setEvaluatingMethod = () => {
      const {searchEvaluateType, migrationEvaluateType} = this.state
      let evaluatingMethod = []
      if (searchEvaluateType !== null) {
        evaluatingMethod.push(searchEvaluateType)
      }
      if (migrationEvaluateType !== null) {
        evaluatingMethod.push(migrationEvaluateType)
      }
      this.props.form.setFieldsValue({evaluatingMethod})
    }
    if (type == 0) {
      this.setState({searchEvaluateType: searchEvaluateType == 0 ? null : 0}, setEvaluatingMethod);
    } else if (type == 1) {
      this.setState({migrationEvaluateType: migrationEvaluateType == 1 ? null : 1}, setEvaluatingMethod);
    } else if (type == 2) {
      this.setState({migrationEvaluateType: migrationEvaluateType == 2 ? null : 2}, setEvaluatingMethod);
    }
  }
  handleAdvanceChange = () => {
    this.setState({advanceStatus: !this.state.advanceStatus})

  }
  normalUpload = (e) => {
    const fileList = e.fileList
    if (fileList.length >= 2) {
      fileList.shift()
    }
    return fileList
  }
  sdkBefore = (file) => {
    if (file.size > 200 * 1024 * 1024) {
      return false
    }

    if (!sdkTypes.some(sdkType => (new RegExp(sdkType + '$')).test(file.name))) {
      return false
    }

  }
  apiBefore = (file) => {
    console.log('before', file)

    if (file.size > 5 * 1024 * 1024) {
      return false
    }
    if (!apiTypes.some(sdkType => (new RegExp(sdkType + '$')).test(file.name))) {
      return false
    }
  }
  handleDatasetTypeChange = () => {
    this.props.form.setFieldsValue({datasetId: null})
  }
  handleAlphaBlur = (value, a, b) => {
    const alpha = this.props.form.getFieldValue('alpha')
    if (alpha === null) {
      this.props.form.setFieldsValue({alpha: 1})
    }
  }
  apiChange = ({file}) => {
    if (file.status == 'uploading') {
      this.setState({apiLoading: true});
    } else {
      this.setState({apiLoading: false});

    }
  }
  sdkChange = ({file}) => {
    if (file.status == 'uploading') {
      this.setState({sdkLoading: true});
    } else {
      this.setState({sdkLoading: false});

    }
  }

  render() {
    const {step, coins, searchEvaluateType, migrationEvaluateType, advanceStatus, evaluatePrice, datasetsCustomer, datasetsSystem} = this.state
    const {getFieldDecorator, getFieldValue, getFieldsError, isFieldsTouched, getFieldsValue} = this.props.form
    const {taskid, isEdit,isGuest} = this.props
    const description = getFieldValue('description') || ''
    const evaluatingMethod = getFieldValue('evaluatingMethod') || []
    const request = getFieldValue('request') || []
    const datasetType = getFieldValue('datasetType')
    const datasets = datasetType == 1 ? datasetsSystem : datasetsCustomer
    const curFormValues = getFieldsValue()
    const formValues = this.data
    const recomends = getRecommendBySourceAndMulticall(formValues)


    const consume = evaluatingMethod.reduce((sum, item) => sum + evaluatePrice[item], 0)
    const evaluatingMethodStr = evaluatingMethod.map(item => evaluateHash[item]).join('、')
    console.log('render values', this.state, this.data, formValues)
    return (
      <div className={`${styles.add} task-add`}>
        <Steps
          type="navigation"
          size="small"
          current={step}
          style={{
            marginBottom: 28,
            boxShadow: '0px -1px 0 0 #e8e8e8 inset',
          }}
        >
          <Step title={isEdit ? '编辑任务名称' : '创建任务名称'}/>
          <Step title="完善待测场景信息"/>
          <Step title="配置测评方案"/>
          <Step title="提交接入信息"/>
          <Step title="选择数据集"/>
          <Step title="提交评测"/>
        </Steps>
        <div style={{borderTop: '1px solid #fff', padding: '0 60px', paddingBottom: 30}}>

          <div style={{display: step == 0 ? 'block' : 'none'}}>
            <h3>{isEdit ? '编辑任务名称' : '创建任务名称'}</h3>
            {
              coins < Object.values(evaluatePrice).sort((a, b) => a - b)[0]
                ? <Alert
                  message={<span className="t-blue">测评需要消耗 R 币，当前余额已不足。您可以先创建任务，然后联系和您对接的 RealAI  工作人员充值 R 币再提交测评。</span>}
                  type="info"
                  showIcon
                />
                : null
            }
            <Item
              label="任务名称"
            >
              {getFieldDecorator('name', {
                rules: [
                  {required: true, message: '请输入任务名称'},
                  {min: 4, max: 30, message: '名称的长度必须介于 4 和 30 个字符之间。'},
                  /*不需要实时校验*/
                  /*{validator:(rule,value,cb)=>{
                      postNameRepat({name:value}).then(result=>{
                        if(result.code==0){
                            if(result.data){
                              cb('此名称已存在，请修改')
                            }else{
                              cb()
                            }
                        }else{
                          cb()
                          message.error(result.msg)
                        }
                      })
                    }}*/
                ]
              })(<Input autocomplete="off"/>)}
            </Item>
            <Item
              label="任务描述"
            >
              <div className={styles.textareaWrap}>
                <span className={styles.inputedNum}>{<span
                  className={`${description.length > 50 ? 't-red' : ''}`}>{description.length}</span>}/50</span>
                {
                  getFieldDecorator('description', {
                    rules: [
                      {min: 4, max: 50, message: '描述的长度必须介于 4 和 50 个字符之间'}
                    ]
                  })(<TextArea
                    placeholder="请输入要评测的业务背景等信息"/>)
                }
              </div>

            </Item>
          </div>
          <div style={{display: step == 1 ? 'block' : 'none'}}>
            <h3>完善待测场景信息</h3>
            <div>完善您的业务场景以帮助我们推荐适合的测评方案</div>
            <Item
              label="应用场景"
            >
              {getFieldDecorator('applyScene', {
                initialValue: 0,
                rules: [{message: '请选择攻击方向', required: true}]

              })(<Radio.Group>
                <Radio value={0}>人脸对比<Tooltip title="通过两张人脸1:1对比相似度，适用于远程认证、酒店智慧入住等场景"><Icon
                  className={`t-blue ${styles.hoverIcon}`} type="question-circle"/></Tooltip></Radio>
                {/*<Radio value={2}>B</Radio>*/}
              </Radio.Group>)}
            </Item>
            <Item
              label="攻击方向"
              extra={(curFormValues.attackDirection || []).length == 2 ? '一般测评时间为2天左右，选择两种攻击方向测评时间会加倍。请选择您关心的攻击方向' : null}
            >
              {
                getFieldDecorator('attackDirection', {
                  initialValue: [0],
                  rules: [{message: '请选择攻击方向', required: true}]
                })(<Checkbox.Group options={[{label: '将不相似人脸攻击为相似', value: 0}, {label: '将相似人脸攻击为不相似', value: 1}]}/>)
              }
            </Item>
            <Item
              label="是否返回分数信息（如置信度）"
            >
              {getFieldDecorator('returnScore', {
                initialValue: 1,
                rules: [{message: '请选择攻击方向', required: true}]
              })(<Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>)}
            </Item>
            <Item
              style={{display:isGuest?'none':'block'}}
              label={<span>是否限制第三方频繁调用<Tooltip
                title="该选项主要用于评估您的算法模型受到查询攻击的威胁程度。攻击者使用查询攻击方法时，需多次访问您的服务并根据返回值不断修改对抗噪声。每生成一张图片对应的对抗样本需要持续访问当前模型一千次左右。"><Icon
                className={`t-blue ${styles.hoverIcon}`}
                type="question-circle"/></Tooltip></span>}
            >
              {getFieldDecorator('multiCall', {
                initialValue: 0,
                rules: [{message: '请选择攻击方向', required: true}]
              })(<Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>)}
            </Item>
          </div>

          <div style={{display: step == 2 ? 'block' : 'none'}}>
            <h3>配置测评方案</h3>
            <h4>选择测试方法</h4>
            <div style={{display:isGuest?'none':'block'}} className={styles.box}>
              <div className={styles.boxTitle}>查询攻击测评</div>
              <div className={styles.boxDesc}>攻击者多次访问您的服务，利用返回值持续优化对抗样本用于攻击。查询攻击方法依赖于多次访问，攻击成功率高、隐蔽性弱。</div>
              <div style={{marginTop: 20,}}>
                <Card
                  type={0}
                  desc="2种主流查询攻击方法，只支持测评 SDK 形式的服务"
                  price={evaluatePrice[0]}
                  isRecommend={recomends.includes(0)}
                  onClick={this.handleMethodClick}
                  checked={searchEvaluateType == 0}
                />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.boxTitle}>迁移攻击测评</div>
              <div
                className={styles.boxDesc}>攻击者训练与攻击目标类似的“替代模型”，利用对抗样本的迁移性，针对替代模型生成对抗样本用于攻击。迁移攻击方法不需要多次方式，攻击成功率稍低、隐蔽性强。
              </div>
              <div style={{marginTop: 20, display: 'flex'}}>
                <Card
                  type={1}
                  desc="3种主流迁移攻击方法"
                  price={evaluatePrice[1]}
                  isRecommend={recomends.includes(1)}
                  onClick={this.handleMethodClick}

                  checked={migrationEvaluateType == (1)}
                />
                <Card
                  style={{marginLeft: 40,display:isGuest?'none':'block'}}
                  type={2}
                  desc="相对迁移攻击测评额外包含3种特殊调优后的强力攻击算法"
                  price={evaluatePrice[2]}
                  isRecommend={recomends.includes(2)}
                  onClick={this.handleMethodClick}
                  checked={migrationEvaluateType == (2)}
                />
              </div>
            </div>
            <div style={{marginTop:5,display:isGuest?'block':'none'}} className={styles.coinEnough}>Lite 版本暂不支持选择更多测评方法及相应的参数配置，如需进行更多测试请使用完整版本</div>

            {
              evaluatingMethod.length
                ? <div className={styles.coinEnough}>
                  您选择了
                  <span style={{fontWeight: 'bold'}}>{evaluatingMethod.map(item => evaluateHash[item]).join('、')}</span>
                  方法，共计消耗
                  <span style={{
                    fontWeight: 'bold',
                    fontSize: 14
                  }}> {evaluatingMethod.reduce((sum, item) => sum + evaluatePrice[item], 0)} R币</span>
                  。
                </div>
                : null
            }

            <Item  className={styles.blank} label="">
              {getFieldDecorator('evaluatingMethod', {
                rules: [{required: true, message: '请选择测评方法'}]
              })(<div style={{display: evaluatingMethod.length ? 'block' : 'none'}}></div>)}
            </Item>
            <h4 style={{marginTop: 16,display: isGuest?'none':'block'}}>测评参数配置</h4>
            <Row style={{display: isGuest?'none':'block'}}>
              <Col span={18}>
                <div>
                  <Item label={<MagnitudeLabel/>}>
                    {getFieldDecorator('magnitude', {
                      initialValue: 4,
                      rules: [{required: true, message: '请选择扰动大小'}]
                    })(<Slider tipFormatter={n => 2 ** (n + 1)} marks={{0: 2, 1: 4, 2: 8, 3: 16, 4: 32}} max={4}
                               min={0}/>)}
                  </Item>
                  {
                    searchEvaluateType == 0
                      ? <Item label={<IterationLabel/>}>
                        {getFieldDecorator('iteration', {
                          initialValue: 5000,
                          rules: [{required: true, message: '请选择最大迭代次数'}]
                        })(<Radio.Group>
                          <Radio value={1000}>1000</Radio>
                          <Radio value={2000}>2000</Radio>
                          <Radio value={3000}>3000</Radio>
                          <Radio value={4000}>4000</Radio>
                          <Radio value={5000}>5000</Radio>
                        </Radio.Group>)}
                      </Item>
                      : null
                  }

                  <div>高级参数配置<span style={{marginLeft: 5, cursor: 'pointer'}} onClick={this.handleAdvanceChange}><Icon
                    type={advanceStatus ? 'up' : 'down'}/></span></div>
                  <Item style={{display: advanceStatus ? 'block' : 'none'}} label={<StepLabel/>}>
                    {getFieldDecorator('alpha', {
                      initialValue: 1,
                      rules: [{required: true, message: '请输入步长'}]
                    })(<InputNumber
                      style={{width: 304}}
                      step={1}
                      min={0.1}
                      max={32}
                      precision={1}
                      onBlur={this.handleAlphaBlur}
                    />)}
                  </Item>
                </div>
              </Col>
              <Col span={6}>
                <div className="flex-center">
                  <img src={magnitudeImg[getFieldValue('magnitude')]} alt=""/>
                  <div
                    className={`${styles.magnitudeImgDesc} t-white`}>扰动大小: {2 * 2 ** getFieldValue('magnitude')}</div>
                </div>

              </Col>
            </Row>


          </div>

          <div style={{display: step == 3 ? 'block' : 'none'}}>
            <h3>提交接入信息</h3>
            <div>支持 {(curFormValues.evaluatingMethod || []).includes(0) ? null : 'API、'}SDK 形式服务的测评，请在下方提交相应的信息</div>
            <Item style={{marginBottom: 0}} label="">
              {
                getFieldDecorator('request', {
                  initialValue: typeof formValues.request==='number'?formValues.request:1
                })(<Radio.Group onChange={this.handleRequestChange}>
                  {(curFormValues.evaluatingMethod || []).includes(0) ? null : <Radio value={0}>API 方式</Radio>}
                  <Radio value={1}>SDK 方式</Radio>

                </Radio.Group>)
              }
            </Item>
            {
              request == 0
                ? <Alert
                  message={<span className="t-blue">1.测评时将生成对抗样本图片模拟攻击您的服务，如可能影响正常业务，请搭建专门的测评环境。<br/>2.测试时需要访问您的服务25万次左右，建议 QPS 不低于5 （执行测评的时间可控制在1天内）。</span>}
                  type="info"
                  showIcon
                />
                : null
            }
            {
              formValues.returnScore
                ? <Item label={<Threshold/>}>


                  {getFieldDecorator('threshold', {
                    validateFirst: true,
                    rules: [
                      {required: true, message: '请输入阈值'},
                      {
                        validator: (rule, value, cb) => {
                          console.log('value', value)

                          if (isNaN(value) || value > 100 || value < 1) {
                            cb('请输入1-100之间的数字')
                            return
                          }
                          console.log()
                          if (!(/^\d+(.\d{0,4})?$/.test(value))) {
                            cb('最多4位小数')
                            return
                          }
                          cb()
                        }
                      }
                    ]
                  })(<Input
                    autocomplete="off"
                    addonBefore={getFieldDecorator('thresholdType', {
                      initialValue: 1,

                    })(<Select style={{width: 94}}>
                      <Option value={1}>大于等于</Option>
                      <Option value={0}>大于</Option>
                    </Select>)}
                    style={{width: 309}}/>)}

                </Item>
                : null
            }

            <Item style={{display: request == 0 ? 'block' : 'none'}} label="最大QPS">
              {
                getFieldDecorator('qps', {
                  rules: [{required: request == 0, message: '请输入qps'},]
                })(<InputNumber style={{width: 309}} min={0} step={1} precision={0}/>)
              }
            </Item>
            <Item className="" style={{display: request == 1 ? 'block' : 'none', width: 570}} label={<SDK/>}>
              {
                getFieldDecorator('sdk', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normalUpload,
                  rules: [
                    {required: request == 1, message: '请上传SDK 文件'},
                    {
                      validator: (rule, value, cb) => {
                        if (value && value.length) {
                          const file = value[0]
                          if (file.status == 'done') {
                            cb()
                            return
                          }
                          console.log('file', file)
                          if (file.size > 200 * 1024 * 1024) {
                            cb('文件过大，请压缩后上传')
                          } else if (file.status == 'error') {
                            cb(file.response ? file.response.msg : '上传失败，请重试')
                          } else if (!sdkTypes.some(sdkType => (new RegExp(sdkType + '$')).test(file.name))) {
                            cb('请上传正确格式的文件')
                          } else {
                            cb()
                          }
                        } else {
                          cb()
                        }
                      }
                    }
                  ]

                })(<Upload
                  beforeUpload={this.sdkBefore}
                  onChange={this.sdkChange}
                  accept=".zip,.rar"
                  action="/realsafe/server/evaluate/task/upload"
                  data={{type: 0, task_id: taskid}}

                >
                  <Button>上传文件</Button>
                  <span style={{marginLeft: 12}} className="t-opa50">支持扩展名：.zip .rar 最大不超过200M 文件过大时请线下提供 SDK 包</span>
                </Upload>)
              }

            </Item>
            <Item style={{display: request == 0 ? 'block' : 'none', width: 570}} label="API 文档">
              {
                getFieldDecorator('apiDoc', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normalUpload,
                  rules: [
                    {required: request == 0, message: '请上传API 文档'},
                    {
                      validator: (rule, value, cb) => {
                        console.log('validator ', value)

                        if (value && value.length) {
                          const file = value[0]
                          if (file.status == 'done') {
                            cb()
                            return
                          }
                          if (file.size > 5 * 1024 * 1024) {
                            cb('请选择小于5m的文件')
                          } else if (file.status == 'error') {
                            cb(file.response ? file.response.msg : '上传失败，请重试')
                          } else if (!apiTypes.some(sdkType => (new RegExp(sdkType + '$')).test(file.name))) {
                            cb('请上传正确格式的文件')
                          } else {
                            cb()
                          }
                        } else {
                          cb()
                        }
                      }
                    }
                  ]
                })(<Upload
                  accept=".pdf"
                  onChange={this.apiChange}
                  beforeUpload={this.apiBefore}
                  action="/realsafe/server/evaluate/task/upload"
                  data={{type: 1, task_id: taskid}}
                >
                  <Button>上传文件</Button>
                  <span style={{marginLeft: 12}} className="t-opa50">支持扩展名：.pdf 最大不超过5M</span>

                </Upload>)
              }

            </Item>
          </div>
          <div style={{display: step == 4 ? 'block' : 'none'}}>
            <h3>选择数据集</h3>
            <div>数据集将用于生成对抗样本模拟攻击来测评您的服务</div>
            {
              datasetsCustomer.length
                ? null
                : <Alert
                  message={<span className="t-blue">您尚未创建满足此条测评任务的数据集，仅可使用系统内置数据集测评。如需进行更精确的测评，<a className="t-bold t-ul"
                                                                                                  href="/customer/dataset">创建数据集</a></span>}
                  type="info"
                  showIcon
                  style={{marginTop: 12}}
                />
            }

            <Item
              label="选择数据集"
            >
              {getFieldDecorator('datasetType', {initialValue: datasetsCustomer.length ? 0 : 1})(<Radio.Group
                onChange={this.handleDatasetTypeChange}>
                <Radio value={0}>您创建的数据集</Radio>
                <Radio value={1}>系统内置数据集</Radio>
              </Radio.Group>)}
            </Item>
            <Item
              label="选择数据集"
            >
              {getFieldDecorator('datasetId', {
                rules: [{required: true, message: '请选择数据集'}]
              })(<Select style={{width: 304}}>
                {datasets.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
              </Select>)}
            </Item>
          </div>
          {
            step == 5
              ?
              <div>
                <div style={{paddingTop: 0}} className={styles.reviewBox}>
                  <h3>{formValues.name}</h3>
                  <div>{formValues.description}</div>
                </div>
                <Row className={styles.reviewBox}>
                  <h4>待测场景信息</h4>
                  <Col span={12}>
                    <div><strong className="d-ib">应用场景</strong><span>人脸对比</span></div>
                    <div><strong
                      className="d-ib">攻击方向</strong><span>{(formValues.attackDirection || []).map(item => attackHash[item]).join('、')}</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{display:isGuest?'none':'block'}}><strong className="d-ib"
                                 style={{width: 196}}>是否能限制第三方频繁调用</strong><span>{formValues.multiCall ? '是' : '否'}</span>
                    </div>
                    <div><strong className="d-ib"
                                 style={{width: 196}}>是否返回分数信息（如置信度）</strong><span>{formValues.returnScore ? '是' : '否'}</span>
                    </div>
                  </Col>
                </Row>

                <Row className={styles.reviewBox}>
                  <h4>测试方案</h4>
                  <Col span={12}>
                    <div><strong className="d-ib"
                                 style={{width: 56}}>测评方法</strong><span>{(formValues.evaluatingMethod || []).map(item => evaluateHash[item]).join('、')}</span>
                    </div>
                    <div style={{display:isGuest?'none':'block'}}><strong className="d-ib" style={{width: 56}}>步长</strong><span>{formValues.alpha}</span></div>
                  </Col>
                  <Col span={12}>
                    <div style={{display:isGuest?'none':'block'}}><strong className="d-ib"
                                 style={{width: (formValues.evaluatingMethod || []).includes(0) ? 84 : 'unset'}}>扰动大小</strong><span>{2 ** (formValues.magnitude + 1)}</span>
                    </div>
                    {
                      (formValues.evaluatingMethod || []).includes(0)
                        ? <div><strong className="d-ib"
                                       style={{width: 84}}>最大迭代次数</strong><span>{formValues.iteration}</span></div>
                        : null
                    }
                  </Col>
                </Row>
                <Row className={styles.reviewBox}>
                  <h4>接入信息</h4>
                  <Col span={12}>
                    <div><strong className="d-ib"
                                 style={{width: 56}}>接入方式</strong><span>{formValues.request == 1 ? 'SDK 方式' : 'API 方式'}</span>
                    </div>
                    <div style={{display: 'flex'}}>
                      <strong className="d-ib"
                              style={{width: 56}}>{formValues.request == 1 ? 'SDK文档' : 'API文档'}</strong>
                      <span style={{flex: 1}}
                            className="ellipsis">{formValues.request == 1 ? formValues.sdk : formValues.apiDoc}</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div><strong className="d-ib" style={{width: 28}}>阈值</strong>
                      {formValues.threshold
                        ? <span>{formValues.thresholdType == 1 ? '大于等于' : '大于'}{formValues.threshold + '%'}</span>
                        : <span className="t-gray">暂未填写</span>
                      }
                    </div>
                    {
                      formValues.request == 0
                        ? <div><strong className="d-ib" style={{width: 28}}>QPS</strong><span>{formValues.qps}</span>
                        </div>
                        : null
                    }
                  </Col>
                </Row>
                <Row className={styles.reviewBox}>
                  <h4>数据集</h4>
                  <Col span={12}>
                    <div><strong className="d-ib">数据集名称</strong><span>{formValues.datasetName}</span></div>
                  </Col>
                  <Col span={12}>
                  </Col>
                </Row>
              </div>
              : null
          }

          <div style={{marginTop: 40}} className="flex-center">

            {
              step != 0 ? <Button onClick={this.handlePreClick}>上一步</Button> : null
            }
            {
              step != 5
                ? <Button style={{marginLeft: 24}} type="primary" onClick={this.handleNextClick}>下一步</Button>
                : <Button style={{marginLeft: 24}} type="primary" onClick={this.handleSaveClick}>提交</Button>

            }
            <Modal
              title="提交任务"
              visible={this.state.saveModalVisible}

              footer={[
                <Button key="back" onClick={coins>=consume?this.handleSaveOkClick:this.handleSaveCancelClick}>
                  {coins >= consume ? '保存' : '取消'}
                </Button>,
                <Button key="save"  type="primary" onClick={this.handleSubmitOkClick}>
                  {coins >= consume ? '提交测评' : '保存'}
                </Button>

              ]}
              destroyOnClose
            >
              {
                coins >= consume
                  ? <p className="t-gray">提交后任务不可继续修改</p>
                  : null
              }
              <p>已选：{evaluatingMethodStr}</p>
              <p>消耗：{consume}R 币（{coins >= consume
                ? <span className="t-green">剩余{coins}R 币</span>
                : <span className="t-orange">剩余{coins}R 币，余额不足。您可以先保存任务，然后联系和您对接的 RealAI 工作人员充值 R 币再提交测评。</span>
              }）</p>
            </Modal>
          </div>

        </div>
      </div>
    );
  }
}

class Card extends React.Component {
  render() {
    const hash = {
      0: '查询攻击',
      1: '迁移攻击',
      2: '高级迁移攻击'
    }
    const {type, desc, price, isRecommend, checked, style, onClick} = this.props
    return <div onClick={onClick.bind(null, type)} className={`${styles.card} ${checked ? styles.checked : ''}`}
                style={style}>
      <div className={styles.cardTitle}>{hash[type]}{isRecommend ?
        <StatusText style={{marginLeft: 8, fontSize: 12, width: 36, height: 16, padding: '3px 9px'}}
                    type="error">推荐</StatusText> : null}</div>
      <div className={styles.cardDesc}>{desc}</div>
      <div className={styles.price}>
        <span className={styles.priceIcon}><MyIcon type="icon-r"/></span>
        <span className={styles.priceNum}>{price}</span>
      </div>
    </div>
  }
}

export default CustomerTaskAddWrap;
