import React from 'react';
import styles from './style.module.css'
import './style.css'
const ImageContain = (props) => {
  const {src,className}=props
  return (
      <div style={{backgroundImage:`url(${src})`}} className={`${styles.experienceMainViewImg} ${className}`}>
        <img style={{opacity:0}} src={src} alt=""/>
      </div>
  );
};

export default ImageContain;
