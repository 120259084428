import React from 'react'
import {Breadcrumb, Icon,Button,Upload} from 'antd'
import styles from './style.module.css'
import MyIcon from '../../components/MyIcon/MyIcon'
import CustomerExperienceMain from '../../components/CustomerExperienceMain/CustomerExperienceMain'
import {Link} from 'react-router-dom'
import img1 from './img/熊猫01.png'
import img2 from './img/熊猫02.png'
import img3 from './img/熊猫03.png'
export default class CustomerExperience extends React.Component {
  state = {
    switchStatus: true
  }
  handleSwitchChange = () => {
    this.setState({switchStatus: !this.state.switchStatus})
  }

  render() {
    const {switchStatus} = this.state
    return (
      <div className="ui-container-padding">
        <div className="ui-container">
            <div className="ui-bd">
              <div className="ui-bd-ct">
                <div style={{padding:'36px 60px'}}>
                  <h2 className="ui-hd">对抗样本体验</h2>
                  <div className={styles.experience}>
                    <h3 className={styles.desc}>对抗样本概述<span onClick={this.handleSwitchChange} className={styles.switch}><Icon
                      type={switchStatus ? 'up' : 'down'}/></span></h3>
                    <div className={switchStatus?'d-b':'d-n'}>
                      <p>对抗样本是指在模型的输入数据中恶意添加细微的对抗噪声所形成的样本。对抗样本会导致模型给出错误的输出，从而在用户难以感知的情况下，使机器产生错误的决策。</p>
                      <div className={styles.image+' f-cb'}>
                        <div className={styles.imageItemImage}>
                          <span className="ta-c">原始图片</span>
                          <img src={img1} alt=""/>
                          <div className={styles.imgDesc}>
                            <h5 >识别结果：</h5>
                            <div><h5 className="d-ib">熊猫</h5> 置信度 60%</div>
                          </div>
                        </div>
                        <div className={styles.imageItemSymbol+' flex-center'}>
                          <Icon type="plus" />
                        </div>
                        <div className={styles.imageItemImage}>
                          <span className="ta-c">对抗噪声</span>
                          <img src={img2} alt=""/>
                          <div className={styles.imgDesc}><br /><br/></div>

                        </div>
                        <div className={styles.imageItemSymbol+' flex-center'}>
                          <MyIcon type="icon-equal-pure" />
                        </div>
                        <div className={styles.imageItemImage} style={{width:135}}>
                          <div style={{width:120}}>
                            <span className="ta-c">对抗样本</span>
                            <img src={img3} alt=""/>
                          </div>
                          <div className={styles.imgDesc}>
                            <h5 >识别结果：</h5>
                            <div><h5 className="d-ib">长臂猿</h5> 置信度 99.3%</div>
                          </div>
                        </div>
                      </div>
                      <p style={{marginTop:20}}>如在图像分类场景中，对熊猫图片添加上少量的对抗噪声，就可以生成使模型误判为长臂猿的对抗样本。</p>
                      <h3>体验说明</h3>
                      <p>此次对抗样本攻防体验场景为人脸比对，人脸比对被各行业广泛用于身份认证。攻破人脸比对模型意味攻击者可以冒充他人解锁手机、进行金融远程开户……严重危害相关业务和社会的安全。</p>
                      <p>体验时，您可以选择或上传两张不相同的人脸照片，观察对抗样本如何误导人脸比对模型。在体验过对抗样本攻击后，您还可以继续体验对抗样本去噪器：通过去除攻击者恶意添加的对抗噪声，从而有效防御对抗样本攻击，提升人工智能系统的安全性。</p>
                    </div>
                    <div className={styles.experienceDemo}>
                      <h3 style={{marginTop:28}}>对抗样本攻防演示</h3>
                      <div className={styles.experienceDemoMain}>
                        <CustomerExperienceMain></CustomerExperienceMain>
                      </div>
                    </div>
                    <div className={styles.experienceOhter}>
                      <h3>您可以右键复制图片地址在第三方体验界面测试</h3>
                      <a target="_blank" href="https://data.aliyun.com/ai#/face-comparison">阿里人脸对比体验服务</a>
                      <a target="_blank" href="https://ai.baidu.com/tech/face/compare">百度人脸对比体验服务</a>
                      <a target="_blank" href="https://ai.qq.com/product/face.shtml#compare">腾讯人脸对比体验服务</a>
                    </div>
                  </div>
                </div>

              </div>

            </div>
        </div>
      </div>

    )
  }
}
