import React from 'react';
import { Empty } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Card from 'components/Card';
import ReactEcharts from "echarts-for-react";
import memoize from "memoize-one";

const colors = {
  "NAttack": "#388DEC",
  "SPSA": "#8E48ED",
  "Boundary": "#388DEC",
  "Evolutionary": "#8E48ED",
  "BIM": "#ec8d42",
  "Feature-InputDiversity-MIM": "#ecce42",
  "MIM": "#38cbe4",
  "Feature-InputDiversity-BIM": "#398dec",
  "TIM": "#4d74f2",
  "Feature-InputDiversity-TIM": "#6848ee",
};
const getOptions = memoize((dataSource) => {
  if (dataSource && dataSource.length <= 0) return;

  const category = [];
  Object.keys(dataSource).map((req) => category.push(req));

  const arrList = [];
  let XList = [];

  Object.values(dataSource).map((req, index) => {
    XList = [];
    const arrayList = [];
    const xAxis = [];
    req.map((req2, index2) => {
      arrayList.push([req2[0], req2[1]]);
      XList.push(req2[0]);
      xAxis.push(req2[1]);
    })

    arrList.push({
      name: category[index],
      value: xAxis,
      arrayList,
    });
  })


  return ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {// 坐标轴指示器，坐标轴触发有效
        type: 'cross', // 默认为直线，可选为：'line' | 'shadow' | cross
        label: {
          backgroundColor: '#298AEC',
        },
        lineStyle: {
          color: '#298AEC',
          type: 'shadow',
        },
        show: true,
      },
      position: (pos) => {
        return {
          top: pos[1],
          left: (pos[0] + 20),
        };
      },
      extraCssText: 'width:auto',
      formatter: (data) => {
        let relVal = `<div>${data[0].axisValue}</div>`;
        if (data) {
          const dataList = data.sort((a, b) => b.data.value - a.data.value);

          dataList.map((req) => {
            let value = `${req.value}` ? `${req.value}` : '-';
            relVal += `<article style='display:flex;dispaly:-webkit-flex;font-size:12px;padding-right:12px'><i style='width:10px;height:10px;border-radius:50%;background: ${req.color};position: relative;top: 6px;margin-right:3px'></i><div>${req.seriesName}：${value}</div></article>`;
            return relVal;
          });
        }
        return relVal;
      },
    },
    grid: {
      top: (category.length >= 4 && category.length < 8) ? "28%" : (category.length <=3 ? '16%' : (category.length > 8 ? '30%' : '10%')),
      left: '0',
      right: '5%',
      bottom: '3%',
      show: true,
      borderWidth: '0.2',
      containLabel: true,
    },
    legend: {
      show: category && category.length > 0 ? true : false,
      data: category || [],
      //type: category && category.length > 5 ? 'scroll' : '',
      icon: 'rect',
      left: 0,
      top: 0,
      textStyle: {
        fontSize: 10,
        borderRadius: 12,
        padding: [0, 0, 0, 0],
      },
      itemWidth: 10,
      itemHeight: 10,
    },
    xAxis: {
      boundaryGap: false,
      data: XList,
      axisLabel: {
        show: true,
        fontSize: 10,
        fontWeight: 100,
        fontFamily: 'PingFangSC-Regular',
        textStyle: {
          //color: '#a7aab3',
          color: '#212A3A',
          align: 'center',
          fontSize: 10,
        },
        margin: 16,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#E9EBF1',
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: '#E9EBF1',
          type: 'solid',
        },
      },
    },
    yAxis: [
      {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          fontSize: 12,
          fontWeight: 100,
          fontFamily: 'PingFangSC-Regular',
          textStyle: {
            color: '#212A3A',
            align: 'center',
            fontSize: 12,
          },
          margin: 16,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#E9EBF1',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E9EBF1',
            type: 'solid',
          },
        },
      }
    ],
    series: arrList && arrList.map(req => {
      const { name, value } = req;
      return ({
        type: 'line',
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 2,
          color: colors[name] || '',
        },
        name,
        color: colors[name] || '',
        data: [...value],
      });
    })
  });
});

function Line(props) {
  const { dataSource } = props;
  const options = getOptions(dataSource);

  return dataSource ? (
    <ReactEcharts
      option={options}
      notMerge
      style={{ width: "275px", height: '300px' }}
      loadingOption={{
        text: '加载中...',
      }}
    />
  ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
}

Line.propTypes = {
  dataSource: PropTypes.arrayOf({}),
};

Line.defaultProps = {
  dataSource: null,
};
export default Line;


