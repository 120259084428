import React, {Component} from 'react';
import {Button,Breadcrumb} from "antd";
import CustomerTaskListWrap from "../CustomerTask/CustomerTaskList";
import CustomerDefenseDenoisingWrap from 'components/CustomerDefense/CustomerDefenseDenoisingWrap'
import { getCookie, setCookie, clearCookie, clearAllCookie } from 'public/utils/com';
import { Link } from 'react-router-dom';


class CustomerDefenseDenoising extends Component {
  componentDidMount() {
    console.log('user',this.props.userInfo)
  }

  render() {
    const {userInfo}=this.props
    if(userInfo&&userInfo.userType==3){
      location.href='/customer/403'
      return null
    }
    return (
      <div className="ui-container-padding">
        <div className="ui-container">
          <div className="ui-bd-transparent">
            <div className="ui-bd-ct">
              <CustomerDefenseDenoisingWrap></CustomerDefenseDenoisingWrap>

            </div>
          </div>

        </div>
      </div>

    );
  }
}

export default CustomerDefenseDenoising;
