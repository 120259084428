import React from 'react';
import { Collapse, Tooltip, message } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { copyText } from 'public/utils/com';
import copyImg from  './copy.svg'
const { Panel } = Collapse;

function Collapses(props) {
  const { dataSource } = props;
  const { output, input, status } = dataSource;


  const [inputList, setInputList] = React.useState([]);
  const [outputList, setOutputList] = React.useState([]);


  React.useEffect(() => {
    if (input && input.length > 0) {
      let list = !status ? [0, 2] : [0, 1];
      setInputList(list);

      let list2 = !status ? [0] : [1];
      setOutputList(list2);
    }
  }, [status, input]);

  return (
    <CollapseTo>
      <Header>
        <span className="title">输入</span>
        <section className="flex text">
          <span style={{ cursor: 'default' }}>图片</span>
          <span style={{ cursor: 'default', paddingLeft: '8px' }}>url</span>
        </section>
        {
          input ? (
            inputList.map((req, index) => {
              let styleName = (index === (inputList.length - 1)) ? {
                marginTop: '-12px',
                marginBottom: '6px',
              } : {};
              return input && (
                <section className="flex text item" style={styleName}>
                  <div className="copyDiv">
                    <Tooltip placement="topLeft" title={input[req].path && input[req].path.split('/').pop()}>
                      <div className="showCopyDiv ellipsis">{input[req].path && input[req].path.split('/').pop()}</div>
                    </Tooltip>
                    <input readonly id={`copyInput${req}`} className="ellipsis" value={input[req].path} />
                  </div>

                  <span onClick={
                    () => {
                      copyText(`copyInput${req}`, (data) => {
                        const { type, message } = data;
                        message[type](message);
                      })
                    }
                  }>
                    <img src={copyImg} />
                  </span>
                </section>
              )
            })
          ) : (
              <section className="flex text">
                <span style={{ cursor: 'default', color: '#212A3A' }} >--</span>
                <span style={{ cursor: 'default', color: '#212A3A' }}>--</span>
              </section>
            )
        }
      </Header>
      <Header>
        <span className="title">输出</span>
        <section className="flex text">
          <span style={{ cursor: 'default' }}>是否为同一人</span>
          <span style={{ cursor: 'default' }}>相似度</span>
        </section>
        {
          output ? (
            outputList.map(req => {
              return output && (
                <section className="flex text item">
                  <span style={{ cursor: 'default' }}>{(output[req] && output[req][0]) ? '是' : '否'}</span>
                  {
                    (output[req] && ((`${output[req][1]}`) != '' && (`${output[req][1]}`) != 'null' && (`${output[req][1]}`) != 'undefined')) ? (<span style={{ cursor: 'default' }}>{((+output[req][1]) * 100).toFixed(0)}%</span>) : (<span style={{ cursor: 'default' }}>--</span>)
                  }
                </section>
              )
            })
          ) : (
              <section className="flex text">
                <span style={{ cursor: 'default', color: '#212A3A' }} >--</span>
                <span style={{ cursor: 'default', color: '#212A3A' }}>--</span>
              </section>
            )
        }
      </Header>
    </CollapseTo>
  )
}

Collapses.propTypes = {
  dataSource: PropTypes.shape({}),
};

Collapses.defaultProps = {
  dataSource: null,
};
export default Collapses;

const CollapseTo = styled.article`
  .ant-collapse-content-active{
    background-color: rgba(230,235,243,0.3);
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #212A3A;
    line-height: 18px;
  }
`
const Header = styled.header`
  .title{
    background:rgba(230,235,243,0.5);
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #212A3A;
    padding:20px;
    display:block;
    border-top:1px solid rgba(201,212,230,0.20);
    border-bottom:1px solid rgba(201,212,230,0.20);
  }
  .flex.text{
    display:flex;
    display:-webkit-flex;
    padding:20px;
    padding-bottom: 12px;
    span{
      dispaly:block;
      width:60px;
      text-align:center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(33,42,58,0.30);
      line-height: 20px;
      cursor: pointer;
      &:first-child{
        flex:1;
        -webkit-flex:1;
        width:100%;
        text-align:left;
      }
    }
  }
  .flex.item{
    padding:12px 20px;
    img{
      width:16px;
      height:16px;
    }
    span{
      opacity: 0.9;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #212A3A;
    }
  }
  .ellipsis{
    font-family: PingFangSC-Regular;
    font-size: 14px !important;
    color: #212A3A !important;
    border:none;
    background:transparent;
    width: 100%;
    flex: 1;
    -webkit-flex: 1;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .copyDiv{
    width:100%;
    position: relative;
    .showCopyDiv{
      position: absolute;
      line-height: 23px;
      height: 23px;
      cursor: pointer;
    }
    
    input{
      border: none;
      background: none;
      outline: none;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      opacity: 0.9;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #212A3A;
      position:relative;
      z-index:-1;
      opacity:0
    }
    input,input:focus{
      border: none !important;
      box-shadow: none !important;
    }
  }
  
`;
