import React from 'react';
import { Empty } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Card from 'components/Card';
import ReactEcharts from "echarts-for-react";
import memoize from "memoize-one";

let declineAndRiseMap = {
  "rise": {
    "title": "安全性变化",
    "color": "#0ECB8A",
  },
  "decline": {
    "title": "模型效果变化",
    "color": "#F45343",
  }
}
const getOptions = memoize((dataSource) => {
  if (dataSource && dataSource.length <= 0) return;

  const yAxis = [];
  const arrayList = [];
  Object.values(dataSource).map(req => yAxis.push(req.name));
  Object.keys(declineAndRiseMap).map(req => {
    let list = [];
    Object.values(dataSource).map(req2 => {
      list.push(req2[req]);
    });
    arrayList.push({
      name: declineAndRiseMap[req].title,
      value: [...list],
      color: declineAndRiseMap[req].color
    });
  })

  return ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter: function (params) {
        var relVal = params[0].name;
        for (var i = 0, l = params.length; i < l; i++) {
          relVal += '<br/>' + params[i].seriesName + ' : ' + ((+params[i].value) * 100).toFixed(0) + "%";
        }
        return relVal;
      }
    },
    grid: {
      top: 0,
      left: 0,
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'value',
        boundaryGap: false,
        axisLabel: {
          show: true,
          fontSize: 12,
          fontWeight: 100,
          fontFamily: 'PingFangSC-Regular',
          textStyle: {
            color: '#212A3A',
            align: 'center',
            fontSize: 12,
          },
          margin: 20,
          formatter: (data) => {
            const value = data || 0;
            return `${(value).toFixed(2) * 100}%`;
          },
        },

        axisLine: {
          show: true,
          lineStyle: {
            color: '#E9EBF1',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E9EBF1',
            type: 'solid',
          },
        },

      }
    ],
    yAxis: [
      {
        type: 'category',
        axisTick: {
          show: false
        },
        inverse: true,
        data: yAxis,
        axisLabel: {
          show: true,
          fontSize: 12,
          fontWeight: 100,
          fontFamily: 'PingFangSC-Regular',
          textStyle: {
            color: '#212A3A',
            fontSize: 12,
            fontWeight: 'bold',
          },
          margin: 10,
          align: 'right',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#E9EBF1',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E9EBF1',
            type: 'solid',
          },
        },
      }
    ],
    series: arrayList && arrayList.map(req => {
      const { name, value, color } = req;
      return ({
        type: 'bar',
        color,
        name,
        data: [...value],
      });
    })
  });
});


function Bar(props) {

  const { dataSource } = props;

  React.useEffect(() => {
  }, [dataSource]);

  const options = getOptions(dataSource);

  return (dataSource && dataSource.length > 0) ? (
    <ReactEcharts
      option={options}
      notMerge
      style={{ height: '400px' }}
      loadingOption={{
        text: '加载中...',
      }}
    />
  ) : '';
}

Bar.propTypes = {
  dataSource: PropTypes.arrayOf({}),
};

Bar.defaultProps = {
  dataSource: null,
};
export default Bar;


