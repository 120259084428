import React from 'react';
import { Empty, Table } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';


function Card(props) {
  const { children, className, style } = props;
  return (
    <CardContainer className={className} style={style}>
      {children}
    </CardContainer>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({})
};


export default Card;

const CardContainer = styled.div`
  background: #ffffff;
  height: calc(100vh - 90px);
  border-radius: 3px 3px 0 0;
  overflow: auto;
`