import React, { useState, Suspense } from 'react';
import 'components/common/common.less'
import './app.css'
import CustomerSider from 'components/Sider/index';
import CustomerHeader from 'components/CustomerHeader/CustomerHeader';
import { renderRoutes } from "react-router-config";
import {getUserInfo} from './api'
class App extends React.Component {
  state = {
    siderWidth: 180,
    headerHeight: 52,
    userInfo:null
  }
  componentDidMount() {
    getUserInfo().then(result=>{
      if(result.code==0){
        this.setState({userInfo:result.data});
      }
    })
  }

  componentWillMount() {
    const { noside } = this.props.route
    if (noside) {
      this.setState({ siderWidth: 0 });
    }
  }
  getMenuUrl=()=>{
    const routes=this.props.route.routes
    const pathname=this.props.location.pathname
    const menuUrl=(routes.find(route=>route.path==pathname)||{}).menuUrl
    return menuUrl;
  }
  render() {
    const { route, history } = this.props
    const { siderWidth, headerHeight,userInfo } = this.state
    const props={userInfo}
    if(userInfo===null){
      return null
    }
    return (
      <div style={{ paddingLeft: siderWidth + 'px', }} className="app">
        <aside style={{ width: siderWidth + 'px' }} className="left">
          <CustomerSider menuUrl={this.getMenuUrl()} history={history}></CustomerSider>
        </aside>
        <div style={{ paddingTop: headerHeight + 'px' }} className="right">
          <header style={{ width: `calc(100%)` }} className="header" >
            <CustomerHeader userInfo={userInfo}></CustomerHeader>
          </header>
          <div className="content">
            {renderRoutes(route.routes,{...props})}
          </div>
        </div>
      </div>
    );
  }
}


export default App;

