import React from 'react';
import { Empty, Table, Popover, message } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import '../../../../public/less/';
import './index.css'
import { exitLogin } from 'public/api';
import help from './help.svg'
import avatar from './avatar.svg'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { getCookie, setCookie, clearCookie, getQueryVariable, clearAllCookie } from 'public/utils/com';


function Header(props) {
  const {
    dataSource, type, isHome,userInfo={}
  } = props;


  let { pathname } = window.location;
  let code = null;
  let pathNameCheck = '';
  pathNameCheck = pathname && pathname.split('/');
  pathNameCheck = (pathNameCheck && pathNameCheck[1]) || pathname;
  if (pathNameCheck && pathNameCheck.indexOf("customer") >= 0) {
    code = 1;
  } else if (pathNameCheck && pathNameCheck.indexOf("platform") >= 0) {
    code = 2;
  }
  code = code || (getCookie('code')) || '';
  let userName = code && (code == '1' ? decodeURI(getCookie('userName') || '') : decodeURI(getCookie('userName') || ''));
  const color = props.color
  console.log('userInfo',userInfo)
  return (
    <div className="headerContainer" style={{ position: 'relative' }}>
      {isHome ? <HeaderLogo>RealSafe</HeaderLogo> : null}
      <MyAccount>
        {
          type && type === 1 && (
            <Link
              target="_blank"
              className="headerContainer-help"
              to="/customer/help">
              <div className="help-pure">
                <img src={isHome ? help : '/img/icon/help.svg'} />
                <span>
                  帮助文档
                </span>
              </div>
            </Link>
          )
        }
        <Popover placement="bottom" content={
          <div className="headerContainer-exitUserName">
            {
              type && type === 1 && (
                <Link
                  style={{ color: 'rgb(33, 42, 58)' }}
                  to="/customer/personal">
                  <div className="headerContainer-personal">财务管理</div>
                </Link>
              )
            }
            <div
              style={{ minWidth: '60px' }}
              className="headerContainer-exit"
              onClick={() => {
                let code = (type || getCookie('code') || 2);
                if ([1, 2].indexOf(code) < 0) return;

                exitLogin().then(result => {
                  if (result.code == 0) {
                    clearAllCookie();
                    if (code === 1) {
                      window.location.href = '/customer/login';
                    } else if (code === 2) {
                      window.location.href = '/platform/login';
                    }
                  } else {
                    message.error(result.msg);
                  }
                })
              }}>退出登录</div>
          </div>
        } trigger="hover">
          <div className="headerContainer-user">
            <img src={isHome ? avatar : '/img/icon/user.svg'} alt="" />
            <span className="MyCard">{userInfo ? userInfo.userName : "我的账户"}</span>
          </div>
        </Popover>
      </MyAccount>
    </div>
  )
}


export default Header;

const HeaderLogo = styled.div`
  font-size:26px;
  color:rgba(0, 0, 0, 0.4);
  height:52px;
  position:absolute;
  left:0;
  line-height:52px;
   color:#ffffff;

`

const HCon = styled.div`
  height:50px;
  box-shadow: 1px 1px 5px 1px #c3c3c3;
  width: 100%;
  display: block;
`
const MyAccount = styled.div`
  font-size: 14px;
  line-height: 45px;
  position: relative;
  right: 20px;
  cursor: pointer;
  float:right;
  .MyCard{
    margin-left:3px;
    position: relative;
    right:0;
    top: 2px;
  }
  display:flex;
  dispaly:-webkit-flex;
  .help-pure{
    margin:0 12px;
    img{
      width:26px;
      margin-right:5px;
      height:26px;
      font-size:20px;
      position: relative;
      top: 1px;
    }
    span{
      position: relative;
      top: 2px;
      font-size: 14px;
      left: 0px;
      margin-left: 3px;
    }
  }
`
const MyIcon = styled.span`
  font-size:26px;
  position: relative;
  display:inline-block;
  top: 3px;
  color:#dddddd;
`;
