import React, {Component} from 'react';
import {Breadcrumb} from "antd";
import queryString from "query-string";
import CustomerTaskAddWrap from 'components/CustomerTask/CustomerTaskAddWrap'

class CustomerTaskEdit extends Component {
  componentWillMount() {
    const query = queryString.parse(location.search)
    const {id} = query
    this.id = id
  }

  render() {
    const {userInfo={}}=this.props

    return (
      <div className="ui-container-padding">
        <div className="ui-container">
          <div className="ui-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item><a href="/customer/task/list">测评任务管理</a></Breadcrumb.Item>
              <Breadcrumb.Item>编辑测评任务</Breadcrumb.Item>
            </Breadcrumb>
          </div>
              <div className="ui-bd-bread">
                <div className="ui-bd-ct">
                  <CustomerTaskAddWrap isGuest={userInfo.userType==3} isEdit={1} history={this.props.history} taskid={this.id}></CustomerTaskAddWrap>

                </div>
              </div>
        </div>
      </div>
    );
  }
}

export default CustomerTaskEdit;
