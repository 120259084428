import React from 'react'
import {Menu} from 'antd';

const {SubMenu} = Menu;
import {getCustomerMenu,getPlatformMenu} from 'public/api'
import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom'
import styled, {css} from 'styled-components';
import {findTreeNode, mapTreeNode, treeToArray} from '@/tree';
import {getCookie, setCookie, clearCookie} from 'public/utils/com';
import 'public/less';
import './index.css';

function flatten(data, parentId) {
  return data.reduce((arr, {id, name, url, children = []}) =>
    arr.concat([{id, name, url, parentId}], flatten(children || [], id)), [])
}

export default class CustomerSider extends React.Component {
  state = {
    data: [],
    openKeys: [],
  }


  getIdByUrl = (url) => {
    const menuArr = flatten(this.state.data)
    console.log('menuArr', menuArr)
    const menuInfo = menuArr.find(menuItem => menuItem.url == url)
    if(menuInfo){
      return [menuInfo.id+'']
    }
    return []
  }

  componentDidMount() {
    let getMenu=getCustomerMenu
    if(this.props.type=='platform'){
      getMenu=getPlatformMenu
    }
    getMenu().then((result) => {
      if (result.code == 0) {
        const data = result.data
        let openKeys=[]
        data.forEach(item => {
          if (item.children) {
            item.children.forEach(child => {
              if (child.url === this.props.menuUrl) {
                openKeys=[item.id+'']
              }
            })
          }
        })
        this.setState({data,openKeys});

      }
    })
  }

  render() {
    const data = this.state.data || []
    console.log('data-->', this.props.menuUrl, this, this.props.onChange)
    const selectedKey = this.getIdByUrl(this.props.menuUrl)
    console.log('selectedKey', this.props.menuUrl, selectedKey, this.state.openKeys,data)
    return (
      <div className="customerSider">
        <Link to="/customer"><Logo/></Link>
        {data&&data.length
          ? <MenuContainer
          defaultOpenKeys={this.state.openKeys}
          mode="inline"
          selectedKeys={selectedKey}
        >
          {data.map(item => {
            if (item.children) {
              return (
                <SubMenu
                  key={item.id}
                  title={
                    <span to={item.url}>
                      <Icon className={`iconfont ${item.icon}`}/>
                      <span>{item.name}</span>
                    </span>
                  }
                >
                  {item.children && item.children.map(item => <Menu.Item key={item.id}><Link to={item.url}>{item.name}</Link></Menu.Item>)}
                </SubMenu>
              )
            }
            return (
              <Menu.Item key={item.id}>
                <Link to={item.url}>
                  <Icon className={`iconfont ${item.icon} menuICON`}/>
                  {item.name}
                </Link>
              </Menu.Item>
            )
          })}
        </MenuContainer>
        :null}
      </div>
    );
  }
}


const Logo = styled.div`
  height:51px;
  line-height:51px;
  text-align: center;
  color:#ffffff;
  background:url('/img/icon/logo.svg') no-repeat center center;
  background-size: 70%;
  margin: 0 auto;
`

const Icon = styled.span`
  margin-right:8px;
`;

const MenuContainer = styled(Menu)`
  min-height: 100%;
  height: 100%;
  width:100%;
  color: #c5dcf6;
  background: #298AEC;
  border:none;
  
  li{
    a{
      color: #c5dcf6;
    }
    .ant-menu-submenu-title{
      i.ant-menu-submenu-arrow{
        &::before,&::after{
          background:#c5dcf6;
        }
      }
    }

    &.ant-menu-item-active:hover,&.ant-menu-item-selected:hover{
      background-color:#2c90e7;
      a,span{
        color:rgba(255, 255, 255, 1) !important;
        font-weight: bold !important;
      }
    }
  }
  li.ant-menu-submenu-active:hover {
    background:#2c90e7;
    i.ant-menu-submenu-arrow{
      &::before,&::after{
        background:#ffffff;
      }
    }
  }
 
  ul.ant-menu-sub{
    background: #298AEC;
  }
  .ant-menu-item-selected{
    background:rgba(255, 255, 255, 0.15) !important;
    color:#ffffff !important;
    font-weight: bold !important;
    &::after{
      border-right: 3px solid rgba(255, 255, 255, 0.15);
    }
    a{
      color:rgba(255, 255, 255, 1) !important;
      font-weight: bold !important;
    }
  }
  .ant-menu-submenu-title{
    color:#ffffff !important;
  }

  .iconfont.icon-mould-evaluation{
    display:inline-grid;
    width:15px;
    height:15px;
    
    &::before{
      content:'';
      position: relative;
      top:2px;
      background:rgba(255, 255, 255, 0.7);
      mask: url('/img/icon/icon-mould-evaluation.svg') no-repeat center;
    }
  }

  .iconfont.icon-defense-solution{
    display:inline-grid;
    width:15px;
    height:15px;
    
    &::before{
      content:'';
      background:rgba(255, 255, 255, 0.7);
      mask: url('/img/icon/icon-defense-solution.svg') no-repeat center;
      position: relative;
      top:2px;
    }
  }
`;
