import React from 'react';
import { Empty, Table, Button, Tooltip, message, Breadcrumb } from 'antd';
import * as PropTypes from 'prop-types';
import Card from 'components/Card';
import { taskReports } from 'public/api'
import { checkMenu } from 'components/PlatformSider/com';
import styles from './style.module.css';
import Line from 'components/Report/chart/Line';
import Collapses from 'components/Report/collapse';
import Wheel from 'components/Report/wheel'
import { QuestionCircleOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { formatCurrency, getQueryVariable } from 'public/utils/com';

function Presentation(props) {

  const {
    dataSource, onChange, location, history
  } = props;
  const { state, pathname } = location;
  const [dataInfo, setDataInfo] = React.useState({
    query_attack: {
      magnitude: {},
      query: {},
    },
    migrate_attack: {
      magnitude: {},
    },
    breach_case: [],
  });

  const [task_title, setTask_title] = React.useState(null);
  const [doc, setDoc] = React.useState(null);
  React.useEffect(() => {
    let task_id = state && state.task_id || getQueryVariable("task_id") || '';
    let task_title = state && state.task_title || getQueryVariable("task_title") || '';
    if (!task_id) return message.error("task_id 丢失");

    task_id = task_id || (state && state.task_id) || '';
    setTask_title(task_title);


    taskReports({
      id: task_id
    }).then(result => {
      const { data, code } = result;
      if (code == 0) {
        setDoc(data.doc);
        let info = JSON.parse(data.report);
        info.safe_score = formatCurrency(info.safe_score || 0);
        setDataInfo(info);
      }
    })
  }, []);

  const [wheelInfo, setWheelInfo] = React.useState({
    output: [],
    input: [],
    status: true,
  });

  let BreadcrumbTo2 = sessionStorage.CustomerBreadcrumbTo && JSON.parse(decodeURIComponent(sessionStorage.CustomerBreadcrumbTo)) || {}
  const taskDetailsUrl = BreadcrumbTo2['/customer/task/detail'] ? `/customer/task/detail?${BreadcrumbTo2['/customer/task/detail']}` : '';

  return  <div className="ui-container-padding">
    <div className="ui-container">
      <div className="ui-breadcrumb">
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item><a href="/customer/task/list">测评任务管理</a></Breadcrumb.Item>

          {getQueryVariable('source') === 'details' && (
            <Breadcrumb.Item>
              {taskDetailsUrl ? <a href={taskDetailsUrl}>任务详情</a> : <span style={{color:'rgba(33, 42, 58, 0.9)'}}>任务详情</span>}
            </Breadcrumb.Item>
          )}

          <Breadcrumb.Item>
            <span style={{ cursor: 'default' }}>查看测评报告</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="ui-bd-bread">
        <div className="ui-bd-ct">
          <article style={{padding:'36px 60px'}}>
            <div className={styles.taskTitle}>
              {decodeURI((state && state.task_title) || task_title)}
              <Link to='/customer/defense'>
                <Button style={{ minWidth: '145px', top: '0' }} type="primary" className={`${styles.detailsButton} ${styles.opend}`}>查看防御解决方案</Button>
              </Link>
            </div>
            <div className={styles.safetyScore}>
              安全评分
              <Tooltip className={styles.safetyScoreQuestion} placement="top" title={'安全评分根据您的模型在对抗样本攻击下的表现进行评分，满分为100分，评分越高则模型安全性越高。'}>
                <a target="_blank" href={'/customer/help#评分方法'}>
                  <QuestionCircleOutlined />
                </a>
              </Tooltip>
            </div>
            {
              (`${dataInfo.safe_score}`) != 'undefined' && (`${dataInfo.safe_score}`) != '' && (
                <div className={styles.score}>
                  <span>{(dataInfo.safe_score.split(".") && dataInfo.safe_score.split(".")[0]) || dataInfo.safe_score}</span>
                  {
                    /* dataInfo.safe_score.split(".")[1] && (
                      <span>.{dataInfo.safe_score.split(".")[1]}</span>
                    ) */
                    <span></span>
                  }
                </div>
              )
            }

            <section className={`${styles.flex} ${styles.detailScore}`}>
              <div>
                查询攻击评分：
                {
                  (`${dataInfo.query_attack_score}`) != 'undefined' ?
                    (
                      <span>{dataInfo.query_attack_score}</span>
                    ) :
                    (
                      <span style={{ fontSize: '14px', fontFamily: 'PingFangSC-Regular' }}>暂未测评</span>
                    )
                }
              </div>
              <div>
                迁移攻击评分：
                {
                  (`${dataInfo.migrate_attack_score}`) != 'undefined' ?
                    (
                      <span>{dataInfo.migrate_attack_score}</span>
                    )
                    :
                    (
                      <span style={{ fontSize: '14px', fontFamily: 'PingFangSC-Regular' }}>暂未测评</span>
                    )
                }
              </div>
            </section>
            <article className={`${styles.testEchart}`}>
              <section className={styles.title}>测评图表</section>
              <section className={`${styles.flex} ${styles.chart}`}>
                {
                  (`${dataInfo.query_attack_score}`) != 'undefined' && (
                    <div className={styles.left}>
                      <div className={styles.title}>查询攻击测评</div>
                      <section className={`${styles.flex} ${styles.attack}`}>
                        {
                          (dataInfo.query_attack.magnitude && Object.getOwnPropertyNames(dataInfo.query_attack.magnitude).length > 0) && (
                            <div>
                              <Line
                                dataSource={dataInfo.query_attack.magnitude || {}}
                              />
                              <span className={`${styles.attackDetails}`}>扰动大小</span>
                            </div>
                          )
                        }
                        {
                          (dataInfo.query_attack.query && Object.getOwnPropertyNames(dataInfo.query_attack.query).length > 0) && (
                            <div>
                              <Line
                                dataSource={dataInfo.query_attack.query || []}
                              />
                              <span className={`${styles.attackDetails}`}>迭代次数</span>
                            </div>
                          )
                        }
                      </section>
                    </div>
                  )
                }
                {
                  (`${dataInfo.migrate_attack_score}`) != 'undefined' && (
                    <div className={styles.right}>
                      <div className={styles.title}>迁移攻击测评</div>
                      <section>
                        <Line
                          type="migrate_attack"
                          dataSource={dataInfo.migrate_attack.magnitude || {}}
                        />
                        <span className={`${styles.attackDetails}`}>扰动大小</span>
                      </section>
                    </div>
                  )
                }
              </section>
            </article>
            <article className={styles.case}>
              <div className={styles.title}>攻破案例</div>
              {
                (dataInfo.breach_case.length > 0 || wheelInfo.input.length > 0) ?
                  (<article className={`${styles.flex} ${styles.caseOne}`}>
                    <section className={`${styles.left}`}>
                      <Wheel
                        dataSource={dataInfo.breach_case || []}
                        onChange={(data, status) => {
                          data.status = status;
                          setWheelInfo({ ...data });
                        }}
                      />
                    </section>
                    <section className={`${styles.right}`}>
                      <Collapses
                        dataSource={wheelInfo || {}}
                      />
                    </section>
                  </article>) : (<div style={{
                    fontFamily: 'PingFangSC-Regular',
                    fontSize: '14px',
                    color: 'rgba(33, 42, 58, 0.5)',
                    lineHeight: '14px'
                  }}>
                    暂无攻击成功案例
                  </div>)
              }
            </article>
            {
              dataInfo.safe_score != '' && Number(dataInfo.safe_score) !== 100 && (
                <article className={styles.suggestions}>
                  <div className={styles.title}>防御建议</div>
                  {
                    (dataInfo.safe_score && +dataInfo.safe_score == 100) ? (
                      <article>
                        <section>
                          1、建议选择相应的解决方案，并严格控制访问次数不超过
                          <a>4000次</a>
                          （在较强攻击算法测试下，基于单个图片的访问超过4000次精度会下降50%）
                        </section>
                        <section>
                          2、RealSafe 提供了
                          <a>5种通用防御方案</a>
                          ，可有效提高模型的安全，请查看了解
                        </section>
                      </article>
                    ) : (
                      <article>
                        <section style={{ marginBottom: '5px;' }}>
                          {
                            doc && doc.split(';').map((req, index) => {
                              return <section>{index + 1}、{req}</section>;
                            })
                          }
                        </section>
                      </article>
                    )
                  }
                </article>
              )
            }
            <LinkTo>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                style={{ marginRight: '20px' }}
              >
                返回
              </Button>
              <Link to='/customer/defense'>
                <Button type="primary" style={{ minWidth: '145px' }} className={`${styles.detailsButton}`}>查看防御解决方案</Button>
              </Link>
            </LinkTo>
          </article>

        </div>
      </div>
    </div>
  </div>
}

Presentation.propTypes = {
  dataSource: PropTypes.arrayOf({}),
};

Presentation.defaultProps = {
  dataSource: null,
};
export default Presentation;


const LinkTo = styled.article`
  text-align: center;
  display: block;
  margin: 30px 0;
`;
