// 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
export  function underline2Hump(s) {
    return s.replace(/_(\w)/g, function(all, letter) {
        return letter.toUpperCase()
    })
}

// 字符串的驼峰格式转下划线格式，eg：helloWorld => hello_world
export  function hump2Underline(s) {
    return s.replace(/([A-Z])/g, '_$1').toLowerCase()
}

// JSON对象的key值转换为驼峰式
export function jsonToHump(obj) {
    if (obj instanceof Array) {
        obj.forEach(function(v, i) {
            jsonToHump(v)
        })
    } else if (obj instanceof Object) {
        Object.keys(obj).forEach(function(key) {
            var newKey = underline2Hump(key)
            if (newKey !== key) {
                obj[newKey] = obj[key]
                delete obj[key]
            }
            jsonToHump(obj[newKey])
        })
    }
    return obj
}

// JSON对象的key值转换为下划线格式
export function jsonToUnderline(obj) {
    if (obj instanceof Array) {
        obj.forEach(function(v, i) {
            jsonToUnderline(v)
        })
    } else if (obj instanceof Object) {
        Object.keys(obj).forEach(function(key) {
            var newKey = hump2Underline(key)
            if (newKey !== key) {
                obj[newKey] = obj[key]
                delete obj[key]
            }
            jsonToUnderline(obj[newKey])
        })
    }
    return obj

}
//匹配下划线命名的字符串，只捕获下划线及之后的一个字母
//如给定字符串:ab_cd_ef,多次匹配之后，结果是[_c,_e]
function matchUnderlineReg() {
    //斜杠声明正则的方式会报错
    return new RegExp('(?<=[a-z]+)_([a-z])')
}
