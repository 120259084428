import React from 'react';
import styles from './style.module.css'
const SlideItem = (props) => {
  const {img1,img2,checked}=props
  return (
    <div >
     <div className={styles.slideItemImgWrap}>
       <div className={styles.slideItemImg}><img src={img1} alt=""/></div>
       <div className={`${styles.slideItemImg} ${styles.ml1}`}><img src={img2} alt=""/></div>
     </div>
      {
        checked
          ?  <div className={styles.slideChecked}>

          </div>
          :<div className={styles.slideMask}></div>
      }

    </div>
  );
};

export default SlideItem;
