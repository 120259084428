import cloneDeep from 'lodash/cloneDeep';

export function treeToArray(nodes, key = 'id', childrenKey = 'children', path = [], pathName = []) {
  if (nodes == null || nodes.length === 0) {
    return [];
  }

  return nodes.reduce(
    (p, node) => {
      const children = node[childrenKey] || [];

      const nodePath = [...path, node[key]];
      const nodePathName = [...pathName, node.name];

      return [
        ...p,
        {
          ...node,
          [childrenKey]: children.map(cn => cn[key]),
          path: nodePath,
          pathName: nodePathName,
          parentId: path[path.length - 1],
        },
        ...treeToArray(children, key, childrenKey, nodePath, nodePathName),
      ];
    },
    [],
  );
}

export function arrayToTree(arr, key, parentKey) {
  const treeMap = arr.reduce((p, c) => ({ ...p, [c[key]]: cloneDeep(c) }), {});

  const tree = [];

  arr.forEach((node) => {
    const parent = treeMap[node[parentKey]];

    if (parent == null) {
      tree.push(node);
    } else if (parent.children) {
      parent.children.push(node);
    } else {
      parent.children = [node];
    }
  });

  return tree;
}

export function mapTreeNode(treeNodes, mapper, parent, childrenKey = 'children') {
  const ps = [];

  for (let i = 0; i < treeNodes.length; i += 1) {
    const t = treeNodes[i];

    const p = mapper(t, parent);

    const children = t[childrenKey];

    if (children && children.length > 0) {
      p.children = mapTreeNode(children, mapper, p, childrenKey);
    }

    ps.push(p);
  }

  return ps;
}

export function findTreeNode(treeNodes, key, value) {
  for (let i = 0; i < treeNodes.length; i += 1) {
    const node = treeNodes[i];

    if (node[key] === value) {
      return node;
    }

    if (node.children && node.children.length > 0) {
      const child = findTreeNode(node.children, key, value);

      if (child) {
        return child;
      }
    }
  }

  return null;
}
