import React, {Component} from 'react';
import Header from 'components/Header'
import styles from "./style.module.css";
import {Button, Icon} from 'antd'
import './style.css'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import avatar from "../../components/Header/avatar.svg";
import {getUserInfo} from '../../pages/Customer/api'

class CustomerHome extends Component {
  state={userInfo:{}}
  componentDidMount() {
    getUserInfo().then(result=>{
      if(result.code==0){
        this.setState({userInfo:result.data});
      }
    })
  }
  render() {
    console.log('this.props',this.props)
    const history=this.props.history
    const path=this.props.match.path
    return (
      <div className={styles.home}>
        <header className={styles.header}>
          <div className={styles.headerContent+' home-header'}>
            <Header userInfo={this.state.userInfo} isHome={1} type={1}/>
          </div>
        </header>
        <div className={styles.homeContent}>

          <div style={{width:790}}>
            <div className={styles.title}>
              RealSafe 人工智能安全平台
            </div>
            <p className={styles.desc}>人工智能的广泛应用改善着人类的生产生活。然而，当前的人工智能算法主要依赖于数据驱动、缺乏可解释性，因此算法模型中的安全隐患也给全社会带来不容忽视的风险挑战。</p>
            <p
              className={styles.desc}>最典型的人工智能算法模型安全隐患，即是对抗样本攻击问题：攻击者通过对输入样本进行细微的、通常无法被用户感知的修改，导致算法模型产生错误的决策。对抗样本攻击会导致人工智能系统被入侵、执行错误的指令，最终可能造成严重的安全后果。</p>
            <p className={styles.desc}>
              RealSafe 人工智能安全平台正是为解决人工智能模型的安全隐患而生，为算法模型提供安全性测评和攻击防御解决方案。平台将持续从算法模型、数据、基础设施、应用等方面提供全方位的人工智能安全解决方案。
            </p>
            <div className={styles.btnWrap}>
              <Link to={path+'task/list'} className={styles.btn}><Button style={{height:34}} ghost >模型安全测评</Button></Link>
              <Link to={path+'defense'} className={styles.btn}><Button style={{height:34}} ghost >防御解决方案</Button></Link>
              <Link to={path+'experience'} className={styles.btn}>  <Button
                style={{color:'#fff',background:'rgba(255,255,255,0.2)',height:34}}

              >对抗样本攻防体验<Icon type="right-circle"/></Button></Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerHome;
