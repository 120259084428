import instance from '@/network';

export const getImgs = (params) => instance.get('/realsafe/server/exp/explist')


//人脸对比
export const postDiff=(data)=>instance.post('/realsafe/server/exp/model',data)

//生成对抗样本
export const postCounter=(data)=>instance.post('/realsafe/server/exp/attack',data)

//上传图片
export const postImage=(data)=>instance.get('/json/experienceUpload.json',data)

//新增加测试数据
export const postAddData=(data)=>instance.post('/realsafe/server/exp/confirm',data)

//去噪
export const postRemoveNoise=(data)=>instance.post('/realsafe/server/exp/defense',data)

//获取生成对抗样本进度的状态
//export const getCounterStatus=(params)=>instance.get('/json/status.json',{params})
export const getCounterStatus=(params)=>instance.get('/realsafe/server/exp/attack/status',{params})
