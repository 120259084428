import React, {Component} from 'react';
import styles from "../CustomerExperience/style.module.css";
import {Button, Icon, Result} from "antd";
import {Link} from "react-router-dom";

const Error404=(props)=> {
    const { location, history,route } = props;
    return (
      <div className="ui-container-padding">
        <div className="ui-container">
          <div className="ui-bd">
            <div className="ui-bd-ct">
              <Result
                className="error404"
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link to={route.backUrl||'/customer'}>
                    <Button type="primary">Back Home</Button>
                  </Link>}
              />
            </div>

          </div>
        </div>
      </div>
    );
}

export default Error404;
