import React, { Component } from 'react';
import { Button, Modal, Icon, Form, message, Select } from "antd";
import styles from './style.module.css'
import { getCoins, getTasks, getOwnPlans, getEstimates, postBuy, gettUnownPlans, postEstimate } from './api'
import Evaluation from './Evaluation'
import Plan from './Plan'
import './style.css'
import { Link } from 'react-router-dom'
const Item = Form.Item
const Option = Select.Option

@Form.create()
class CustomerDefenseDenoisingWrap extends Component {
  state = {
    ownPlans: [],
    unownPlans: [],
    estimates: [],
    tasks: [],
    buyVisible: false,
    estimateVisible: false,
    coins: 0,
    selectPlanName: '',
    selectTaskIds: [],
    buyError: ''
  }

  componentDidMount() {
    getTasks().then(result => {
      if (result.code == 0) {
        this.setState({ tasks: result.data });
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('出错了')
    })

    getOwnPlans().then(result => {
      if (result.code == 0) {
        this.setState({ ownPlans: result.data });
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('出错了')
    })

    gettUnownPlans().then(result => {
      if (result.code == 0) {
        this.setState({ unownPlans: result.data });
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('出错了')
    })

    getEstimates().then(result => {
      if (result.code == 0) {
        this.setState({ estimates: result.data });
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('出错了')
    })
  }

  handleBuyClick = () => {
    const { ownPlans, unownPlans } = this.state
    console.log('unownPlans', unownPlans, ownPlans)
    if (!unownPlans || !unownPlans.length) {
      Modal.info({ title: '暂无解决方案供购买', content: '您已经购买了全部的解决方案' })
      return
    }
    getCoins().then(result => {
      if (result.code == 0) {
        this.setState({ coins: result.data.currency, buyVisible: true });
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('出错了')
    })

  }
  //选择方案
  handlePlanChange = (value) => {
    const { ownPlans, unownPlans } = this.state
    this.setState({ selectPlanName: value, buyError: '' });
    console.log('value', value)
  }
  //点击购买ok
  handleBuyOkClick = () => {
    const { selectPlanName } = this.state
    postBuy({ program_name: selectPlanName }).then(result => {
      if (result.code == 0) {

        getOwnPlans().then(result => {
          if (result.code == 0) {
            this.setState({ ownPlans: result.data });
          } else {
            message.error(result.msg)
          }
        }).catch(err => {
          message.error('出错了')
        })

        gettUnownPlans().then(result => {
          if (result.code == 0) {
            this.setState({ unownPlans: result.data });
          } else {
            message.error(result.msg)
          }
        }).catch(err => {
          message.error('出错了')
        })

        message.success('购买成功')
        this.setState({ buyVisible: false, selectPlanName: '' });
      } else {
        this.setState({ buyError: result.msg });
        //message.error(result.msg)

      }
    }).catch(err => {
      message.error('出错了')

    })
  }
  //点击购买取消
  handleBuyCancelClick = () => {
    this.setState({ buyVisible: false, selectPlanName: '', buyError: '' });
  }
  //点击开始评估确认
  handleEstimateOkClick = () => {
    const { selectTaskIds, tasks } = this.state
    const names = selectTaskIds.map(id => (tasks.find(task => task.id == id).name))
    console.log('ids', selectTaskIds, names)
    postEstimate({ task_names: names, task_ids: selectTaskIds }).then(result => {
      if (result.code == 0) {
        getEstimates().then(result => {
          if (result.code == 0) {
            this.setState({ estimates: result.data, estimateVisible: false });
          } else {
            message.error(result.msg)
          }
        }).catch(err => {
          message.error('出错了')
        })
      } else {
        message.error(result.msg)

      }
    }).catch(err => {
      message.error('出错了')

    })
  }
  //点击开始评估取消
  handleEstimateCancelClick = () => {
    this.setState({ estimateVisible: false, selectTaskIds: [] });

  }
  //点击评估解决方案
  handleEstimateClick = () => {
    this.setState({ estimateVisible: true });
  }
  //选择评估方案
  handleEstimateChange = (value) => {
    console.log('value', value)
    this.setState({ selectTaskIds: value });
  }
  render() {
    const { ownPlans, unownPlans, buyVisible, estimateVisible, coins, estimates, selectPlanName, tasks, selectTaskIds, buyError } = this.state
    console.log('estimates', estimates)
    const selectPlan = unownPlans.find(item => item.name == selectPlanName)
    console.log('selectTaskIds', selectTaskIds)
    return (
      <div >
        <div className={`${styles.card} `}>
          <h2>对抗样本去噪</h2>
          <p>RealSafe 提供了5种去除对抗噪声的通用防御方案。您可以针对已完成安全测评的任务查看不同防御方案的应用效果并选择购买，购买后防御方案将以 SDK 的形式提供给您使用<Link style={{ whiteSpace: 'nowrap' }} target="_blank" to="/customer/help#防御解决方案">了解更多 >></Link></p>
          <Button onClick={this.handleEstimateClick} style={{ width: 136 }} type="primary">评估解决方案</Button>
        </div>
        <div className={`${styles.card} `}>
          <h3 style={{ marginBottom: 24 }}>已购买</h3>
          {
            ownPlans && ownPlans.length
              ? <div>
                {ownPlans.map(plan => <Plan key={plan.name} {...plan} />)}
              </div>
              : <div className="t-gray">
                暂无购买记录，请先评估解决方案的效果。
              </div>
          }
          <Button type="primary" style={{ marginTop: 24 }}
            onClick={this.handleBuyClick}>{
              !unownPlans || !unownPlans.length
                ? '继续购买'
                : ownPlans && ownPlans.length
                  ? '购买更多'
                  : '立即购买'

            }</Button>
        </div>
        <div className={`${styles.card} `}>
          <h3>防御方案评估记录</h3>
          {
            estimates && estimates.length
              ? <div>
                {estimates.map((estimate, index) => <Evaluation key={index} {...estimate} />)}
              </div>
              : <div>
                <p className="t-opa50">暂无防御方案效果评估记录</p>
              </div>
          }

        </div>
        <Modal
          title="购买"
          okText="购买"
          visible={buyVisible}
          onOk={this.handleBuyOkClick}
          onCancel={this.handleBuyCancelClick}
          okButtonProps={{ disabled: selectPlan ? coins < selectPlan.price : true }}
          destroyOnClose
        >

          <div className={styles.selectWrap}>
            <span className={styles.selectLabel}>请选择防御方案</span>
            <Select className={styles.select} onChange={this.handlePlanChange}>
              {unownPlans.map(plan => <Option key={plan.name} value={plan.name}>{plan.name}</Option>)}
            </Select>


          </div>

          {
            buyError ? <div style={{ marginTop: 18, marginLeft: 111 }} className="t-red">{buyError}</div> : null
          }
          {
            selectPlan
              ? coins >= selectPlan.price
                ? <div className={styles.coinEnough}>消耗 {selectPlan.price}R 币 (剩余 {coins}R 币)</div>
                : <div className={styles.coinUnEnough}>消耗 {selectPlan.price}R 币 (剩余 {coins}R 币，余额不足，请通过和您对接的 RealAI 工作人员联系充值)</div>
              : null

          }


        </Modal>

        <Modal
          title="评估解决方案"
          okText="开始评估"
          maskClosable={false}
          visible={estimateVisible}
          onOk={this.handleEstimateOkClick}
          okButtonProps={{ disabled: selectTaskIds.length <= 0 }}
          onCancel={this.handleEstimateCancelClick}
          destroyOnClose
        >
          <div className={styles.estimateDesc}>关联测评任务后，我们将利用您测评的信息评估每种防御解决方案的效果，以作为您选择的参考</div>
          <div className={styles.selectWrap}>
            <span className={styles.selectLabel}>选择测评任务</span>
            <Select
              showArrow={true}
              maxTagCount={2}
              mode="multiple"
              className={`${styles.select} defense-select`}
              onChange={this.handleEstimateChange}
            >
              {tasks.map(task => <Option disabled={selectTaskIds.length >= 2 ? !selectTaskIds.includes(task.id) : false} key={task.name} value={task.id}>{task.name}</Option>)}
            </Select>
          </div>



        </Modal>
      </div>
    );
  }
}

export default CustomerDefenseDenoisingWrap;
