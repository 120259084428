import axios from 'axios'; //引入axios
import { getCookie, setCookie, clearCookie, clearAllCookie, getQueryVariable } from 'public/utils/com';

let getCheckCode = () => {
  let code = null;
  let { pathname } = window.location;

  let pathNameCheck = '';
  pathNameCheck = pathname && pathname.split('/');
  pathNameCheck = (pathNameCheck && pathNameCheck[1]) || pathname;
  if (pathNameCheck && pathNameCheck.indexOf("customer") >= 0) {
    code = 1;
  } else if (pathNameCheck && pathNameCheck.indexOf("platform") >= 0) {
    code = 2;
  }
  return code;
}

let instance = axios.create({
  headers: {
    'content-type': 'application/json'
  }
})

instance.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  response => {
    //拦截响应，做统一处理
    let code = getCheckCode();
    let pathName = (code == 1) ? 'customer' : 'platform';
    if (response.data.code == 1 || response.data.code == 4) {
      clearAllCookie();
      if ((window.location.pathname).indexOf(`/${pathName}/login`) >= 0) {
        return response.data;
      }
      return window.location.href = `/${pathName}/login`;
    }
    return response.data;
  },
  //接口错误状态处理，也就是说无响应时的处理
  (error) => {
    let err = error.message;

    let code = getCheckCode();

    if (error.response.status == 401) {
      clearAllCookie();
      let pathName = (code == 1) ? 'customer' : 'platform';
      return window.location.href = `/${pathName}/login`;
      throw '请进行登录';
    }
    console.log('error', error);
    return Promise.reject(error.response.status); // 返回接口返回的错误信息
  }
)
export default instance;
