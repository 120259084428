import React, {Component} from 'react';
import {Button, Empty, Table, Modal, Form, Input, Upload, Icon, Tooltip, message} from "antd";
import {toPageNum, toOffset} from '@/offsetAndPagenum'
import {getDatasets, postDataset, postDasetImg, getProgress, postCancel, postDeleteDataset} from './api'
import StatusText from '../common/StatusText/StatusText'
import styles from "./style.module.css";
import NoData from 'components/NoData';

const Item = Form.Item
//数据集状态
const hash = {
  0: '上传中',
  1: '上传失败',
  2: '成功',
  3: '失败',
  4: '应用测评中'
}
const colorHash = {
  0: 'doing',
  1: 'error',
  2: 'success',
  3: 'error',
  4: 'success'
}

@Form.create()
class CustomerDatasetWrap extends Component {
  state = {
    pageSzie: 10,
    datasetData: {},
    visible: false,
    fileList: [],
    imgFileList: [],
    task: {}
  }
  doinges=[]
  columns = [
    {title: '数据集名称', dataIndex: 'name',ellipsis: true,className:"t-bold"},
    {
      title: '状态',
      dataIndex: 'status',
      width:120,
      render: (text, record) => <Tooltip title={record.statusDesc}><span><StatusText
        type={colorHash[text]}>{record.statusMsg}</StatusText></span></Tooltip>
    },
    {title: '创建人', dataIndex: 'creator',ellipsis: true,},
    {title: '图片对', dataIndex: 'validSamples',ellipsis: true,render:(text,record)=>{
        if(record.status==0){
          return '--'
        }
        return text
      }},
    {
      title: '处理进度', dataIndex: 'progress',width:100, render: (text, record) => {
        const status = record.status
        const progressStr = parseInt(text * 100) + '%'
        console.log('progressStr',text,progressStr)
        if (status == 1 || status == 3) {
          return <span className="t-red">{progressStr}</span>
        }
        return <span className="">{progressStr}</span>

      }
    },
    {title: '创建时间', dataIndex: 'createtime',width:190},
    {
      title: '操作', dataIndex: 'operate', render: (text, record) => {
        const status = record.status
        if (status == 1 || status == 2 || status == 3) {
          return <a onClick={this.handleDeleteClick.bind(null, record.id)}>删除</a>
        } else if (status == 0) {
          return <a onClick={this.handleCancelTaskClick.bind(null, record.id)}>取消上传</a>
        }
        return <span>--</span>
      }
    },

  ]

  componentDidMount() {
    this.getDatasetsAndUpdateProgress(1, 10)
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }

  getDatasetsAndUpdateProgress = (pageNum, pageSize) => {
    console.log('pageNum', pageNum, pageSize)
    clearInterval(this.timer)
    getDatasets(toOffset({pageNum, pageSize})).then(result => {
      if (result.code == 0) {
        const datasetData=result.data
        this.setState({datasetData});
        this.doinges = (result.data.data || []).filter(item => item.status == 0).map(item => item.id)
        if (this.doinges.length == 0) {
          this.setState({datasetData});
          return
        }
        this.timer=setInterval(()=>{
          if(this.doinges==0){
            clearInterval(this.timer)
            return
          }
          const datasetData = this.state.datasetData
          const {offset,num}=datasetData
          getDatasets({offset,num}).then(result => {
            if(result.code==0){
              this.doinges = (result.data.data || []).filter(item => item.status == 0).map(item => item.id)
              const datasetData=result.data
              this.setState({datasetData});
            }else{
              message.error(result.msg)
            }
          }).catch(err=>{
            message.error('出错了')
          })

          },3000)
        //先查一次
        /*getProgress({ids: this.doinges}).then(result => {
          const progressdata = result.data
          const doinges = progressdata.filter(item => item.upload != item.total).map(item => item.id)
          //处理状态
          if (this.doinges.length != doinges.length) {
            this.doinges=doinges
            this.getDatasetsAndUpdateProgress(toOffset({pageNum, pageSize}))
            return
          }
          //开启定时器
          this.timer = setInterval(() => {
            getProgress({ids: this.doinges}).then(result => {
              if (result.code == 0) {
                const progressdata = result.data
                const doinges = progressdata.filter(item => item.upload != item.total).map(item => item.id)
                console.log('doinges', this.doinges)
                if (this.doinges.length != doinges.length) {
                  this.doinges=doinges
                  const datasetData = this.state.datasetData
                  this.getDatasetsAndUpdateProgress(
                    toPageNum(datasetData).pageNum, toPageNum(datasetData).pageSize
                  )

                  return
                }
                const progressdataObj = progressdata.reduce((obj, item) => {
                  obj[item.id] = (item.upload / item.total)
                  return obj
                }, {})
                console.log('progressdataObj', progressdataObj)
                const datasetData = this.state.datasetData
                const datasets = datasetData.data.map(item => {
                  if (item.status == 0) {
                    return {...item, progress: progressdataObj[item.id]||0}
                  }
                  return item
                })
                datasetData.data = datasets
                this.setState({datasetData});
              }
            })
          }, 2000)


        })*/

      }
    })
  }
  handleDeleteClick = (id) => {
    console.log('handleDeleteClick', id)
    Modal.confirm({
      title:'确定删除吗',
      content:'数据集删除后不可恢复',
      onOk:()=>{
       return postDeleteDataset({id}).then(result => {
          if (result.code == 0) {
            //message.success(result.msg)
            const datasetData = this.state.datasetData
            this.getDatasetsAndUpdateProgress(
              toPageNum(datasetData).pageNum, toPageNum(datasetData).pageSize
            )

          } else {
            message.error(result.msg)

          }
        }).catch(err => {
          message.error('出错了')
        })

      }
    })

  }
  handleCancelTaskClick = (id) => {
    Modal.confirm({
      title:'确定取消吗',
      content:'取消上传将不保留已上传的数据',
      onOk:()=>{
        return postCancel({id}).then(result => {
          if (result.code == 0) {
            //message.success(result.msg)
            const datasetData = this.state.datasetData
            setTimeout(()=>{
              this.getDatasetsAndUpdateProgress(
                toPageNum(datasetData).pageNum, toPageNum(datasetData).pageSize
              )
            },500)

          } else {
            message.error(result.msg)

          }
        }).catch(err => {
          message.error('出错了')
        })
      },
      okText:'确定',
      cancelText:'取消',
    })


  }
  handlePaginationChange = (pageNum, pageSize) => {
    this.getDatasetsAndUpdateProgress(pageNum, pageSize)
  };
  handleAddDatasetClick = () => {
    this.setState({visible: true});
  }
  handleCancelClick = () => {
    this.setState({visible: false});
  }
  handleOkClick = () => {
    console.log('导出标注.zip', this.props.form.validateFields)
    this.props.form.validateFields((err, valuse) => {
      console.log('values1', valuse, err)
      if (!err) {

        const formData = new FormData();
        formData.append('name', valuse.name)
        //formData.append('num', valuse.img.length)
        formData.append('csv', valuse.csv[0].originFileObj)
        console.log('formData', formData)
        postDataset(formData).then(result => {
          console.log('result',result)
          if (result.code == 0) {
            const datasetData = this.state.datasetData
            this.getDatasetsAndUpdateProgress(toPageNum(datasetData).pageNum, toPageNum(datasetData).pageSize)
            this.setState({visible:false});

            /*   const id = result.data.id
               const img=valuse.img

               this.setState({visible: false});
               console.log('loop--------',datasetData,toPageNum(datasetData))
               console.log('img========', img)
               img.forEach(imgFile => {
                 const formData = new FormData();
                 formData.append('file', imgFile.originFileObj)
                 formData.append('id', id)
                 postDasetImg(formData)
               })*/
          } else {
            message.error(result.msg)
            console.log('result', result)

          }
        }).catch(err => {
          console.log('err',err)
          message.error('出错了')

        })

      }
    })
  }
  handleCsvChange = ({file, fileList}) => {
    if (fileList.length >= 2) {
      fileList.shift()
    }
    this.props.form.setFieldsValue({csv: fileList});


  }
  handelImgChange = (info) => {
    console.log('imgUpload', this.imgUpload)
    const {file, fileList, event} = info
    console.log('file', file, fileList, event)
    const allTypes = ['image/png', 'image/jpg', 'image/jpeg']
    const imgFileList = fileList.filter(file => file.size <= 10 * 1024 * 1024 && allTypes.includes(file.type))
    //this.setState({imgFileList});

    console.log('change', imgFileList)


  }

  normalCsvUpload = e => {
    console.log('e', e)
    let fileList = e
    if (!Array.isArray(e)) {
      fileList = e.fileList
    }
    /* const allTypes = ['image/png', 'image/jpg', 'image/jpeg']
     const imgFileList = fileList.filter(file => file.size <= 10 * 1024 * 1024 && allTypes.includes(file.type))*/
    return fileList
  }
  normalImgUpload = e => {
    console.log('e', e)
    let fileList = e
    if (!Array.isArray(e)) {
      fileList = e.fileList
    }
    const allTypes = ['image/png', 'image/jpg', 'image/jpeg']
    const imgFileList = fileList.filter(file => file.size <= 10 * 1024 * 1024 && allTypes.includes(file.type))
    return imgFileList
  }

  render() {
    const {datasetData, visible, fileList, imgFileList} = this.state
    const Des = <span>数据集将用于生成对抗样本测评您的服务,创建数据集前请查看<a target="_blank" href="/customer/help#准备工作"> 数据准备指南</a></span>
    const {getFieldDecorator, getFieldValue} = this.props.form
    console.log('datasetData', this.state)
    //const img=getFieldValue('img')||[]
    const csv = getFieldValue('csv') || []
    console.log('csv',csv)
    return (
      <div className="ui-container">
           <Button type="primary" onClick={this.handleAddDatasetClick} style={{ marginBottom:21}}>创建数据集</Button>
         <div className="ui-bd-btn">
           <div className="ui-bd-ct">
             <div  style={{padding:20}}>
               <Table
                 rowKey="id"
                 columns={this.columns}
                 dataSource={datasetData.data || []}
                 pagination={{
                   current: toPageNum(datasetData).pageNum,
                   pageSize: toPageNum(datasetData).pageSize,
                   total: datasetData.total,
                   showQuickJumper: true,
                   onChange: this.handlePaginationChange,
                   hideOnSinglePage: true
                 }}
                 locale={{emptyText:()=>null}}
               />
               {
                 !(datasetData.data&&datasetData.data.length)
                   ?<NoData text={Des}/>
                   :null
               }
             </div>
           </div>

         </div>
        <Modal
          title="创建数据集"
          okText="创建"
          visible={visible}
          width={480}
          destroyOnClose
          onOk={this.handleOkClick}
          onCancel={this.handleCancelClick}
          maskClosable={false}
        >
          <div className={styles.uploadDesc}>数据集将用于生成对抗样本测评您的服务，创建数据集前请查看<a target="_blank" href="/customer/help#准备工作">数据准备指南</a></div>

          <Form labelCol={{span: 6}} wrapperCol={{span: 16}} >
            <Item label="数据集名称">
              {getFieldDecorator('name', {
                rules: [{required: true, message: '请输入数据集名称'}, {max: 15, min: 2, message: '名称为2-15个字符之间'}]
              })(<Input autocomplete="off" style={{width:318}} placeholder="请输入名称,2-15字之间"/>)}
            </Item>
            <Item label="上传csv文件" extra={csv&&csv.length?null:<span style={{fontSize:12}}>支持扩展名：.CSV 大小不超过5M </span>}>
              {getFieldDecorator('csv', {
                validateFirst: true,
                valuePropName: 'fileList',
                getValueFromEvent: this.normalCsvUpload,
                rules: [
                  {required: true, message: '请选择文件'},
                  {
                    validator: (rule, value, cb) => {
                      console.log('csssssss ', value)
                      if (value) {
                        const file = value[0]
                        if (file.type != 'text/csv') {
                          cb('请选择csv文件')
                        }
                        if (file.size > 5 * 1024 * 1024) {
                          cb('请选择小于5m的文件')
                        }
                        cb()
                      } else {
                        cb()
                      }
                    }
                  }
                ]
              })(<Upload
                accept=".csv"
                beforeUpload={() => false}
                onChange={this.handleCsvChange}
              >
                <Button><Icon type="upload"/> 上传文件</Button>
              </Upload>)}
            </Item>
            {/* <Item
              label="上传图片"
             extra={img.length ? `已选择${img.length}张图片` : '同时选择多张图片上传 支持扩展名：.PNG .JPEG '}
            >
              {getFieldDecorator('img', {
                getValueFromEvent: this.normalImgUpload,
                valuePropName: 'fileList',
                rules: [
                  {required: true, message: '请选择图片'},

                  {
                    validator: (rule, value, cb) => {
                      console.log('imgFileList', value,img)
                      if (value.fileList.length == 0) {
                        cb('请选择图片')
                      } else {
                        cb()
                      }
                  cb()
                    }
                  }
                ]
              })(<Upload
                ref={el=>this.imgUpload=el}
                accept=".jpg,.jpeg,.png"
                multiple
                showUploadList={false}
                beforeUpload={() => false}
                onChange={this.handelImgChange}
              >
                <Button><Icon type="upload"/> 选择多张图片上传</Button>
              </Upload>)}
            </Item>*/}
          </Form>
        </Modal>
      </div>
    );
  }
}

export default CustomerDatasetWrap;
