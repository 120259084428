import React, {Component} from 'react';
import {Button, Icon, Upload, Spin, Switch, Modal, Form, message, Tooltip, Row, Col} from "antd";
import Slide from './Slide'
import styles from './style.module.css'
import {getImgs, postDiff, postCounter, postImage, postAddData, postRemoveNoise, getCounterStatus} from './api'
import ScanLoading from './ScanLoading'
import ImageContain from './ImageContain'
import MyIcon from "../MyIcon/MyIcon";
import './style.css'
import CloseTooltip from 'components/common/CloseTooltip/CloseTooltip'
import getObjectURL from 'public/utils/getObjectURL'
import imgLoad from 'public/utils/imgLoad'

const getRightChecked = () => typeof window && localStorage.getItem('rightCheck')
const getSwitchChecked = () => typeof window && localStorage.getItem('switchCheck')
const Item = Form.Item

const getScoreKey = (removeStatus, rightCheckIndex) => {
  const hash = {
    '2x01': 'xyscore',
    '2x00': 'xzscore',
    '2x20': 'dxdzscore',
    '2x21': 'dxdyscore',
  }
  return hash[`2x${removeStatus}${rightCheckIndex}`]
}

class CustomerExperienceMain extends Component {
  state = {
    imgs: [],
    diffStatus: 0,//0:未生成，1：生成中，2：已生成
    counterStatus: 0,//0:未生成，1：生成中，2：已生成
    removeStatus: 0,//0:未生成，1：生成中，2：已生成
    checkedId: null,
    rightCheckIndex: 1,//右边缩略图选中位置，0：第一张，1：第二张
    uploadVisible: false,
    image1Url: '',
    image2Url: '',
    upload1loading: false,
    upload2loading: false,
    closeTooltipRightVisible: false,
    closeTooltipSwitchVisible: false,
    errorMsg1: '',
    errorMsg2: ''
  }

  componentDidMount() {
    this.counterDiff = false
    this.rightBgColor.addEventListener('mouseenter', () => {
      console.log('ent')
      this.setState({hover: 1});
    })
    this.rightBgColor.addEventListener('mouseleave', () => {
      this.setState({hover: 0});
    })
    if (!localStorage.getItem('rightCheck')) {
      this.setState({closeTooltipRightVisible: true});
    }
    if (!localStorage.getItem('switchCheck')) {
      this.setState({closeTooltipSwitchVisible: true});
    }
    getImgs().then(result => {
      console.log('result', result, typeof result)
      if (result.code == 0) {
        const imgs = result.data || []
        const firstImg = imgs[0]
        const counterStatus = firstImg.status == 1 ? 1 : 0

        this.setState({imgs, checkedId: firstImg.tid, counterStatus}, () => {
          if (counterStatus == 1) {
            this.loopCounterStatus()
          }
        });
      } else {
        message.error(result.msg)
      }
    }).catch(err => {
      message.error('出错了')

    })
  }

  //轮询对抗样本状态
  loopCounterStatus = () => {
    const tid = this.state.checkedId
    console.log('loop tid', tid)
    this.loopCounterStatusTimer = setInterval((tid) => {
      console.log('tiemr tid', tid)
      getCounterStatus({tid}).then(result => {
        const status = result.data.status
        this.setState({counterStatus: status == 3 ? 0 : status});
        if (status == 2) {
          console.log('status 2')
          clearInterval(this.loopCounterStatusTimer)
          this.setState({rightCheckIndex: 0, diffStatus: 0});
          getImgs().then(result => {
            if (result.code == 0) {
              this.setState({imgs: result.data});
            }
          })
        } else if (status == 3) {
          console.log('status 3')

          clearInterval(this.loopCounterStatusTimer)
          message.error(result.data.msg)
          getImgs().then(result => {
            if (result.code == 0) {
              this.setState({imgs: result.data});
            }
          })
        }
      })
    }, 1000, tid)
  }

  //点击人脸对比
  handleDiffClick = () => {
    this.setState({diffStatus: 1});

    const {imgs, checkedId, removeStatus, rightCheckIndex} = this.state

    //如果对比的是图一和对抗图，则把counterDiff置为true.为了省事，不等数据返回就认为对比过了图一和对抗图。
    if (rightCheckIndex == 0) {
      this.counterDiff = true

    }

    const scoreKey = getScoreKey(removeStatus, rightCheckIndex)
    //imgs已存在，则从imgs找出来，并给一个假loading
    console.log('scoreKey', scoreKey)
    if (imgs.find(img => img.tid === checkedId && img[scoreKey] !== undefined)) {
      this.fakeScoreTimer = setTimeout(() => {
        this.setState({diffStatus: 2});
      }, 1000)
      return
    }
    console.log('找不到则向后端发请求')
    //找不到则向后端发请求
    const splitKey = scoreKey.replace('score', '').match(/(.*x)(.*y|.*z)/).splice(1, 2)//将形如dxdyscore的字符串，转为['dx','dy']
    const promiseWait1Secod = new Promise(resolve => {
      setTimeout(resolve, 1000)
    })
    const promsieDiff = postDiff({tid: checkedId, images: splitKey})
    //loading最少一秒
    Promise.all([promiseWait1Secod, promsieDiff]).then(result => {
      result = result[1]
      this.setState({diffStatus: 0});
      if (result.code == 0) {
        const data=result.data
        if(data.status==3){
          message.error(data.msg)
        }else{
          const newImgs = imgs.map(img => ({
            ...img, ...(img.tid === checkedId ? {
              ...img,
              [scoreKey]: result.data[scoreKey]
            } : {})
          }))
          this.setState({diffStatus: 2, imgs: newImgs});
        }
      } else {
        message.error(result.msg)

      }
    }).catch(err => {
      message.error('出错了')
      this.setState({diffStatus: 0});
    })
  }
  //点击生成对抗样本
  handleConunterClick = () => {
    const {imgs, checkedId} = this.state
    this.setState({counterStatus: 1});
    //已经生成，则生成一个假loading
    if (imgs.find(img => img.tid === checkedId && img.z)) {
      setTimeout(() => {
        this.setState({counterStatus: 2, rightCheckIndex: 0, diffStatus: 0});
      }, 500)
      return
    }
    //没有生成过，则向后端发请求
    postCounter({tid: checkedId}).then(result => {
      if (result.code == 0) {
        const newImgs = imgs.map(img => ({
          ...img, ...(img.tid === checkedId ? {
            ...img,
            status: 1,
          } : {})
        }))
        this.setState({counterStatus: 1, imgs: newImgs});
        this.loopCounterStatus()
      } else {
        message.error(result.msg)

      }
    }).catch(err => {
      message.error('出错了')

    })
  }
  //选原图
  handleyClick = () => {
    this.setState({diffStatus: false});
    if (!localStorage.getItem('rightCheck')) {
      localStorage.setItem('rightCheck', 1)
    }
    const images = ['x', 'y']
    const {rightCheckIndex, checkedId, removeStatus} = this.state
    if (removeStatus == 2) {
      const images = ['x', 'y']
      this.doRemoveNoise({images, tid: checkedId})
    }
    this.setState({rightCheckIndex: 1});
  }
  //选对抗图
  handlezClick = () => {
    this.setState({diffStatus: false});

    const {rightCheckIndex, checkedId, removeStatus} = this.state
    if (removeStatus == 2) {
      const images = ['x', 'z']
      this.doRemoveNoise({images, tid: checkedId})
    }
    this.setState({rightCheckIndex: 0});
  }
  //去噪
  doRemoveNoise = (data) => {
    const {rightCheckIndex}=this.state
    this.setState({removeStatus: 1});
    postRemoveNoise(data).then(result => {
      if (result.code == 0) {
        const data = result.data || {}

        if(data.status==3){
          message.error(data.msg)
          this.setState({removeStatus: 0});
        }else{
          const {imgs, checkedId} = this.state
          const newImgs = imgs.map(img => ({
            ...img, ...(img.tid === checkedId ? {
              ...img,
              dx: data.dx,
              dy: data.dy,
              dz: data.dz
            } : {})
          }))
          const promises = [imgLoad(data.dx), rightCheckIndex == 0 ? imgLoad(data.dz) : imgLoad(data.dy)]

          Promise.all(promises).then(result=>{
            console.log('load result',result)
            this.setState({imgs: newImgs, removeStatus: 2});
          }).catch(err=>{
            console.log('err',err)
            message.error('loadimg error')
          })
        }

      } else {
        message.error(result.msg)
        this.setState({removeStatus: 0});
      }

    })
  }
  //改变switch
  handleSwitchChange = (checked) => {
    if (!localStorage.getItem('switchCheck')) {
      localStorage.setItem('switchCheck', 1)
    }
    this.setState({diffStatus: 0});

    const {imgs, checkedId, removeStatus, rightCheckIndex} = this.state
    const scoreKey = getScoreKey(removeStatus, rightCheckIndex)
    if (checked) {
      const images = ['x', rightCheckIndex == 0 ? 'z' : 'y']
      this.doRemoveNoise({images, tid: checkedId})
    } else {
      this.setState({removeStatus: 0});
    }

  }
  //选择轮播
  handleCarouselClick = (img) => {
    if (img.tid == this.state.checkedIndex) {
      return
    }
    //清除其他slide项的定时任务
    clearInterval(this.fakeScoreTimer)
    clearInterval(this.loopCounterStatusTimer)

    const counterStatus = img.status == 1 ? 1 : 0
    this.setState({
      checkedId: img.tid,
      /*初始化其他*/
      diffStatus: 0,
      counterStatus,
      removeStatus: 0,
      rightCheckIndex: 1,
    }, () => {
      if (counterStatus == 1) {
        this.loopCounterStatus()
      }
    });

  }
  handlePlusClick = () => {
    this.setState({uploadVisible: true});
  }
  //新增数据
  handleUploadOkClick = () => {
    const {errorMsg1, errorMsg2} = this.state
    if(errorMsg1||errorMsg2){
      return
    }
    if (!this.xId) {
      this.setState({errorMsg1: '请上传图片'});
      return
    }
    if (!this.yId) {
      this.setState({errorMsg2: '请上传图片'});
      return
    }
    postAddData({ids: [this.xId, this.yId]}).then(result => {
      this.setState({uploadVisible: false});
      if (result.code == 0) {
        this.setState({
          checkedId: result.data.tid,
          image1Url: '',
          image2Url: '',
          diffStatus: 0,
          counterStatus: 0,
          removeStatus: 0,
          rightCheckIndex: 1,
          errorMsg1: '',
          errorMsg2: ''
        });
        //清除定时任务
        clearInterval(this.loopCounterStatusTimer)

        getImgs().then(result => {
          console.log('result', result, typeof result)
          if (result.code == 0) {
            const imgs = result.data || []
            this.setState({imgs});
          }
        })
      } else if (result.code == 3) {
        Modal.info({
          title: "体验机会已用尽",
          content: '单用户每日最多可上传3组数据体验，请明天再试',
          okText: '确定',
          icon: <Icon style={{color: '#EC8D42'}} type="exclamation-circle"/>
        })
      } else {
        message.error(result.msg)
      }
    })

  }
  handleUploadCancel = () => {
    this.setState({uploadVisible: false, image1Url: '', image2Url: '', errorMsg1: '',errorMsg2:''});

  }
  //第一个上传区域change
  handleUpload1Change = (info) => {
    console.log('info',info)

    if (info.file.status === 'uploading') {
      this.setState({upload1loading: true, errorMsg1: ''});
      return;
    }

    if (info.file.status === 'done') {
      const responese = info.file.response
      console.log('responese', responese, info)
      this.setState({upload1loading: false,});
      if (responese.code == 0) {
        const data = responese.data
        this.xId = data.id
      } else {
        //message.error(responese.msg)
        this.setState({errorMsg1: responese.msg});
      }
      this.setState({[`image1Url`]: getObjectURL(info.file.originFileObj)});

    }

    if(info.file.status=='error'){
      const error = info.file.error
      this.setState({errorMsg1:'error'});
    }
  }
  //第二个上传区域change
  handleUpload2Change = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({upload2loading: true,errorMsg2:''});
      return;
    }
    if (info.file.status === 'done') {
      const responese = info.file.response
      this.setState({upload2loading: false,});
      if (responese.code == 0) {
        const data = responese.data
        this.yId = data.id

      } else {
        //message.error(responese.msg)
        this.setState({errorMsg2: responese.msg});

      }
      this.setState({[`image2Url`]: getObjectURL(info.file.originFileObj)});

    }
    if(info.file.status=='error'){
      const error = info.file.error
      this.setState({errorMsg2:'error'});
    }
  }
  beforeUpload = (key, file) => {

    const {size, type} = file
    if (size > 5 * 1024 * 1024) {
      this.setState({[`errorMsg${key}`]: '文件过大，请更换'});
      return false
    }
    const allTypes = ['image/png', 'image/jpg', 'image/jpeg']
    if (!allTypes.includes(type)) {
      this.setState({[`errorMsg${key}`]: '请上传PNG、JPG、JPEG格式的文件'});
      return false
    }
  }
  handleTooltipCloseClick = (name) => {
    const hash = {
      'switchCheck': 'closeTooltipSwitchVisible',
      'rightCheck': 'closeTooltipRightVisible'
    }
    localStorage.setItem(name, 1)
    this.setState({[hash[name]]: false});
  }

  render() {
    const {imgs, checkedId, uploadVisible, image1Url, image2Url, upload1loading, upload2loading, diffStatus, counterStatus, removeStatus, rightCheckIndex, closeTooltipRightVisible, closeTooltipSwitchVisible, errorMsg1,errorMsg2} = this.state
    const img = imgs.find(img => img.tid == checkedId) || {}
    const diffDisabled = (diffStatus, counterStatus, removeStatus) => diffStatus == 1 || removeStatus == 1 || counterStatus == 1
    const counterShow = (diffStatus, counterStatus, removeStatus,) => {
      console.log('counterStatus', diffStatus, counterStatus)
      const hash = {
        '200': true,
        '230': true,
      }
      return hash[`${diffStatus}${counterStatus}0`]
    }
    const removeDisabled = (diffStatus, counterStatus, removeStatus) => {
      if (counterStatus == 2) {
        if (diffStatus == 1 || removeStatus == 1) {
          return true
        }
        return false
      }
    }
    const removeShow = (diffStatus, counterStatus, removeStatus) => {
      return counterStatus == 2
    }
    const getDes1 = (diffStatus, counterStatus, removeStatus) => {
      const hash = {
        'xx1': '图一-去噪中',
        'xx2': '图一-去噪后',
        'xx0': '图片一'
      }
      return hash[`xx${removeStatus}`]
    }
    const getDes2 = (diffStatus, counterStatus, removeStatus, rightCheckIndex) => {
      const hash = {
        'x200': '对抗样本（根据图二生成）',
        'x201': '图片二',
        'x210': '对抗样本-去噪中',
        'x211': '图二-去噪中',
        'x220': '对抗样本-去噪后',
        'x221': '图二-去噪后',
      }
      return hash[`x2${removeStatus}${rightCheckIndex}`]
    }

    const loadingText2 = removeStatus == 1
      ? '去除对抗噪声...'
      : counterStatus == 1
        ? `对抗样本生成中${img.z ? '' : '（需要10秒左右）'}`
        : ''
    const loadingText1 = removeStatus == 1 ? '去除对抗噪声...' : ''
    const getyKey = (diffStatus, counterStatus, removeStatus, rightCheckIndex) => {
      if (counterStatus != 2) {
        return 'y'
      }
      if (rightCheckIndex == 0) {
        return 'z'
      }
      return 'y'
    }

    const getyremoveKey = (diffStatus, counterStatus, removeStatus, rightCheckIndex) => {
      if (rightCheckIndex == 0) {
        return 'dz'
      }
      return 'dy'
    }
    //removeStatus由变为0或变为2才触发switch
    if (this.switchChecked && removeStatus == 0) {
      this.switchChecked = false
    } else if (!this.switchChecked && removeStatus == 2) {
      this.switchChecked = true
    }
    console.log('removeStatus',removeStatus,loadingText1,loadingText2)
    const score = parseInt((img[getScoreKey(removeStatus, rightCheckIndex)] * 100 || 0))
    return (
      <div className={styles.experienceMain}>

        <div className={styles.experienceMainSlide}>
          <div className={styles.experienceMainSlideWrap}>
            <Slide
              imgs={imgs}
              checkedId={checkedId}
              onCheck={this.handleCarouselClick}
            />
          </div>

          <div onClick={this.handlePlusClick} className={styles.upload}>
            <Icon type="plus"/>
          </div>

        </div>

        <ScanLoading
          style={{marginTop: '24px'}}
          loading={diffStatus == 1}
        >
          <div className={styles.experienceMainView}>
            <Spin wrapperClassName="experience-loading"
                  tip={<div style={{marginTop: '60px'}}>{loadingText1}</div>}
                  spinning={!!loadingText1}
                  indicator={<MyIcon style={{fontSize: '80px', transform: 'translateX(-50%)', marginLeft: 0}}
                                     type="icon-loading" spin></MyIcon>}>
              <div className={`${styles.experienceMainViewImgWrap}`}>
                <ImageContain
                  src={img.x}
                  className={`${removeStatus !=2 ? 'fade-in' : 'fade-out'}`}
                />
                <ImageContain
                  src={img.dx}
                  className={`${removeStatus == 2 ? 'fade-in' : 'fade-out'}`}
                />
              </div>
            </Spin>
            <Spin wrapperClassName="experience-loading"
                  tip={<div style={{marginTop: '60px'}}>{loadingText2}</div>}
                  spinning={!!loadingText2}
                  indicator={<MyIcon style={{fontSize: '80px', transform: 'translateX(-50%)', marginLeft: 0}}
                                     type="icon-loading" spin></MyIcon>}>
              <div
                className={`${styles.experienceMainViewImgWrap} ${styles.experienceMainViewImgWrapR}`}>
                <ImageContain
                  src={img[getyKey(diffStatus, counterStatus, removeStatus, rightCheckIndex)]}
                  className={`${removeStatus !=2 ? 'fade-in' : 'fade-out'}`}
                />
                <ImageContain
                  src={img[getyremoveKey(diffStatus, counterStatus, removeStatus, rightCheckIndex)]}
                  className={`${removeStatus == 2 ? 'fade-in' : 'fade-out'}`}
                />
                {counterShow(diffStatus, counterStatus, removeStatus) ?
                  <div
                    style={{zIndex: 999, top: '387px', textAlign: 'center', width: '100%', position: 'absolute'}}>
                    <Button
                      size="large"
                      ghost
                      onClick={this.handleConunterClick}>生成对抗样本</Button></div> : null}

                {
                  counterStatus == 2
                    ? <div className={styles.counterDiff}>
                      <div onClick={this.handlezClick}
                           className={`${styles.counterDiffItem} ${rightCheckIndex == 0 ? styles.checked : ''}`}><img
                        src={img.z} alt=""/></div>
                      <Tooltip
                        getPopupContainer={triggerNode => triggerNode.parentElement.parentElement}
                        visible={counterStatus == 2 && !getRightChecked()}
                        overlayStyle={{zIndex: 2}}
                        title={<CloseTooltip
                          handleCloseClick={this.handleTooltipCloseClick.bind(null, 'rightCheck')}>可点击切换</CloseTooltip>}
                        placement="bottom"
                      >

                        <div onClick={this.handleyClick}
                             className={`${styles.counterDiffItem} ${rightCheckIndex == 1 ? styles.checked : ''}`}><img
                          src={img.y} alt=""/></div>

                      </Tooltip>
                    </div>
                    : null
                }

              </div>
            </Spin>

            <div style={{display: diffStatus == 2 ? 'block' : 'none'}} className={styles.diffResult}>
              <div className={`${styles.diffResultBgColor} ${styles.diffResultBgColorL}`}></div>
              <div ref={el => this.rightBgColor = el}
                   className={`${styles.diffResultBgColor} ${styles.diffResultBgColorR}`}></div>
              <div className={styles.diffResultBgImg}>
                     <span
                       className={styles.diffResultRate}>{score}<span
                       style={{fontSize: '30px'}}> %</span></span><br/>
                <span className={styles.diffResultDesc}>RealAI 人脸比对结果</span>
              </div>

            </div>


          </div>
        </ScanLoading>
        <div className={styles.experienceMainExtra}>
          <span>{getDes1(diffStatus, counterStatus, removeStatus)}</span>
          <span>{getDes2(diffStatus, counterStatus, removeStatus, rightCheckIndex)}</span>
        </div>
        {
          removeShow(diffStatus, counterStatus, removeStatus)
            ? <div className={`${styles.btn} flex-center`}>
              对抗样本去噪

              <Tooltip
                getPopupContainer={triggerNode => triggerNode.parentElement}

                visible={diffStatus == 2 && rightCheckIndex == 0 && !getSwitchChecked() && this.counterDiff}
                title={<CloseTooltip
                  handleCloseClick={this.handleTooltipCloseClick.bind(null, 'switchCheck')}>可去除攻击者故意添加的噪声</CloseTooltip>}
                placement="right"
              >
                <Switch loading={removeDisabled(diffStatus, counterStatus, removeStatus)}
                        checked={this.switchChecked}
                        style={{marginLeft: 8}}
                        onChange={this.handleSwitchChange}
                        size="small"
                />
              </Tooltip>
            </div>
            : null
        }
        <div className={`${styles.btn} flex-center`}>
          <Button size="large" disabled={diffDisabled(diffStatus, counterStatus, removeStatus)}
                  onClick={this.handleDiffClick}
                  type="primary">人脸对比</Button>


        </div>
        <Modal
          title="新增加测试数据"
          visible={uploadVisible}
          onOk={this.handleUploadOkClick}
          onCancel={this.handleUploadCancel}
          cancelButtonProps={{type: 'primary', ghost: true}}
          destroyOnClose
        >
          <div className={styles.uploadDesc}>
            <p>1. 请上传两张不相同人脸照片，上传后将针对图片二的人脸生成对抗样本</p>
            <p>2. 每次新增测试数据消耗1次体验机会（单用户每日最多可上传3组数据）</p>
            <p style={{marginBottom: 0}}>3. 系统仅保留最近新增加的5条测试数据</p>
          </div>

          <h5 style={{marginTop: 16}}>上传图片</h5>
          <span style={{opacity: 0.5, fontSize: 12}}>请上传文件小于5M的文件，支持PNG、JPG、JPEG格式。</span>
          <div style={{marginTop: '16px'}} className="flex-center upload-box">
            <Row>
              <Col span="12" style={{paddingRight: 12}}>
                <Item>
                  <Upload
                    accept=".PNG,.JPG,.JPEG"
                    className="left-upload"
                    name="image"
                    action="/realsafe/server/exp/upload"
                    listType="picture-card"
                    showUploadList={false}
                    onChange={this.handleUpload1Change}
                    beforeUpload={this.beforeUpload.bind(null, 1)}
                  >
                    {image1Url
                      ? <div className="bg-center"
                             style={{backgroundImage: `url(${image1Url})`, width: 152, height: 152,}}></div>
                      : upload1loading
                        ? <Icon type="loading"/>
                        : <Icon type="plus"/>
                    }
                  </Upload>
                </Item>
                <div style={{width:152}} className="ta-c fl-r">图片一</div>
                <Item
                  validateStatus="error"
                  help={errorMsg1}
                />
              </Col>
              <Col span="12" style={{paddingLeft: 12}}>
                <Item>
                  <Upload
                    name="image"
                    accept=".PNG,.JPG,.JPEG"

                    action="/realsafe/server/exp/upload"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={this.beforeUpload.bind(null, 2)}

                    onChange={this.handleUpload2Change}
                  >
                    {image2Url
                      ? <div className="bg-center"
                             style={{backgroundImage: `url(${image2Url})`, width: 152, height: 152,}}></div>
                      : upload2loading
                        ? <Icon type="loading"/>
                        : <Icon type="plus"/>
                    }
                  </Upload>
                </Item>
                <div style={{width:152}} className="ta-c fl-l">图片二</div>
                <Item
                  validateStatus="error"
                  help={errorMsg2}
                />
              </Col>
            </Row>

          </div>

        </Modal>
      </div>
    );
  }
}

export default CustomerExperienceMain;
