import instance from '@/network';
import {jsonToHump,jsonToUnderline} from '@/jsonTransofrm'

export const getDatasetsCustomer = () => instance.post('/realsafe/server/evaluate/dataset/list',{type:0}).then(result=>jsonToHump(result))

export const getDatasetsSystem = () => instance.post('/realsafe/server/evaluate/dataset/list',{type:1}).then(result=>jsonToHump(result))


//查编辑的回显
export const getTask = (data) => instance.post('/realsafe/server/evaluate/task/edit',data).then(result=>jsonToHump(result))


export const getTasks=(data)=>instance.post('/realsafe/server/evaluate/taskinfo/list',data).then(result=>jsonToHump(result))

export const getCoin = (params) => instance.get('/realsafe/server/defense/currency')


export const postNameRepat=(params)=>instance.get('/json/nameRepat.json').then(result=>jsonToHump(result))

export const postTask=(data)=>instance.post('/realsafe/server/evaluate/task/create',jsonToUnderline({...data,status:0})).then(result=>jsonToHump(result))

export const postFinalTask=(data)=>instance.post('/realsafe/server/evaluate/task/create',jsonToUnderline({...data,status:1})).then(result=>jsonToHump(result))

export const getEvaluatePrice=(data)=>instance.get('/realsafe/server/evaluate/task/price').then(result=>jsonToHump(result))





