import React, { Component } from 'react';
import styles from './style.module.css'
import { Button, Row, Col,message } from 'antd'
import img1 from './防御解决方案01.svg'
import img2 from './防御解决方案02.svg'
import img3 from './防御解决方案03.svg'
import { Link } from 'react-router-dom';
import { getCookie, setCookie, clearCookie, clearAllCookie } from 'public/utils/com';


class CustomerDefense extends Component {
  render() {
    console.log('props',this.props)
    const {userInfo}=this.props
    return (
      <div className={styles.defense}>

        <div className={styles.item}>
          <img src={img1} />
          <h2>对抗样本去噪</h2>
          <div>去除攻击者故意添加的噪声
            提高模型安全性</div>
          {
            userInfo.userType==3
            ?<Link onClick={()=>message.warning('Lite 版本暂不支持此功能，请使用完整版本')} ><Button style={{ width: 140 }} size="large" type="primary">立即使用</Button></Link>
            :<Link to="/customer/defense/denoinsing"><Button style={{ width: 140 }} size="large" type="primary">立即使用</Button></Link>
          }
        </div>
        <div className={styles.line}></div>
        <div className={styles.item}>
          <img src={img2} />
          <h2>对抗样本检测</h2>
          <div>
            检测模型输入是否存在对抗样本
            防止对抗样本进入业务流程
            </div>
          <a href=""><Button size="large" style={{ width: 140 }} disabled type="">敬请期待</Button></a>
        </div>
        <div className={styles.line}></div>

        <div className={styles.item}>
          <img src={img3} />
          <h2>对抗训练</h2>
          <div>
            利用各种攻击算法生成对抗样本
            训练您的算法模型，提升模型的鲁棒性
            </div>
          <a href=""><Button size="large" style={{ width: 140 }} disabled type="">敬请期待</Button></a>
        </div>

      </div>
    );
  }
}

export default CustomerDefense;
