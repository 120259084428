import React from 'react';
import { Icon, Spin, Tooltip, Modal } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import styles from './evaluation.module.css'
import './evaluation.css'
import Bar from "../../views/PlatformCustomerDetails/component/chart/Bar";
import StatusText from 'components/common/StatusText/StatusText'
const antIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;
const statusHash = {
  1: '评估中',
  2: '已完成',
  3: '失败'
}
const colorTypeHahs = {
  /*1:'doing',*/
  2: 'success',
  3: 'error'
}
class Evaluation extends React.Component {
  state = {
    visible: false
  }
  handleDetailClick = () => {
    this.setState({ visible: true });
  }
  handleCandelClick = () => {
    this.setState({ visible: false });

  }
  render() {
    const { visible } = this.state
    const { defense_status: status, task_names, createtime, finish_time, data } = this.props
    console.log('data', data, visible)
    const name = task_names.join('、')
    return (
      <section className={`${styles.evaluationBox}  evaluation-box`}>
        <Spin spinning={status == 1} size="large" indicator={antIcon}>
          <article className={styles.top}>
            <div className="title">
              <h4 className={`${styles.titleName} ellipsis`} title={name}>关联任务：{name}</h4>
              <StatusText style={{ padding: '3px 6px', fontSize: 12, verticalAlign: 'top' }} type={colorTypeHahs[status]}>{statusHash[status]}</StatusText>
            </div>
            {
              status == 2
                ? <div className={styles.time}>评估完成时间：{finish_time}</div>
                : <div className={styles.time}>评估创建时间：{createtime}</div>
            }
          </article>
        </Spin>

        <div className={styles.bottom}>
          {
            status == 2
              ? <div
                className={styles.open}
                onClick={this.handleDetailClick}
              >
                查看详细评估数据
                <Icon className={styles.icon} type="right" />
              </div >
              : <div className={styles.info}>
                {status === 3 && (
                  <span className="t-red text">评估失败，请检查您的服务是否异常</span>
                )}
                {status === 1 && (
                  <div className="t-blue">

                    <span className=" text">
                      正在利用您的测评任务信息评估各解决方案的效果，预计评估消耗
                      {(+task_names.length) * 5}
                      h
                    </span>
                  </div>
                )}
              </div>
          }
        </div>
        <Modal
          visible={visible}
          width={800}
          footer={null}
          onCancel={this.handleCandelClick}
        >
          <h4 className={`title ${styles.evaluationModalItem}`}>关联任务：{name}</h4>
          <div className={`t-gray ${styles.evaluationModalItem}`}>评估创建时间：{createtime}</div>
          <div className={styles.evaluationModalItem}>防御方案不可叠加使用，请根据评估效果选择一种购买</div>
          {/*<div className="method">方法：</div>*/}
          <Bar
            dataSource={data}
          />
        </Modal>
      </section>

    );
  }
}


export default Evaluation;
