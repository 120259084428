import App from './App';
import React from "react";

//const Demo=React.lazy(() => import('views/Demo/Demo'))
import Demo from '../../views/Demo/Demo'
import CustomerHome from '../../views/CustomerHome/CustomerHome'
import CustomerExperience from '../../views/CustomerExperience/CustomerExperience'
import CustomerTaskList from '../../views/CustomerTask/CustomerTaskList'
import CustomerTaskDetail from '../../views/CustomerTask/CustomerTaskDetail'
import CustomerTaskReport from '../../views/CustomerTask/CustomerTaskReport'
import CustomerTaskEdit from '../../views/CustomerTask/CustomerTaskEdit'
import CustomerTaskAdd from '../../views/CustomerTask/CustomerTaskAdd'
import CustomerDataset from '../../views/CustomerDataset/CustomerDataset'
import CustomerDefense from '../../views/CustomerDefense/CustomerDefense'
import CustomerDefenseDenoising from '../../views/CustomerDefense/CustomerDefenseDenoising'
import CustomerPersonal from '../../views/CustomerPersonal'
import CustomerEvaluation from '../../views/CustomerEvaluation'
import CustomerHelp from '../../views/CustomerHelp/CustomerHelp'
import Login from 'views/Login';

import Err404 from 'views/Error404/Error404'
import Err403 from 'components/Error403'
//进行菜单匹配，需要使routes里的path，menuUrl，以及菜单数据里的url三者保持一致。
const routes = [
  {
    path: '/customer/help',
    noside: true,//不包含侧边栏
    component: App,
    routes: [
      {path: '/customer/help', component: CustomerHelp}
    ]
  },
  {
    path: '/customer/',
    exact: true,
    component: CustomerHome
  },
  {
    path: '/customer/login',
    exact: true,
    component: Login
  },

  {
    path: '/customer',
    component: App,
    routes: [
      {path: '/customer/demo/:id?', component: Demo},
      {path: '/customer/experience', component: CustomerExperience,menuUrl:'/customer/experience'},
      {path: '/customer/task/list', component: CustomerTaskList,menuUrl:'/customer/task/list'},
      {path: '/customer/task/detail', component: CustomerTaskDetail,menuUrl:'/customer/task/list'},
      {path: '/customer/task/report', component: CustomerTaskReport,menuUrl:'/customer/task/list'},
      {path: '/customer/task/edit', component: CustomerTaskEdit,menuUrl:'/customer/task/list'},
      {path: '/customer/task/add', component: CustomerTaskAdd,menuUrl:'/customer/task/list'},
      {path: '/customer/dataset', component: CustomerDataset,menuUrl:'/customer/dataset'},
      {path: '/customer/defense', exact: true, component: CustomerDefense,menuUrl:'/customer/defense'},
      {path: '/customer/defense/denoinsing', exact: true, component: CustomerDefenseDenoising,menuUrl:'/customer/defense'},
      {path: '/customer/personal', component: CustomerPersonal},
      {path: '/customer/403', component: Err403},
      {path: '/*', component: Err404},
    ]
  },

];
export default routes;
