/*
* scoure:https://github.com/niklasvh/html2canvas/issues/1380#issuecomment-361611523
* 解决调用html2canvas时，svg没有样式的问题
* 需要在调用html2canvas之前调用
* */
export const setInlineStyles=(targetElem)=>{

  const transformProperties = [
    'fill',
    'color',
    'font-size',
    'stroke',
    'font'
  ];

  let svgElems = Array.from(targetElem.getElementsByTagName("svg"));

  for (let svgElement of svgElems) {
    recurseElementChildren(svgElement);
  }

  function recurseElementChildren(node) {
    if (!node.style)
      return;

    let styles = getComputedStyle(node);

    for (let transformProperty of transformProperties) {
      node.style[transformProperty] = styles[transformProperty];
    }

    for (let child of Array.from(node.childNodes)) {
      recurseElementChildren(child);
    }
  }
}
