import React from 'react'
import styles from './style.module.css'
import Header from 'components/Header'
import styled from "styled-components";
export default class CustomerHeader extends React.Component {
  render() {
    console.log('custoemrheader',this.props)
    return (
      <HCon >
        <Header {...this.props} type={1} />
      </HCon>
    )
  }
}
const HCon = styled.div`
  height:50px;
  box-shadow: 1px 1px 5px 1px #c3c3c3;
  width: 100%;
  display: block;
  background:#fff
`
