import React from 'react';
import styles from './plan.module.css'
import {message} from 'antd'
import MyIcon from 'components/MyIcon/MyIcon'
import { copyText } from 'public/utils/com';


function toThousands(num) {
  var result = '', counter = 0;
  num = `${(num || 0)}`;
  for (var i = num.length - 1; i >= 0; i--) {
    counter++;
    result = num.charAt(i) + result;
    if (!(counter % 3) && i != 0) { result = ',' + result; }
  }
  console.log('result',result)
  return result;
}
const Plan = (props) => {
  const {name,price,url,token}=props
  const priceStr=toThousands(price)
  const handleTokenClick=(id)=>{
    copyText(`copyInput${id}`, (data) => {
      const { type, message:msg } = data;
      console.log('msg',msg,data)
      message[type](msg);
    })
  }
  return (
    <div className={styles.card}>
      <h3 className={styles.title}>{name}</h3>
      <div className={styles.price}>
        <span className={styles.priceIcon}><MyIcon type="icon-r"/></span>
        <span className={styles.priceNum}>{toThousands(price)}</span>
        <span className={styles.year}> / 年</span>
      </div>
      <div className={`${styles.operate} flex-center`}>
        <div className={styles.sdk}><a href={url} >SDK 下载</a></div>
        <div className={styles.token} >
          <a onClick={handleTokenClick.bind(null,name)}>复制Token</a>
          <input readOnly id={`copyInput${name}`} className={styles.hidden} value={token}/>
        </div>
      </div>
    </div>
  );
};

export default Plan;
