import React from 'react';
import { Result, Button } from 'antd';
import './index.css';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

function Err403(props) {
  const { location, history } = props;

  return (
    <Result
      className="error403"
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link to={'/customer'}>
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  )
}

export default Err403;
