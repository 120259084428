import instance from '@/network';
import {jsonToHump,jsonToUnderline} from '@/jsonTransofrm'

export const getDatasets = (data) => instance.post('/realsafe/server/dataset/info',data).then(result=>jsonToHump(result))

export const postDataset=(data)=>instance.post('/realsafe/server/dataset/csv/upload',data).then(result=>jsonToHump(result))

export const postDasetImg=(data)=>instance.post('/realsafe/server/dataset/image/upload',data)

export const getProgress=(data)=>instance.post('/realsafe/server/dataset/progress',data)


export const postCancel=(data)=>instance.post('/realsafe/server/dataset/upload/cancel',data)

export const postDeleteDataset=data=>instance.post('/realsafe/server/dataset/delete',data)


