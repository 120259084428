import React from 'react';
import { Empty, Table } from 'antd';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import detault from 'public/img/default.png';

function NoData(props) {
  const { text } = props;
  return (
    <NoDataTo>
      <Back>
        <img src={detault} />
      </Back>
      <Text>{text ? text : "暂无数据"}</Text>
    </NoDataTo>
  )
}

NoData.propTypes = {
  text: PropTypes.string,
};

NoData.defaultProps = {
  text: null,
};
export default NoData;

const NoDataTo = styled.article`
  padding:60px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Back = styled.section`
  width:395px;
  height:196px;
  margin:20px auto;
  img{
    width:100%;
    height:100%;
  }
`;
const Text = styled.section`
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(33,42,58,0.40);
  letter-spacing: 0;
  text-align: center;
  line-height: 18px;
`;
