import React, {Component} from 'react';
import {Breadcrumb,Button} from "antd";
import CustomerDatasetWrap from "components/CustomerDataset/CustomerDatasetWrap";

class CustomerDataset extends Component {
  render() {
    return (
      <div className="ui-container-padding">


              <CustomerDatasetWrap></CustomerDatasetWrap>


      </div>

    );
  }
}

export default CustomerDataset;
