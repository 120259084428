import React from 'react';
import { Empty, Table, Button, message, Breadcrumb } from 'antd';
import * as PropTypes from 'prop-types';
import Card from 'components/Card';
import styled, { css } from 'styled-components';
import { taskDetails } from 'public/api';
import { checkMenu } from 'components/PlatformSider/com';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { formatCurrency, getQueryVariable } from 'public/utils/com';
import './index.css';

const attack_directionMap = {
  "1": "将相似人脸攻击为不相似",
  "0": "将不相似人脸攻击为相似",
}

const requestMap = {
  "0": "API",
  "1": "SDK"
}

const methodsMap = {
  "0": '查询攻击测评',
  "1": '迁移攻击测评',
  "2": '高级迁移攻击测评'
}


function TaskDetails(props) {
  const { location, onChange, history, userInfo = {}
  } = props;
  const { state, pathname } = location;

  const [dataInfo, setDataInfo] = React.useState({
    apply_scene: {
      method: '',
      multi_call: '',
      attack_direction: '',
      return_score: '',
    },
    args: {},
    dataset: {},
  });

  React.useEffect(() => {
    let task_id = getQueryVariable("task_id") || '';
    if (!task_id) return message.error("task_id 丢失");

    task_id = task_id || (state && state.task_id) || '';

    taskDetails({
      id: task_id
    }).then(result => {
      if (result.code == 0) {
        let { task_info } = result.data || {};
        let str = {};
        if (task_info) str = JSON.parse(task_info);

        setDataInfo({
          ...dataInfo,
          ...result.data,
          ...str,
        });
      } else {
        message.error(result.msg);
      }
    })
  }, []);

  let background = "#25B5FF";
  if (['接入服务中', '排队中', '测评中'].indexOf(dataInfo.task_status) >= 0) {
    background = "#C9D4E6";
  } else if (['测评失败'].indexOf(dataInfo.task_status) >= 0) {
    background = "#F45343";
  } else if (['已完成', '测评完成'].indexOf(dataInfo.task_status) >= 0) {
    background = "#0ECB8A";
  }
  console.log('dataInfo.evaluating_method', dataInfo)
  const isGuest = userInfo.userType == 3
  return (
    <article>
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item><a href="/customer/task/list">测评任务管理</a></Breadcrumb.Item>
        <Breadcrumb.Item>
          <span style={{ cursor: 'default' }}>任务详情</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card style={{ padding: '3% 5% 6%', margin: '18px', height: 'calc(100vh - 100px)', overflow: 'auto' }}>
        <article style={{ minWidth: '1148px' }}>
          <Header>
            <h2>
              {dataInfo.task_name}
              <span style={{
                fontFamily: 'PingFangSC-Semibold',
                background: background,
                borderRadius: "15px",
                fontSize: "12px",
                color: "#ffffff",
                padding: "3px 12px 2px",
              }}
              >
                {dataInfo.task_status}
              </span>
            </h2>
            <div className="content">{dataInfo.description}</div>
          </Header>

          <TaskDetailsTo>
            <div className="title">待测场景信息</div>
            <article>
              <section className="flex">
                <div>
                  <strong>应用场景</strong>
                  {
                    dataInfo.apply_scene.method ? (<span>{dataInfo.apply_scene.method}</span>) : (<span className="notYet">暂未填写</span>)
                  }
                </div>
                <div>
                  {
                    isGuest
                      ? <div >
                        <strong>是否返回分数信息（如置信度）</strong>
                        {
                          (dataInfo.apply_scene.return_score !== '' && dataInfo.apply_scene.return_score !== null) ? (
                            <span>{`${dataInfo.apply_scene.return_score ? '是' : '否'}`}</span>
                          ) : (<span className="notYet">暂未填写</span>)
                        }
                      </div>
                      : <div >
                        <strong>是否能限制第三方多次重复调用</strong>
                        {
                          (dataInfo.apply_scene.multi_call !== '' && dataInfo.apply_scene.multi_call !== null) ? (
                            <span>{`${dataInfo.apply_scene.multi_call ? '是' : '否'}`}</span>
                          ) : (<span className="notYet">暂未填写</span>)
                        }
                      </div>
                  }

                </div>
              </section>
              <section className="flex">
                <div>
                  <strong>攻击方向</strong>
                  <span>
                    {
                      dataInfo.apply_scene.attack_direction ? dataInfo.apply_scene.attack_direction : (<span className="notYet">暂未填写</span>)
                    }
                  </span>
                </div>
                <div style={{ display: isGuest ? 'none' : 'block' }}>
                  <strong>是否返回分数信息（如置信度）</strong>
                  {
                    (dataInfo.apply_scene.return_score !== '' && dataInfo.apply_scene.return_score !== null) ? (
                      <span>{`${dataInfo.apply_scene.return_score ? '是' : '否'}`}</span>
                    ) : (<span className="notYet">暂未填写</span>)
                  }
                </div>
              </section>
            </article>
            <div className="title">测试方案</div>
            <article>
              <section className="flex">
                <div>
                  <strong>测试方法</strong>
                  {
                    dataInfo.evaluating_method ? dataInfo.evaluating_method : (<span className="notYet">暂未填写</span>)
                  }
                </div>
                <div style={{ display: isGuest ? 'none' : 'block' }}>
                  <strong style={{ minWidth: '83px' }}>扰动大小</strong>
                  {
                    dataInfo.args.magnitude ? (<span>{dataInfo.args.magnitude}</span>) : (<span className="notYet">暂未填写</span>)
                  }
                </div>
              </section>
              <section style={{ display: isGuest ? 'none' : 'block' }} className="flex">
                <div >
                  <strong style={{ minWidth: '60px' }}>步长</strong>
                  {
                    dataInfo.args.alpha ? (<span style={{ minWidth: '60px' }}>{dataInfo.args.alpha}</span>) : (<span style={{ minWidth: '60px' }} className="notYet">暂未填写</span>)
                  }
                </div>
                {
                  (dataInfo.evaluating_method || '').includes('查询') && (
                    <div>
                      <strong style={{ minWidth: '80px' }}>最大查询次数</strong>
                      {
                        dataInfo.args.iteration ? (<span style={{ minWidth: '110px' }}>{dataInfo.args.iteration}</span>) : (<span style={{ minWidth: '110px' }} className="notYet">暂未填写</span>)
                      }
                    </div>
                  )
                }
              </section>
            </article>
            <div className="title">接入信息</div>
            <article>
              <section className="flex">
                <div>
                  <strong style={{ minWidth: '62px' }}>接入方式</strong>
                  {
                    (`${dataInfo.request}`) != 'undefined' && (`${dataInfo.request}`) != '' && (`${dataInfo.request}`) != 'null' ? (<span>{requestMap[dataInfo.request]}</span>) : (<span className="notYet">暂未填写</span>)
                  }
                </div>
                {
                  (dataInfo.apply_scene.return_score !== '' || dataInfo.apply_scene.return_score !== null) && dataInfo.apply_scene.return_score
                    ? (<div>
                      <strong style={{ minWidth: '32px' }}>阈值</strong>
                      {
                        dataInfo.threshold ? (<span>{dataInfo.threshold_type == 1 ? '大于等于' : '大于'}{(dataInfo.threshold) + '%'}</span>) : (<span className="notYet">暂未填写</span>)
                      }
                    </div>)
                    : null
                }
              </section>
              <section className="flex">
                {
                  dataInfo.api_doc ? (
                    <div>
                      <strong>API 文档</strong>
                      {/* <a href={dataInfo.api_doc}>
                      <IconPDF src="/img/icon/PDF.svg" />
                      测试人脸对比说明文档
                    </a> */}
                      {dataInfo.api_doc}
                    </div>
                  ) : (
                    dataInfo.sdk ? (
                      <div>
                        <strong>SDK文件</strong>
                        {/* <a href={dataInfo.sdk}>
                          <IconPDF src="/img/icon/SDK.svg" />
                          测试人脸对比说明文档
                        </a> */}
                        {dataInfo.sdk}
                      </div>
                    ) : (
                      (!dataInfo.api_doc && !dataInfo.sdk) && (
                        <div>
                          <strong style={{ minWidth: '62px' }}>文件</strong>
                          <span>--</span>
                        </div>
                      )
                    )
                  )
                }
                {
                  dataInfo.request == '0' && (
                    <div>
                      <strong style={{ minWidth: '32px' }}>QPS</strong>
                      {
                        dataInfo.qps ? (<span>{dataInfo.qps}</span>) : (<span className="notYet">暂未填写</span>)
                      }
                    </div>
                  )
                }
              </section>
            </article>
            <div className="title">数据集信息</div>
            <article>
              <section className="flex">
                <div>
                  <strong>数据集名称</strong>
                  {
                    dataInfo.dataset.name ? (<span>{dataInfo.dataset.name}</span>) : (<span className="notYet">暂未填写</span>)
                  }
                </div>
              </section>
            </article>

            <LinkTo style={{ border: 'none' }}>
              <Button onClick={() => {
                history.goBack();
              }}
                      style={{ marginRight: '20px' }}
              >返回</Button>
              {
                ['已完成', '测评完成'].indexOf(dataInfo.task_status) >= 0 ?
                  (
                    <Link to={`/customer/task/report?task_id=${dataInfo.task_id}&task_title=${dataInfo.task_name}&source=details`}
                          onClick={() => {
                            let url = encodeURIComponent(JSON.stringify({
                              "/customer/task/detail": `task_id=${dataInfo.task_id}&task_title=${dataInfo.task_name}&source=details`
                            }))
                            sessionStorage.CustomerBreadcrumbTo = url;
                          }}>
                      <Button type="primary" style={{ minWidth: '140px' }}>查看测评报告</Button>
                    </Link>
                  )
                  :
                  ['待提交', '测评失败'].indexOf(dataInfo.task_status) >= 0 && (
                    <Link to={`/customer/task/edit?id=${dataInfo.task_id}`}>
                      <Button type="primary">
                        编辑
                      </Button>
                    </Link>
                  )
              }
            </LinkTo>
          </TaskDetailsTo>
        </article>
      </Card>
    </article>
  )
}

TaskDetails.propTypes = {
  onChange: PropTypes.func,
};

TaskDetails.defaultProps = {
  onChange: null,
};
export default TaskDetails;

const TaskDetailsTo = styled.div`
  .title{
    opacity: 0.9;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #212A3A;
    line-height: 40px;
    margin-bottom: 8px;
  }
  article{
    border-bottom:1px solid rgba(201,212,230,0.50);
    padding-bottom:20px;
    margin-bottom: 20px;
    .flex{
      display:flex;
      display:-webkit-flex;
      margin-bottom: 10px;
      div{
        width:50%;
        display:flex;
        display:-webkit-flex;
        strong{
          opacity: 0.9;
          font-family: PingFangSC-Semibold;
          font-size: 14px;
          color: #212A3A;
          margin-right:10px;
          min-width: 60px;
          display: inline-block;
        }
        span{

        }
      }
    }
  }
  .notYet{
    font-size: 14px;
    color: #999999;
    position: relative;
    top: -1px;
  }
  
`

const Header = styled.div`
  h2{
    font-size: 30px;
    position:relative;
  }
  span{
    position: absolute;
    top: 12px;
    color: #ffffff;
    background: #0ECB8A;
    border-radius: 9px;
    font-size: 12px;
    padding: 1px 6px;
    margin-left: 10px;
  }
  .content{
    opacity: 0.9;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #212A3A;
    line-height: 21px;
    border-bottom:1px solid rgba(201,212,230,0.50);
    padding-bottom:20px;
    margin-bottom: 20px;
  }
`;

const LinkTo = styled.article`
  text-align: center;
  display: block;
  margin: 30px 0;
`;

const IconPDF = styled.img`
  position: relative;
  margin-right: 4px;
  top: -2px;
  font-size: 16px;
  display: inile-block;
  width: 16px;
  height: 16px;
`;
