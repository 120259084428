import React, {Component} from 'react';
import scanBack from './scan-back.png'
import scanLine from './scan-line.png'
import './scan-loading.css'
class ScanLoading extends Component {

  render() {
    const {style,loading}=this.props
    return (
      <div style={style} className="scan-loading ">
        <div style={{display:loading?'block':'none',height: '100%', width: '100%', position: 'absolute', zIndex: 3}}>
          <img className="scan-loading-back swiper-animate-back" src={scanBack} alt=""/>
          <img className="scan-loading-front swiper-animate-front" src={scanLine} alt=""/>
          <div className="scan-mask"></div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default ScanLoading;
