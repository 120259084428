import React, {Component} from 'react';
import styles from "./style.module.css";
import {Icon} from "antd";
import SlideItem from './SlideItem'

class Slide extends Component {
  state={
    checkedIndex:0,
    showIimg:'right'
  }
  handleLeftClick=()=>{
    console.log('this.slide',this.slide)
    this.slide.scrollLeft=0
    this.setState({showIimg:'right'});
  }
  handleRightClick=()=>{
    this.slide.scrollLeft=800
    this.setState({showIimg:'left'});

  }
  handleItemClick=(index,img)=>{

    this.props.onCheck(img)
  }
  render() {
    const {imgs,checkedId}=this.props
    const {showIimg}=this.state
    return (
      <div className={styles.slideWrap} >
       <div ref={slide=>this.slide=slide}  className={styles.slide}>
         {imgs.map((img,index) => <div  className={`${styles.slideItemWrap} `} key={img.tid} onClick={this.handleItemClick.bind(null,index,img)}>
           <SlideItem
             img1={img.x}
             img2={img.y}
             checked={checkedId==img.tid}
           />
         </div>)}
       </div>
        {showIimg=='left'&&imgs.length>6?<span  onClick={this.handleLeftClick} className={styles.arrowLeft}><Icon type="left"/></span>:null}
        {showIimg=='right'&&imgs.length>6?<span onClick={this.handleRightClick} className={styles.arrowRight}><Icon type="right"/></span>:null}
      </div>
    );
  }
}

export default Slide;
