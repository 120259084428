import React from 'react';
import {Icon} from "antd";

/**
 *
 * @param props props.visible,props.handleCloseClick,props.children
 * @returns {*}
 * @constructor
 */
const CloseTooltip=(props)=>{
  return <div style={{display:'flex'}}>
    <div style={{borderRight: '1px solid #fff',flex:1,wordBreak: 'break-word',paddingRight:5}}> {props.children}</div>
    <div className="flex-center" onClick={props.handleCloseClick} style={{width:20}}>
      <Icon style={{cursor:'pointer',padding:4,paddingRight:1}} type="close"></Icon>
    </div>
  </div>
}
export default CloseTooltip;
