import React, { Component } from 'react';
import { Icon, Breadcrumb, Button, Modal } from "antd";
import { getCookie, setCookie, clearCookie } from 'public/utils/com';
import CustomerTaskListWrap from '../../components/CustomerTask/CustomerTaskListWrap'
import MyIcon from "../CustomerExperience/CustomerExperience";
import { getDatasetsCustomer } from "./api";

class CustomerTaskList extends Component {
  state={
    visible:false
  }
  componentDidMount() {
    getDatasetsCustomer().then(result => {
      if (result.code == 0) {
        this.datasets = result.data
      }
    })
  }
  handleAddTaskClick = () => {
    if (this.datasets.length) {
      location.href = '/customer/task/add'
      return
    }
    this.setState({visible:true});
   /* Modal.confirm({
      title: '继续创建任务吗',
      content: '',
      okText: '创建数据集',
      cancelText: '继续创建任务',
      okButtonProps: { style: { width: 106 } },
      cancelButtonProps: { style: { width: 116 } },
      onCancel: () => location.href = '/customer/task/add',
      onOk: () => location.href = '/customer/dataset',
      icon: <Icon type="exclamation-circle" />
    });*/
  }
  render() {
    console.log('CloseTooltip')
    return (
      <div className="ui-container-padding">
        <div className="ui-container">
          <Button type="primary" onClick={this.handleAddTaskClick} style={{ marginBottom: 21, width: 116 }}>新建测评任务</Button>
          <div className="ui-bd-btn">
            <div className="ui-bd-ct">
              <CustomerTaskListWrap></CustomerTaskListWrap>

            </div>
          </div>
        </div>
        <Modal
          width={480}
          maskClosable={false}
          title="创建任务"
          onCancel={()=>this.setState({visible:false})}
          visible={this.state.visible}
          footer={[
            <Button onClick={() => location.href = '/customer/task/add'}>继续创建任务</Button>,
            <Button onClick={()=>location.href = '/customer/dataset'} type="primary">创建数据集</Button>,
          ]}
        >
          <div style={{lineHeight:2}}>
            数据集将用于生成对抗样本测评您的服务。使用实际业务数据将有利于提升测评精度，您也可以选择使用系统内置的数据集（主要含白种人图片）测评
          </div>
        </Modal>

      </div>

    );
  }
}

export default CustomerTaskList;
