import React, {Component} from 'react';
import {Tree} from 'antd'
const { TreeNode, DirectoryTree } = Tree;
import styles from './style.module.css'
import './style.css'
import img from './图片_帮助文档.png'
//https://marked.js.org/demo/
//替换csv==>/file/示例.csv
//替换img src==>img
const data=[
  {
    title:'产品简介',
    key:1,
    children:[
      {
        title:'文档导览',
        key:2,

      },
      {
        title:'应用场景',
        key:3,

      },
    ]
  },
  {
    title:'概念',
    key:4,
    children:[
      {title:'对抗样本',key:9},
      {title:'对抗样本攻击方法',key:10},
    ]
  },
  {
    title:'模型安全测评',
    key:5,
    children:[
      {title:'准备工作',key:11},
      {title:'开始测评',key:12},
    ]
  },
  {
    title:'防御解决方案',
    key:6,
    children:[
      {title:'评估防御解决方案效果',key:13},
      {title:'购买防御解决方案',key:14},
    ]
  },
  {
    title:'购买指南',
    key:8,
    children:[
      {title:'如何购买',key:15},
      {title:'产品定价',key:16},
      {title:'发票说明',key:17},
    ]
  }

]
const dataGuest=[
  {
    title:'产品简介',
    key:1,
    children:[
      {
        title:'文档导览',
        key:2,

      },
      {
        title:'应用场景',
        key:3,

      },
    ]
  },
  {
    title:'概念',
    key:4,
    children:[
      {title:'对抗样本',key:9},
      {title:'对抗样本攻击方法',key:10},
    ]
  },
  {
    title:'模型安全测评',
    key:5,
    children:[
      {title:'准备工作',key:11},
      {title:'开始测评',key:12},
    ]
  },
  {
    title:'购买指南',
    key:8,
    children:[
      {title:'如何购买',key:15},
      {title:'发票说明',key:17},
    ]
  }

]
class CustomerHelp extends Component {
  onSelect=(selectKeys,e)=>{
    const title=e.node.props.title
    location.hash=title
    //this.props.history.push('#'+title)
  }
  componentDidMount() {
    if(this.props.location.hash){
      location.href=location.href
    }

  }

  render() {
    console.log('pors',this.props)
    const {userInfo={}}=this.props

    return (
      <div className={styles.helpWrap+' markdown-body'}>
       <div className={styles.help}>
         <div className={styles.helpLeft}>
           <DirectoryTree showIcon={false} switcherIcon={null} treeData={userInfo.userType==3?dataGuest:data} onSelect={this.onSelect}  defaultExpandAll expandAction={false} />
         </div>
         <div className={styles.helpRight}>
           <div className="post">
             {
               userInfo.userType==3
                 ?<div className="post_body">
                   <h1 id="产品简介">产品简介</h1>
                   <h2 id="文档导览">文档导览</h2>
                   <p>您好，欢迎使用 RealSafe 人工智能安全平台。
                     本文档主要介绍了 RealSafe 人工智能安全平台的使用流程，如果您对文档内容或在使用过程中有任何疑问请通过邮箱<a href="mailto:business@realai.ai">business@realai.ai</a> 联系我们。</p>
                   <h2 id="应用场景">应用场景</h2>
                   <p>RealSafe 人工智能安全平台目前支持黑盒人脸比对场景的对抗样本测评及防御。</p>
                   <h1 id="概念">概念</h1>
                   <h2 id="对抗样本">对抗样本</h2>
                   <h3 id="对抗样本简介">对抗样本简介</h3>
                   <p>对抗样本是指能够使机器学习模型出现错误的一类合成样本。攻击者在数据源上增加人眼难以辨别的细微扰动，就可以使模型判断出错。如在图像分类场景中，在熊猫图片上添加少量的对抗噪声，就可以生成使模型误判为长臂猿的对抗样本。</p>
                   <h3 id="对抗样本原理">对抗样本原理</h3>
                   <p><img src={img} alt="" />
                     对抗样本问题本质上是因为当前的机器学习模型主要依赖从训练数据中学习分布，缺乏可解释性，因此可以故意为样本增加微小的噪声操纵其跨过界面，从而使模型判断出错。</p>
                   <h2 id="对抗样本攻击方法">对抗样本攻击方法</h2>
                   <h3 id="迁移攻击">迁移攻击</h3>
                   <p>迁移攻击方法指攻击者事先训练与攻击目标类似的“替代模型”，对替代模型进行白盒攻击生成具有迁移性的对抗样本。再利用这些对抗样本对目标模型进行攻击。</p>
                   <p>迁移攻击方法方法不需要多次查询，攻击成功率稍低，但隐蔽性很强。</p>
                   <h1 id="模型安全测评">模型安全测评</h1>
                   <h2 id="准备工作">准备工作</h2>
                   <p>在测评前，您需要做好以下准备：</p>
                   <h3 id="准备待测评的服务">准备待测评的服务</h3>
                   <p>如您准备只进行迁移攻击测评，可选择选择以下两种形式的服务：</p>
                   <ul>
                     <li>SDK：需要提前准备 SDK 包及说明文档</li>
                     <li>API：需要提前准备 API 说明文档，同时需要注意以下问题：<ul>
                       <li>提交大量对抗样本访问您的服务，如果会影响您的线上服务正常运行，请提供测试环境的API</li>
                       <li>测评时会多次查询 API ，为减少测评时间请支持尽量大的 QPS （QPS 为 5 时预计需要2天测评完成）</li>
                     </ul>
                     </li>
                   </ul>
                   <p>如您准备进行查询攻击测评，则必须准备 SDK 形式的服务（由于查询攻击需要多次查询 API 方式，耗时较长，故此暂不支持 API 形式）。</p>
                   <h3 id="准备测评数据">准备测评数据</h3>
                   <p>测评时需要利用数据集生成对抗样本模拟攻击您的服务。为保证测评结果的准确性，建议您上传真实业务场景中的数据进行测评。若准备数据较为繁琐，您也可以选择系统内置数据集（主要含白种人图片）进行测评。</p>
                   <p>如果您需要创建数据集，可以按照以下步骤进行准备：</p>
                   <h4 id="准备图片">准备图片</h4>
                   <p>待测图片时需要符合以下要求：</p>
                   <ul>
                     <li>格式：图片格式仅支持 JPG、JPEG、PNG</li>
                     <li>文件大小：图片文件大小不超过10M</li>
                     <li>人脸个数要求：每张图片只能含有一个人脸</li>
                     <li>数量：为保证统计有效性，请上传一定量的数据<ul>
                       <li>至少提供1000个不同的人、每人至少含两张不同的图片（为避免因个别图片异常导致上传失败，建议多准备一部分数据，如1200对图片）</li>
                       <li>最多不超过10000张图片</li>
                     </ul>
                     </li>
                   </ul>
                   <h4 id="创建含图片-url-的-csv-文件">创建含图片 URL 的 CSV 文件</h4>
                   <p>图片准备完成后，您可以创建一个 CSV 文件用于关联图片 URL 与人的关系并提供必要的信息：例如您的服务输入要求提交图片时，必须同时提交图片类型，此时可以将每张图片对应的类型填写在 CSV 文件内并通过序号与图片相关联。</p>
                   <p><strong>CSV 文件结构</strong></p>
                   <table>
                     <thead>
                     <tr>
                       <th>序号</th>
                       <th>图片 URL</th>
                       <th>可选参数名称</th>
                     </tr>
                     </thead>
                     <tbody><tr>
                       <td>1</td>
                       <td><a href="https://test1.jpg/">https://test1.jpg/</a></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>1</td>
                       <td><a href="https://test2.jpg/">https://test2.jpg/</a></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>2</td>
                       <td><a href="https://test3.jpg/">https://test3.jpg/</a></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>2</td>
                       <td><a href="https://test4.jpg/">https://test4.jpg/</a></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>...</td>
                       <td>...</td>
                       <td>...</td>
                     </tr>
                     </tbody></table>
                   <p>CSV 文件必须按照上述格式创建，每行对应您要上传的一个图片，且每行至少包含2列：</p>
                   <ul>
                     <li>第一列为连续的整数字，相同的数字代表同一个人</li>
                     <li>第二列为图片的 URL</li>
                     <li>第三列或更多列可以根据您的服务情况添加更多，系统不会强制校验</li>
                   </ul>
                   <p>您可以点击<a href="">下载 CSV 文件示例</a>。</p>
                   <p><strong>创建 CSV 时常见的错误</strong></p>
                   <p>请注意避免创建 CSV 文件过程中可能发生的常见错误，如：</p>
                   <ul>
                     <li>CSV 各行之间出现空行</li>
                     <li>第一列不是数字格式</li>
                     <li>非 CSV 格式的文件</li>
                     <li>CSV 文件内的图片 URL 无法访问</li>
                     <li>代表同一个人的数据列在 CSV 中处于不相邻：如第一列数字1和2分别代表两个人。数据从上往下按 1、1、2、2、1排列不符合要求，需要排列成1、1、1、2、2</li>
                   </ul>
                   <h4 id="确认是否已有-r-币">确认是否已有 R 币</h4>
                   <p>测评时需要消耗 R 币，如余额不足，您可以先创建任务，然后联系和您对接的 RealAI 工作人员充值 R 币再提交测评。</p>
                   <h2 id="开始测评">开始测评</h2>
                   <p>目前 RealSafe 支持多种测评方法。您可以按照实际业务场景进行选择。按任务流程指引创建完成测评任务后，我们将会首先接入您的服务，再利用数据集生成对抗样本模拟攻击进行测评。</p>
                   <p>测试完成后，还将提供安全评分和完整的测评报告。</p>
                   <h3 id="评分方法">评分方法</h3>
                   <p>根据您的模型在对抗样本攻击下的表现进行评分，满分为100分，评分越高则模型安全性越高，迁移攻击方法评分时会综合在不同算法、扰动量大小的攻击下模型效果的变化进行评分。</p>
                 <h1 id="购买指南">购买指南</h1>
               <h2 id="如何购买">如何购买</h2>
               <p>RealSafe 平台以 R 币作为结算单位，如果您需要进行模型安全测评或购买防御方案需要先充值 R 币。</p>
               <p>如需充值 R 币，可以联系和您对接的RealAI工作人员或直接通过邮箱 <a href="mailto:business@realai.ai">business@realai.ai</a> 联系我们。</p>
               <h2 id="发票说明">发票说明</h2>
               <p>支付 R 币后，我们可以在30 个工作日内为您开具相应的发票（支持增值税普通发票和增值税专用发票），并快递到您的手中。</p>

                 </div>
                 :<div className="post_body">

                   <h1 id="产品简介">产品简介</h1>
                   <h2 id="文档导览">文档导览</h2>
                   <p>您好，欢迎使用 RealSafe 人工智能安全平台。
                     本文档主要介绍了 RealSafe 人工智能安全平台的使用流程，如果您对文档内容或在使用过程中有任何疑问请通过邮箱<a href="mailto:business@realai.ai">business@realai.ai</a> 联系我们。</p>
                   <h2 id="应用场景">应用场景</h2>
                   <p>RealSafe 人工智能安全平台目前支持黑盒人脸比对场景的对抗样本测评及防御。</p>
                   <h1 id="概念">概念</h1>
                   <h2 id="对抗样本">对抗样本</h2>
                   <h3 id="对抗样本简介">对抗样本简介</h3>
                   <p>对抗样本是指能够使机器学习模型出现错误的一类合成样本。攻击者在数据源上增加人眼难以辨别的细微扰动，就可以使模型判断出错。如在图像分类场景中，在熊猫图片上添加少量的对抗噪声，就可以生成使模型误判为长臂猿的对抗样本。</p>
                   <h3 id="对抗样本原理">对抗样本原理</h3>
                   <p><img src={img} alt="" />
                     对抗样本问题本质上是因为当前的机器学习模型主要依赖从训练数据中学习分布，缺乏可解释性，因此可以故意为样本增加微小的噪声操纵其跨过界面，从而使模型判断出错。</p>
                   <h2 id="对抗样本攻击方法">对抗样本攻击方法</h2>
                   <p>目前生成对抗样本攻击黑盒模型主要有两类方法：查询攻击方法、迁移攻击方法。</p>
                   <h3 id="查询攻击">查询攻击</h3>
                   <p>查询攻击方法指攻击者多次查询（每生成一张图片对应的对抗样本需要查询当前模型一千次左右）服务，并根据输入输出信息不断优化对抗样本。</p>
                   <p>查询攻击方法根据服务是否返回分数信息（如相似度）又分为两类：</p>
                   <ul>
                     <li>基于分数的查询攻击：服务接收图片等信息后，返回分数（如人脸的相似度）会有助于攻击者优化迭代的方向，更容易攻击成功，因此安全风险较高；</li>
                     <li>基于决策的查询攻击：服务接收图片等信息后，仅返回离散的标签（如是否为同一个人），相对难被攻击成功，因此安全风险较低。</li>
                   </ul>
                   <h3 id="迁移攻击">迁移攻击</h3>
                   <p>迁移攻击方法指攻击者事先训练与攻击目标类似的“替代模型”，对替代模型进行白盒攻击生成具有迁移性的对抗样本。再利用这些对抗样本对目标模型进行攻击。</p>
                   <p>迁移攻击方法方法不需要多次查询，攻击成功率稍低，但隐蔽性很强。</p>
                   <h1 id="模型安全测评">模型安全测评</h1>
                   <h2 id="准备工作">准备工作</h2>
                   <p>在测评前，您需要做好以下准备：</p>
                   <h3 id="准备待测评的服务">准备待测评的服务</h3>
                   <p>如您准备只进行迁移攻击测评，可选择选择以下两种形式的服务：</p>
                   <ul>
                     <li>SDK：需要提前准备 SDK 包及说明文档</li>
                     <li>API：需要提前准备 API 说明文档，同时需要注意以下问题：<ul>
                       <li>提交大量对抗样本访问您的服务，如果会影响您的线上服务正常运行，请提供测试环境的API</li>
                       <li>测评时会多次查询 API ，为减少测评时间请支持尽量大的 QPS （QPS 为 5 时预计需要2天测评完成）</li>
                     </ul>
                     </li>
                   </ul>
                   <p>如您准备进行查询攻击测评，则必须准备 SDK 形式的服务（由于查询攻击需要多次查询 API 方式，耗时较长，故此暂不支持 API 形式）。</p>
                   <h3 id="准备测评数据">准备测评数据</h3>
                   <p>测评时需要利用数据集生成对抗样本模拟攻击您的服务。为保证测评结果的准确性，建议您上传真实业务场景中的数据进行测评。若准备数据较为繁琐，您也可以选择系统内置数据集（主要含白种人图片）进行测评。</p>
                   <p>如果您需要创建数据集，可以按照以下步骤进行准备：</p>
                   <h4 id="准备图片">准备图片</h4>
                   <p>待测图片时需要符合以下要求：</p>
                   <ul>
                     <li>格式：图片格式仅支持 JPG、JPEG、PNG</li>
                     <li>文件大小：图片文件大小不超过10M</li>
                     <li>人脸个数要求：每张图片只能含有一个人脸</li>
                     <li>数量：为保证统计有效性，请上传一定量的数据<ul>
                       <li>至少提供1000个不同的人、每人至少含两张不同的图片（为避免因个别图片异常导致上传失败，建议多准备一部分数据，如1200对图片）</li>
                       <li>最多不超过10000张图片</li>
                     </ul>
                     </li>
                   </ul>
                   <h4 id="创建含图片-url-的-csv-文件">创建含图片 URL 的 CSV 文件</h4>
                   <p>图片准备完成后，您可以创建一个 CSV 文件用于关联图片 URL 与人的关系并提供必要的信息：例如您的服务输入要求提交图片时，必须同时提交图片类型，此时可以将每张图片对应的类型填写在 CSV 文件内并通过序号与图片相关联。</p>
                   <p><strong>CSV 文件结构</strong></p>
                   <table>
                     <thead>
                     <tr>
                       <th>序号</th>
                       <th>图片 URL</th>
                       <th>可选参数名称</th>
                     </tr>
                     </thead>
                     <tbody><tr>
                       <td>1</td>
                       <td><aa href="https://test1.jpg/">https://test1.jpg/</aa></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>1</td>
                       <td><aa href="https://test2.jpg/">https://test2.jpg/</aa></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>2</td>
                       <td><aa href="https://test3.jpg/">https://test3.jpg/</aa></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>2</td>
                       <td><aa href="https://test4.jpg/">https://test4.jpg/</aa></td>
                       <td>xx</td>
                     </tr>
                     <tr>
                       <td>...</td>
                       <td>...</td>
                       <td>...</td>
                     </tr>
                     </tbody></table>
                   <p>CSV 文件必须按照上述格式创建，每行对应您要上传的一个图片，且每行至少包含2列：</p>
                   <ul>
                     <li>第一列为连续的整数字，相同的数字代表同一个人</li>
                     <li>第二列为图片的 URL</li>
                     <li>第三列或更多列可以根据您的服务情况添加更多，系统不会强制校验</li>
                   </ul>
                   <p>您可以点击<a href="/file/示例.csv">下载 CSV 文件示例</a>。</p>
                   <p><strong>创建 CSV 时常见的错误</strong></p>
                   <p>请注意避免创建 CSV 文件过程中可能发生的常见错误，如：</p>
                   <ul>
                     <li>CSV 各行之间出现空行</li>
                     <li>第一列不是数字格式</li>
                     <li>非 CSV 格式的文件</li>
                     <li>CSV 文件内的图片 URL 无法访问</li>
                     <li>代表同一个人的数据列在 CSV 中处于不相邻：如第一列数字1和2分别代表两个人。数据从上往下按 1、1、2、2、1排列不符合要求，需要排列成1、1、1、2、2</li>
                   </ul>
                   <h4 id="确认是否已有-r-币">确认是否已有 R 币</h4>
                   <p>测评时需要消耗 R 币，如余额不足，您可以先创建任务，然后联系和您对接的 RealAI 工作人员充值 R 币再提交测评。</p>
                   <h2 id="开始测评">开始测评</h2>
                   <p>目前 RealSafe 支持多种测评方法。您可以按照实际业务场景进行选择。按任务流程指引创建完成测评任务后，我们将会首先接入您的服务，再利用数据集生成对抗样本模拟攻击进行测评。</p>
                   <p>测试完成后，还将提供安全评分和完整的测评报告。</p>
                   <h3 id="评分方法">评分方法</h3>
                   <p>根据您的模型在对抗样本攻击下的表现进行评分，满分为100分，评分越高则模型安全性越高。其中两类测评方法的评分逻辑稍微区别：</p>
                   <ul>
                     <li>查询攻击方法评分时会综合在不同算法、迭代次数、扰动大小的攻击下模型效果的变化进行评分</li>
                     <li>迁移攻击方法评分时会综合在不同算法、扰动大小的攻击下模型效果的变化进行评分</li>
                   </ul>
                   <h1 id="防御解决方案">防御解决方案</h1>
                   <p>RealSafe 提供了5种去除对抗噪声的通用防御方法。使用防御方案后，可实现对输入数据的自动去噪处理，破坏攻击者恶意添加的对抗噪声，从而达到提升模型安全性的目的。</p>
                   <h2 id="评估防御解决方案效果">评估防御解决方案效果</h2>
                   <p>防御方法间没有绝对的好坏，不同的模型的防御效果很可能不同。您可以关联已测评完成的任务评估各种防御方案的效果，选择购买最合适的防御方案。</p>
                   <p>评估时会展示两个维度的指标：</p>
                   <ul>
                     <li>安全性变化：利用生成的对抗样本图片经过防御解决方案处理后的安全评分变化</li>
                     <li>模型效果变化：测试原始样本经过防御方案处理后对于模型正确率（accuracy）的影响</li>
                   </ul>
                   <h2 id="购买防御解决方案">购买防御解决方案</h2>
                   <p>您可以根据评估结果选择购买合适防御解决方案。购买时每种方法解决方案需要支付 R 币。</p>
                   <p>另外使用防御方案还需要注意以下几点：</p>
                   <ul>
                     <li>“去噪”只是破坏了故意添加的对抗噪声，并非将对抗噪声直接清洗掉，因此去噪后的图片可能与原始图片有较大不同</li>
                     <li>各防御方案之间不可叠加使用，您可以根据评估效果选择一种购买</li>
                   </ul>
                   <h2 id="使用防御解决方案">使用防御解决方案</h2>
                   <p>SDK 采用联网授权机制，在有效期过后，SDK 功能将会不可用。</p>
                   <h1 id="购买指南">购买指南</h1>
                   <h2 id="如何购买">如何购买</h2>
                   <p>RealSafe 平台以 R 币作为结算单位，如果您需要进行模型安全测评或购买防御方案需要先充值 R 币。</p>
                   <p>如需充值 R 币，可以联系和您对接的RealAI工作人员或直接通过邮箱 <a href="mailto:business@realai.ai">business@realai.ai</a> 联系我们。</p>
                   <h2 id="产品定价">产品定价</h2>
                   <table>
                     <thead>
                     <tr>
                       <th>收费类型</th>
                       <th>R币数量</th>
                       <th>单位</th>
                     </tr>
                     </thead>
                     <tbody><tr>
                       <td>查询攻击测评</td>
                       <td>1</td>
                       <td>次</td>
                     </tr>
                     <tr>
                       <td>迁移攻击测评</td>
                       <td>2</td>
                       <td>次</td>
                     </tr>
                     <tr>
                       <td>高级迁移攻击测评</td>
                       <td>3</td>
                       <td>次</td>
                     </tr>
                     <tr>
                       <td>防御方案一</td>
                       <td>100</td>
                       <td>年</td>
                     </tr>
                     <tr>
                       <td>防御方案二</td>
                       <td>150</td>
                       <td>年</td>
                     </tr>
                     <tr>
                       <td>防御方案三</td>
                       <td>200</td>
                       <td>年</td>
                     </tr>
                     <tr>
                       <td>防御方案四</td>
                       <td>250</td>
                       <td>年</td>
                     </tr>
                     <tr>
                       <td>防御方案五</td>
                       <td>200</td>
                       <td>年</td>
                     </tr>
                     </tbody></table>
                   <h2 id="发票说明">发票说明</h2>
                   <p>支付 R 币后，我们可以在30 个工作日内为您开具相应的发票（支持增值税普通发票和增值税专用发票），并快递到您的手中。</p>

                 </div>

             }
           </div>
         </div>
       </div>

      </div>
    );
  }
}

export default CustomerHelp;
