import React from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import styles from './style.module.css';
import tou from './img/tou.png';
import { List } from 'antd';
import './index.css'

const dataCompute = (dataSource, selectIndex) => {
  let list = [];
  if (dataSource && dataSource.length <= 0) return list;

  Object.values(dataSource).map((req, index) => {

    let req2 = (req[2] || '');
    list.push({
      ...req,
      ...req2,
      active: (index === selectIndex ? true : false),
    });

    return list;
  })
  return list;
}

function Carousel(props) {
  const { dataSource, configure } = props;

  const [mySwiper, setMySwiper] = React.useState(null);
  const [dataList, setDataList] = React.useState([]);

  const [initialSlide, setInitialSlide] = React.useState(0);

  React.useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      let list = dataCompute(dataSource, (configure.initialSlide || 0));

      setDataList(list);
      configure.onClick && configure.onClick(list[(configure.initialSlide || 0)]);
    }
  }, [dataSource]);

  React.useEffect(() => {
    setMySwiper(new Swiper('.swiper-container', {
      spaceBetween: 20,
      //grabCursor: true,
      height: 60,
      //slidesOffsetBefore: 36,
      //slidesOffsetAfter: 10,
      // 如果需要前进后退按钮

      slidesPerView: "auto",

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      ...configure,
      initialSlide,
    }));
  }, [dataList]);

  return (
    <header className={`swiper-container ${styles.swiperContainer} ${configure.className}`}>
      <div className={`swiper-wrapper ${styles.swiperWrapper}`}>
        {
          (dataList && dataList.length > 0) && dataList.map((req, index) => {
            return (
              <div
                className={`swiper-slide ${req.active ? styles.slideActive : ''}`}
                style={{ maxWidth: '120px' }}
                onClick={() => {
                  let list = dataCompute(dataList, index);
                  setDataList(list);
                  setInitialSlide(index);
                  configure.onClick && configure.onClick(req, req[configure.fieldName || ''], index);
                }}
              >
                {
                  (req[configure.fieldName || ''] instanceof Array) ? req[configure.fieldName || ''].map((result, index) => {
                    return (index === 0 || index === 1) && (
                      <section key={index} style={{
                        width: '50%', maxHeight: '60px', maxWidth: '60px', minHeight: '60px', minWidth: '60px', display: 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                      }}>
                        <img
                          src={result.path}
                          style={{ 'margin': "0 auto", maxWidth: '60px', maxHeight: '60px', display: 'block' }}
                        />
                      </section>
                    );
                  }) : (
                      <section key={index}>
                        <img
                          src={req}
                          style={{ width: '100%' }}
                        />
                      </section>
                    )
                }
              </div>
            )
          })
        }
      </div>
      {
        (dataList && dataList.length > 4) && (<div className={`swiper-button-prev ${styles.swiperPrev}`}></div>)
      }
      {
        (dataList && dataList.length > 4) && (<div className={`swiper-button-next ${styles.swiperNext}`}></div>)
      }
    </header>
  )
}

Carousel.propTypes = {
  dataSource: PropTypes.array,
  configure: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    fieldName: PropTypes.string,
  }),
};

Carousel.defaultProps = {
  dataSource: null,
  configure: null,
  className: null,
  onClick: null,
};
export default Carousel;
