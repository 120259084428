import instance from '@/network';

export const getPlatformMenu = (data) => instance.get('/json/menu.json')

//客户列表
export const getRooms = (data) => instance.get('/json/room.json');

//客户详情
export const getCustomerDetails = (data) => instance.get('/json/customerDetails.json');

//任务详情
export const getTaskDetails = (data) => instance.get('/json/taskDetails.json', data);

//查看测评报告
export const getPresentation = (data) => instance.get('/json/presentation.json', data);

//日志
export const getLogs = (data) => instance.get('/json/logs.json');



/**
 * 用户登录接口
 * @param {*} data
 */
export const login = (data) => instance.post('/realsafe/admin/login', data);


/**
 * 客户管理列表
 */
export const getCompanySummary = (data) => instance.get('/realsafe/admin/company_summary', {
  params: {
    ...data,
  }
});

/**
 * R币余额
 */
export const getUserCurrency = () => instance.get('/realsafe/admin/user_currency');

/**
 * R币配置
 */
export const getUpdateCurrency = (data) => instance.post('/realsafe/admin/update_currency', data);

/**
 * 客户详情 – 客户信息
 * @param {company_id} data
 */
export const getCompanyInfo = (data) => instance.get('/realsafe/admin/company_info', {
  params: {
    ...data,
  }
});


/**
 * 客户详情 – 客户任务记录
 * @param {company_id} data
 */
export const getCompanyTasks = (data) => instance.get('/realsafe/admin/company_tasks', {
  params: {
    ...data,
  }
});

/**
 * 客户详情 – 任务详情
 * @param {company_id} data
 */
export const getTaskDetail = (data) => instance.get('/realsafe/admin/task_detail', {
  params: {
    ...data,
  }
});

/**
 * 开始处理
 * @param {*} data
 */
export const getStartTask = (data) => instance.get('/realsafe/admin/start_task', {
  params: {
    ...data,
  }
});


/**
 * 开始测评
 * @param {*} data
 */
export const startEvaluate = (data) => instance.get('/realsafe/admin/start_evaluate', {
  params: {
    ...data,
  }
});


/**
 * 关闭任务
 * @param {*} data
 */
export const closeTask = (data) => instance.post('/realsafe/admin/close_task', data);


/**
 * 查看任务报告
 * @param {*} data
 */
export const taskReport = (data) => instance.get('/realsafe/admin/task_report', {
  params: {
    ...data,
  }
});

/**
 * 退出登录
 */
export const exitLogin = () => instance.get('/realsafe/admin/logout');


/**
 * 财务管理
 */
export const userFinanceDetail = (data) => instance.get('/realsafe/admin/user_finance_detail', {
  params: {
    ...data,
  }
});


//-------------------------用户侧-----------------------------------------

/**
 * 用户侧列表
 * @param {*} data
 */
export const getCustomerMenu = (params) => instance.get('/json/customerMenu.json', { params });

/**
 * 获取任务详情
 * @param {*} data
 */
export const taskDetails = (data) => instance.post('/realsafe/server/evaluate/task/detail', data);

/**
 * 获取任务评估报告
 * @param {*} data
 */
export const taskReports = (data) => instance.post('/realsafe/server/evaluate/task/report', data);
