import instance from '@/network';

export const getCoins = (params) => instance.get('/realsafe/server/defense/currency')

export const getTasks= (params) => instance.get('/realsafe/server/defense/task/list')

export const getOwnPlans =(params) => instance.get('/realsafe/server/defense/programs/list')

export const getEstimates =(params) => instance.get('/realsafe/server/defense/record')

export const gettUnownPlans=(data)=>instance.get('/realsafe/server/defense/buylist',data)


export const postEstimate=(data)=>instance.post('/realsafe/server/defense/programs/test',data)

export const postBuy=(data)=>instance.post('/realsafe/server/defense/programs/buy',data)


