import React from 'react';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import styles from './style.module.css';
import tou from './img/tou.png'
import { Button, Tooltip, Tag, Spin, } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import Carousel from '../Carousel';
import { changeConfirmLocale } from 'antd/lib/modal/locale';
import { getCookie, setCookie, clearCookie, clearAllCookie } from 'public/utils/com';
//const antIcon = <LoadingOutlined style={{ fontSize: 45, color: '#ffffff', }} spin />;
import MyIcon from "components/MyIcon/MyIcon";
import './index.css';

function Wheel(props) {
  const { dataSource, onChange } = props;

  const [dataInfo, setDataInfo] = React.useState({
    input: [],
    output: [],
  });
  const [tooltipShopActive, setTooltipShopActive] = React.useState(true);
  const [tooltipVis, setTooltipVis] = React.useState(null);

  const [scanning, setScanning] = React.useState(null);
  const [backFlag, setBackFlag] = React.useState(0);
  const [backFlag1, setBackFlag1] = React.useState(0);
  const [recordNum, setRecordNum] = React.useState(0);

  React.useEffect(() => {
    let stb = JSON.parse(sessionStorage.tagToTab || "true");
    setTooltipVis(stb);
  }, []);

  return (
    <article >
      <Carousel
        dataSource={dataSource}
        configure={{
          //参数配置
          fieldName: 'input',//请求的参数
          className: "CarouselName",
          initialSlide: 0,//默认从0开始

          onClick: (data, item, index) => {
            setDataInfo({ ...data });
            setTooltipShopActive(true);

            if (data.magnitude != recordNum) {
              setBackFlag(1);
              setBackFlag1(1);
            }
            setRecordNum(data.magnitude);
            /* setTimeout(() => {
              setBackFlag(0);
              setBackFlag1(0);
            }, 600); */

            if (index) {
              onChange && onChange({
                input: null,
                output: null,
              }, tooltipShopActive);
            } else {
              onChange && onChange({ ...data }, tooltipShopActive);
            }
          }
        }}

      />
      <article className="Container">
        <section className={`${scanning && (scanning ? ' scanning animate-front' : ' scanning animate-back')}`}>
          <div className="imgBox">
            <Spin spinning={backFlag == 1} size="large" indicator={<MyIcon style={{ fontSize: '50px', color: '#ffffff', transform: 'translate(-40%,-40%)', marginLeft: 0 }}
              type="icon-loading" spin></MyIcon>}>
              <div className="imgData">
                <div className="imgContain" style={{ background: `url('${dataInfo.input[0] && dataInfo.input[0].path}') no-repeat center center`}}></div>
                <img style={{ position: 'absolute', zIndex: '-1'}} onLoad={(e) => {
                  setTimeout(() => {
                    setBackFlag('0');
                  }, 300);
                }} src={dataInfo.input[0] && dataInfo.input[0].path} />
              </div>
            </Spin>
            <span className="imgName">图片一</span>
          </div>
          <div className="imgBox">
            <Spin spinning={backFlag1 == 1} size="large" indicator={<MyIcon style={{ fontSize: '50px', color: '#ffffff', transform: 'translate(-40%,-40%)', marginLeft: 0 }}
              type="icon-loading" spin></MyIcon>}>
              <div className="imgData">
                <div className="imgContain" style={{ background: `url('${dataInfo.input[1] && (tooltipShopActive ? dataInfo.input[1].path : dataInfo.input[2].path)}') no-repeat center center`}}></div>
                <img style={{ position: 'absolute', zIndex: '-1'}} onLoad={(e) => {
                  setTimeout(() => {
                    setBackFlag1('0');
                  }, 300);
                }} src={dataInfo.input[1] && (tooltipShopActive ? dataInfo.input[1].path : dataInfo.input[2].path)} />
              </div>
            </Spin>
            {
              tooltipShopActive ? (<span className="imgName">图片二</span>) : (<span className="imgName">对抗样本（根据图二生成）</span>)
            }
            <ul style={{ display: 'flex', display: '-webkit-flex' }}>
              <li
                className={`${!tooltipShopActive ? styles.tooltipShopActive : ''}`}
                onClick={() => {
                  sessionStorage.tagToTab = false;
                  setTooltipVis(false);

                  onChange && onChange({
                    input: null,
                    output: null,
                  }, !tooltipShopActive);

                  setTooltipShopActive(false)
                  //setBackFlag(1);
                  setBackFlag1(1);
                }}
              >
                <TooltipTo>
                  <img className="img" src={dataInfo.input[2] && dataInfo.input[2].path} />
                  {
                    tooltipVis && (
                      <section className="toolTipSection">
                        可切换对抗样本
                        <CloseOutlined
                          className="closeToolTipSection"
                          onClick={() => {
                            sessionStorage.tagToTab = !tooltipVis;
                            setTooltipVis(!tooltipVis);
                          }}
                        />
                      </section>
                    )}
                </TooltipTo>
              </li>
              <li
                className={`${tooltipShopActive ? styles.tooltipShopActive : ''}`}
                onClick={() => {
                  setTooltipShopActive(true);
                  //setBackFlag(1);
                  setBackFlag1(1);

                  onChange && onChange({
                    input: null,
                    output: null,
                  }, !tooltipShopActive);
                }}
              >
                <img className="img" src={dataInfo.input[1] && dataInfo.input[1].path} />
              </li>
            </ul>
          </div>
        </section>
      </article>
      <div align="center" style={{ padding: '20px 16px 16px' }}>
        <Button style={{ fontWeight: 'bold' }} type="primary" disabled={scanning} ghost onClick={() => {
          setScanning(true);
          setTimeout(() => {
            onChange && onChange(dataInfo, tooltipShopActive);
            setScanning(false);
          }, 1000)
        }}>
          测试您的服务
        </Button>
      </div>
    </article>
  )
}

Wheel.propTypes = {
  dataSource: PropTypes.shape({}),
  onChange: PropTypes.func,
};

Wheel.defaultProps = {
  dataSource: null,
  onChange: null,
};
export default Wheel;

const TooltipTo = styled(Tooltip)`
  position:relative;
  .toolTipSection{
    position: absolute;
    width: auto !important;
    background: black;
    padding: 0px 12px;
    white-space: nowrap;
    border-radius: 6px;
    height: auto;
    color: #ffffff;
    bottom: -52px;
    left: -49px;
    padding-right: 40px;
    height: 30px;
    line-height: 30px;
    &::before{
      content: '';
      width: 5px;
      height: 9px;
      border: 8px solid;
      border-color: transparent transparent #000000 transparent;
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }
    &::after{
      margin: 0 8px;
      content: '';
      border-right: 1px solid #c3c3c3;
      height: 15px;
      width: 1px;
      position: absolute;
      right: 24px;
      top: 8px;
    }
  }
  .closeToolTipSection{
    position: absolute;
    right: 12px;
    color: #ffffff;
    font-size: 13px;
    top: 9px;
    width: 25px;
    right: 4px;
  }
`;

const TagTo = styled(Tag)`
  background:transparent !important;
  border:none !important;
  color:#ffffff !important;
  padding:0 !important;
  i{
    color:#ffffff !important;
  }

`;