export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

/**
 * 将数值四舍五入(保留2位小数)后格式化成金额形式
 *
 * @param num 数值(Number或者String)
 * @return 金额格式的字符串,如'1,234,567.45'
 * @type String
 */
export function formatCurrency(num) {
  num = num.toString().replace(/\$|\,/g, '');
  if (isNaN(num))
    num = "0";
  let sign = (num == (num = Math.abs(num)));
  num = Math.floor(num * 100 + 0.50000000001);
  let cents = num % 100;
  num = Math.floor(num / 100).toString();
  if (cents < 10)
    cents = "0" + cents;
  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    num = num.substring(0, num.length - (4 * i + 3)) + ',' +
      num.substring(num.length - (4 * i + 3));
  return (((sign) ? '' : '-') + num + '.' + cents);
}

export function copyText(dom, callback) {
  try {
    let input = document.querySelector(`#${dom}`);
    input.select();
    input.focus();
    //input.setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
      return callback({
        message: '复制成功',
        type: 'success'
      });
    } else {
      return callback({
        message: '当前浏览器不支持复制操作，请使用Ctrl+c手动复制',
        type: 'warning'
      })
    }
    document.body.removeChild(input);
  } catch (e) {
    return callback({
      message: `复制出错：${e}`,
      type: 'error'
    })
  }
};

/**
 * 
 * @param {*} uri 
 * @param {*} key 
 * @param {*} value 
 */
export function updateQueryStringParameter(uri, key, value) {
  if (!value) {
    return uri;
  }
  let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  let separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}


export function getCookie(name) {
  function getCookieVal(offset) {
    var endstr = document.cookie.indexOf(";", offset);
    if (endstr == -1) endstr = document.cookie.length;
    return (document.cookie.substring(offset, endstr));
  }

  var arg = name + "=";
  var alen = arg.length;
  var clen = document.cookie.length;
  var i = 0;
  while (i < clen) {
    var j = i + alen;
    if (document.cookie.substring(i, j) == arg) return getCookieVal(j);
    i = document.cookie.indexOf(" ", i) + 1;
    if (i == 0) break;
  }
  return null;
}

export function setCookie(name, value, days) {
  var exp = new Date();
  exp.setTime((exp.getTime() + days * 3600 * 1000));//过期时间 一个月分钟
  //document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  document.cookie = name + "=" + (value) + ";expires=" + exp.toGMTString()+";path=/";
}

export function clearCookie(name) {
  let exp = new Date();
  exp.setTime(exp.getTime() - 10000);
  let cval = getCookie(name);
  if (cval != null) document.cookie = name + "=" + 'cval' + ";expires=" + exp.toGMTString()+";path=/";
}

export function clearAllCookie() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--;) {
      //document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString();
      clearCookie(keys[i]);
    }

  }
}