import React, { Component } from 'react';
import { Button, Table, Tooltip, Popconfirm, Icon, Empty } from 'antd'
import StatusText from '../common/StatusText/StatusText'
import { getTasks, getDatasets } from './api'
import { Link } from 'react-router-dom'
import CloseTooltip from 'components/common/CloseTooltip/CloseTooltip'
import { toPageNum, toOffset } from '@/offsetAndPagenum'
import styles from './style.module.css'
import NoData from 'components/NoData';

const PAGE_SIZE = 10
const hash = {
  0: '待提交',
  1: '待提测',
  2: '测评中',
  3: '测评中',
  4: '测评中',
  5: '测评失败',
  6: '已完成'
}
const addFirstFail = (arr = []) => {
  const closeFailFlag=localStorage.getItem('closeFailFlag')
  if(closeFailFlag){
    return arr
  }
  let flag = false
  arr.forEach(item => {
    if (item.status == 5 && !flag) {
      flag = true
      item.firstFail = true
    }
  })
  return arr
}
class CustomerTaskListWrap extends Component {
  state = {
    tasksData: {},
    datasets: []
  }
  colums = [
    { dataIndex: 'name',ellipsis:true, title: '任务名称', render: (text, record) => <Link className="d-b ellipsis"  to={`/customer/task/detail?task_id=${record.id}`}>{text}</Link> },
    {
      dataIndex: 'statusMsg', title: '状态', width: 120, render: (text, record) => {
        const hash = {
          0: 'todo',
          1: 'todo',
          2: 'doing',
          3: 'doing',
          4: 'doing',
          5: 'error',
          6: 'success'
        }
        return <StatusText type={hash[record.status]}><Tooltip title={record.statusDesc}>{text}</Tooltip></StatusText>
      }
    },
    { dataIndex: 'creator', title: '创建人', ellipsis: true,  render: (text) => <span title={text} >{text || '- -'}</span> },
    { dataIndex: 'evaluatingMethod', title: '测评方案',  ellipsis: true, render: (text, record) => <span ><span title={text ? text.join('、') : '- -'} >{text ? text.join('、') : '- -'}</span></span> },
    { dataIndex: 'createtime', title: '创建时间', width: 190, render: (text, record) => text || <span ><Icon type="minus" /> <Icon type="minus" /></span> },
    { dataIndex: 'updatetime', title: '完成时间', width: 190, render: (text, record) => record.status == 6 ? text : '- -' },
    {
      dataIndex: 'operate', title: '操作', render: (text, record) => {
        if (record.status == 0 || record.status == 5) {
          if (record.status == 0) {
            return <Link to={`/customer/task/edit?id=${record.id}`}>编辑</Link>
          }
          return <Tooltip
            getPopupContainer={triggerNode=>triggerNode.parentElement}
            visible={record.firstFail}
            title={<CloseTooltip handleCloseClick={this.handleFailCloseClick}>{record.statusDesc}</CloseTooltip>}
          >
            <Link to={`/customer/task/edit?id=${record.id}`}>编辑</Link></Tooltip>
        } else if (record.status == 6) {
          return <Tooltip title=""><Link to={`/customer/task/report?task_id=${record.id}&task_title=${record.name}`}>查看测评报告</Link></Tooltip>
        }
        return '- -'
      }
    },
  ]

  componentDidMount() {

    getTasks(toOffset({ pageNum: 1, pageSize: PAGE_SIZE })).then(result => {
      console.log('result', result)
      if (result.code == 0) {
        result.data.data = addFirstFail(result.data.data)
        this.setState({ tasksData: result.data });
      }
    })
  }

  handlePaginationChange = pageNum => {
    getTasks(toOffset({ pageNum, pageSize: PAGE_SIZE })).then(result => {
      if (result.code == 0) {
        result.data.data = addFirstFail(result.data.data)
        this.setState({ tasksData: result.data });
      }
    })
  };
  handleFailCloseClick = () => {
    localStorage.setItem('closeFailFlag',1)
    const { tasksData } = this.state
    tasksData.data = tasksData.data.map(item => ({ ...item, firstFail: false }))
    this.setState({ tasksData });

  }
  render() {
    const { tasksData } = this.state
    const Des =  <span>您可以通过新建测评任务的方式提交相关信息，RealSafe 平台将在您提交任务后生成对抗样本模拟攻击进行测评并输出相应的测评报告</span>
    return (
        <div className={styles.table}>


            <Table
              rowKey="id"
              columns={this.colums}
              dataSource={tasksData.data || []}
              pagination={{
                current: toPageNum(tasksData).pageNum,
                pageSize: PAGE_SIZE,
                total: tasksData.total,
                showQuickJumper: true,
                onChange: this.handlePaginationChange,
                hideOnSinglePage: true
              }}
              locale={{emptyText:()=>null}}

            />
            {
              !(tasksData.data&&tasksData.data.length)
                ?<NoData text={Des}/>
                :null
            }
        </div>


    );
  }
}

export default CustomerTaskListWrap;
